import { HeadCell } from '../../components/crud/protocols/head-cell';

export interface Data extends Record<string, any> {
  codigo: string;
  nomePt: string;
}

export const displayProperties = ['nomePt'];

export const moduleLabel = 'País';

export const headCells: readonly HeadCell<Data>[] = [
  {
    id: 'nomePt',
    numeric: false,
    disablePadding: false,
    label: 'Nome',
  },
  {
    id: 'codigo',
    numeric: false,
    disablePadding: false,
    label: 'Código',
  },
];
