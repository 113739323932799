import { HeadCell } from '../../../components/crud/protocols/head-cell';

export interface Data extends Record<string, any> {
  valor: number;
  edicaoLinhaDeAcaoId: number;
  tipoRecursoLinhaDeAcaoId: number;
  convenioId: number;
  tesouroId: number;
}

export const moduleName =
  'ORCAMENTO.LINHA_DE_ACAO.EDICAO_LINHA_DE_ACAO.EDICAO_RECURSO_LINHA_DE_ACAO.EDICAO_RECURSO_LINHA_DE_ACAO';

export const headCells: readonly HeadCell<Data>[] = [
  {
    id: 'edicaoLinhaDeAcaoId',
    numeric: false,
    disablePadding: false,
    label: 'Edição da Linha de Ação',
    resource: {
      name: 'edicaoLinhaDeAcao',
      target: 'ano',
    },
  },
  {
    id: 'tipoRecursoId',
    numeric: false,
    disablePadding: false,
    label: 'Tipo de Recurso',
    resource: {
      name: 'tipoRecursoLinhaDeAcao',
      target: 'codigo',
    },
  },
  {
    id: 'fonte',
    numeric: false,
    disablePadding: false,
    label: 'Fonte',
    resource: {
      target: (data) => data.tesouro?.ano ?? data.convenio?.nome,
    },
  },
  {
    id: 'valor',
    numeric: false,
    disablePadding: false,
    label: 'Valor',
    mask: 'money',
  },
];
