const DepartamentoFundacaoUiSchema = {
  type: 'VerticalLayout',
  elements: [
    {
      type: 'Control',
      scope: '#/properties/nome',
      label: 'Nome do Departamento',
    },
    {
      type: 'Control',
      scope: '#/properties/sigla',
    },
    {
      type: 'LongText',
      scope: '#/properties/descricao',
      label: 'Descrição',
    },
  ],
};

export default DepartamentoFundacaoUiSchema;
