import {
  Button,
  CssBaseline,
  IconButton,
  InputAdornment,
  TextField,
  Typography,
} from '@mui/material';
import { useEffect, useState } from 'react';
import { Link, useLocation, useNavigate } from 'react-router-dom';
import AlertCustom from '../../components/crud/alert-custom';
import { useCrudContext } from '../../components/crud/crud-context';
import { LoginApi, makeLoginApi } from '../../api/login-api';
import './login.css';
import { Visibility, VisibilityOff } from '@mui/icons-material';
import LogoFundacao from '../../components/layout/logo-fundacao';

interface NewPasswordFormState {
  senha: string;
  senhaConfirmar: string;
}

const AlterarSenha = () => {
  const navigate = useNavigate();
  const { search } = useLocation();
  const [api, setApi] = useState<LoginApi>();
  const { openAlert, messageAlert, severityAlert, showError, showSuccess } = useCrudContext();
  const [showPassword, setShowPassword] = useState<boolean>(false);
  const [showPasswordConfirm, setShowPasswordConfirm] = useState<boolean>(false);
  const [formData, setFormData] = useState<NewPasswordFormState>({
    senha: '',
    senhaConfirmar: '',
  });

  useEffect(() => {
    const loginApi = makeLoginApi();
    setApi(loginApi);
  }, []);

  const obterTokenDaURL = (search: string) => {
    const parametros = new URLSearchParams(search);
    return parametros.get('token');
  };

  const token = obterTokenDaURL(search);

  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setFormData({
      ...formData,
      [e.target.name]: e.target.value,
    });
  };

  const handleSubmit = (e: React.FormEvent) => {
    e.preventDefault();

    if (formData.senha !== formData.senhaConfirmar) {
      showError('A senha e a confirmação da senha não conferem.');
      return;
    }

    // String deve ter entre 8 e 64 char, contendo 1 letra minuscula, 1 maiuscula, 1 numero e 1 caracter especial, pelo menos.
    const validations =
      /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[!@#$%^&*()-_=+{};:'",<.>\/?\\[\]`~])[\w!@#$%^&*()-_=+{};:'",<.>\/?\\[\]`~]{8,64}$/;
    if (!formData.senha?.match?.(validations)) {
      showError(
        'A senha deve ter entre 8 e 64 caracteres, contendo pelo menos 1 letra minúscula, 1 maiúscula, 1 número e 1 caracter especial.',
      );
      return;
    }

    if (token) {
      api
        ?.alterarSenha(token, formData.senha)
        .then((res) => {
          showSuccess('Senha alterada com sucesso.');
          navigate('/login');
        })
        .catch((err) => {
          showError(err?.response?.data?.message || 'Ocorreu um erro.');
        });
    } else {
      showError('Erro ao alterar senha.');
      navigate('/recuperar-senha');
    }
  };

  const handleTogglePasswordVisibility = () => {
    setShowPassword(!showPassword);
  };

  const handleTogglePasswordConfirmVisibility = () => {
    setShowPasswordConfirm(!showPasswordConfirm);
  };

  return (
    <div className='div-group'>
      <div className='background' />

      <div className='login-container'>
        <CssBaseline />
        <div className='paper'>
          <LogoFundacao className='logo-image' />
          <Typography component='h1' variant='h5' style={{ color: '#454545', fontWeight: '700' }}>
            Olá de novo!
          </Typography>
          <Typography component='h4' style={{ color: '#939495' }}>
            Agora é com você, capitão ⚓
          </Typography>
          <form className='login-form' onSubmit={handleSubmit}>
            <TextField
              variant='outlined'
              margin='normal'
              required
              fullWidth
              name='senha'
              label='Senha'
              type={showPassword ? 'text' : 'password'}
              id='senha'
              value={formData.senha}
              onChange={handleChange}
              InputProps={{
                endAdornment: (
                  <InputAdornment position='end'>
                    <IconButton onClick={handleTogglePasswordVisibility}>
                      {showPassword ? <VisibilityOff /> : <Visibility />}
                    </IconButton>
                  </InputAdornment>
                ),
              }}
            />
            <TextField
              variant='outlined'
              margin='normal'
              required
              fullWidth
              name='senhaConfirmar'
              label='Confirmar Senha'
              type={showPasswordConfirm ? 'text' : 'password'}
              id='senhaConfirmar'
              value={formData.senhaConfirmar}
              onChange={handleChange}
              InputProps={{
                endAdornment: (
                  <InputAdornment position='end'>
                    <IconButton onClick={handleTogglePasswordConfirmVisibility}>
                      {showPasswordConfirm ? <VisibilityOff /> : <Visibility />}
                    </IconButton>
                  </InputAdornment>
                ),
              }}
            />
            <Button type='submit' fullWidth variant='contained' color='primary' className='submit'>
              Alterar senha
            </Button>
          </form>
          <div className='links-container'>
            <Link to='/registrar' className='link'>
              Não tem conta? Crie uma agora!
            </Link>
            <Link to='/login' className='link'>
              Login
            </Link>
          </div>
        </div>
        <AlertCustom open={openAlert} message={messageAlert} severity={severityAlert}></AlertCustom>
      </div>
    </div>
  );
};

export default AlterarSenha;

export const disableAutoRoute = true;
