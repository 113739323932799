const requireComponent = require.context('./', true);

/**
 * importa automaticamente todas as views do diretorio src/views/*.tsx
 */
export const views: any[] = requireComponent
  .keys()
  .filter((fileName) => fileName.includes('.view.tsx'))
  .flatMap((fileName: any) => {
    try {
      return Object.values(requireComponent(fileName));
    } catch (e) {
      console.error(e);
    }
    return null;
  });

/**
 * importa automaticamente todos os "Head Cells" do diretorio src/views/*
 */

export const headCells: { id: string; value: any }[] = requireComponent
  .keys()
  .filter((fileName) => fileName.includes('.headcell.ts'))
  .map((fileName: any) => {
    const module = requireComponent(fileName);
    const pathSplited = fileName
      .replace(/\.\//, '')
      .replace(/\.headcell\.ts$/, '')
      .split('/');
    const id = pathSplited[pathSplited.length - 1];
    return { id, value: module.headCells };
  })
  .filter((entry) => entry.value !== undefined);

export const displayProperties: { id: string; value: string[] | Function }[] = requireComponent
  .keys()
  .filter((fileName) => fileName.includes('.headcell.ts'))
  .map((fileName: any) => {
    const module = requireComponent(fileName);
    const pathSplited = fileName
      .replace(/\.\//, '')
      .replace(/\.headcell\.ts$/, '')
      .split('/');
    const id = pathSplited[pathSplited.length - 1];
    return { id, value: module.displayProperties };
  })
  .filter((entry) => entry.value !== undefined);

export const moduleLabels: { id: string; value: any }[] = requireComponent
  .keys()
  .filter((fileName) => fileName.includes('.headcell.ts'))
  .map((fileName: any) => {
    const module = requireComponent(fileName);
    const pathSplited = fileName
      .replace(/\.\//, '')
      .replace(/\.headcell\.ts$/, '')
      .split('/');
    const id = pathSplited[pathSplited.length - 1];
    return { id, value: module.moduleLabel };
  })
  .filter((entry) => entry.value !== undefined);

export const rowsLength: { id: string; value: any }[] = requireComponent
  .keys()
  .filter((fileName) => fileName.includes('.headcell.ts'))
  .map((fileName: any) => {
    const module = requireComponent(fileName);
    const pathSplited = fileName
      .replace(/\.\//, '')
      .replace(/\.headcell\.ts$/, '')
      .split('/');
    const id = pathSplited[pathSplited.length - 1];
    return { id, value: module.rowsLength };
  })
  .filter((entry) => entry.value !== undefined);

export const moduleNames: { id: string; value: any }[] = requireComponent
  .keys()
  .filter((fileName) => fileName.includes('.headcell.ts'))
  .map((fileName: any) => {
    const module = requireComponent(fileName);
    const pathSplited = fileName
      .replace(/\.\//, '')
      .replace(/\.headcell\.ts$/, '')
      .split('/');
    const id = pathSplited[pathSplited.length - 1];
    return { id, value: module.moduleName };
  })
  .filter((entry) => entry.value !== undefined);
