import { HeadCell } from '../../../components/crud/protocols/head-cell';

export interface Data extends Record<string, any> {
  codigo: string;
  nome: string;
  tipoRubricaId: string;
}

export const displayProperties: Function = (item: any) => {
  return `${item.codigo} - ${item.nome}`;
};

export const moduleName = 'CONFIGURACOES_INICIAIS.RUBRICA';

export const headCells: readonly HeadCell<Data>[] = [
  {
    id: 'codigo',
    numeric: false,
    disablePadding: false,
    label: 'Código',
  },
  {
    id: 'nome',
    numeric: false,
    disablePadding: false,
    label: 'Nome',
  },
  {
    id: 'tipoRubricaId',
    numeric: false,
    disablePadding: false,
    label: 'Tipo',
    resource: {
      name: 'tipoRubrica',
      target: 'codigo',
    },
  },
];
