import { GenericApi } from '../../../api/generic-api';
import client from '../../../config/axios-config';

export class AreaDeConhecimentoApi extends GenericApi {
  async buscarGrandesAreas(): Promise<any> {
    const response = await client.get<any>(`${this.url}/grandearea`);
    return response.data;
  }

  async buscarAreaPorGrandeAreaId(id: number): Promise<any> {
    const response = await client.get<any>(`${this.url}/area/${id}`);
    return response.data;
  }

  async buscarSubAreaPorAreaId(id: number): Promise<any> {
    const response = await client.get<any>(`${this.url}/subarea/${id}`);
    return response.data;
  }

  async buscarEspecialidadePorSubAreaId(id: number): Promise<any> {
    const response = await client.get<any>(`${this.url}/especialidade/${id}`);
    return response.data;
  }
}

export const makeAreaDeConhecimentoApi = () => {
  return new AreaDeConhecimentoApi('area-de-conhecimento');
};
