import { HeadCell } from '../../../components/crud/protocols/head-cell';

export interface Data extends Record<string, any> {
  id: number;
  descricao: string;
}

export const moduleLabel = 'Termo de Aceite';

export const moduleName = 'EDITAL.TERMO_ACEITE';

export const headCells: readonly HeadCell<Data>[] = [
  {
    id: 'termoDeAceite',
    numeric: false,
    disablePadding: false,
    label: 'Descrição',
  },
];
