import { JsonFormsCore, UISchemaElement, ValidationMode, createAjv } from '@jsonforms/core';
import { materialCells, materialRenderers } from '@jsonforms/material-renderers';
import { JsonForms } from '@jsonforms/react';
import { Fragment } from 'react';
import { customRenderers } from '../../jsonforms/renderers';
import { useCrudContext } from './crud-context';
import { translateErrors } from './i18n';
import Loading from './loading';
import './style/json-form.css';

export interface JsonFormProps {
  uischema: UISchemaElement;
  data: any;
  onChange: (state: Pick<JsonFormsCore, 'data' | 'errors'>) => void;
  readonly: boolean;
  validationMode: ValidationMode;
  additionalErrors?: any[];
  formSize?: {
    width?: { value: number; scrollable?: boolean };
    height?: { value: number; scrollable?: boolean };
  };
}

const JsonForm = ({
  uischema,
  data,
  onChange,
  readonly,
  validationMode,
  additionalErrors,
  formSize,
}: JsonFormProps) => {
  const handleDefaultsAjv = createAjv({ useDefaults: true });
  const translateError = (error: any): string => translateErrors(error) ?? error?.message ?? '';
  const { schema, load } = useCrudContext();

  const getSizeConfig = () => {
    let sizeConfig: {
      width?: string;
      height?: string;
      overflowY?: 'visible' | 'hidden' | 'clip' | 'scroll' | 'auto';
      overflowX?: 'visible' | 'hidden' | 'clip' | 'scroll' | 'auto';
    } = {};

    if (formSize?.width?.value) {
      sizeConfig.width = `${formSize.width.value}px`;
      if (formSize.width.scrollable) sizeConfig.overflowX = 'scroll';
    }
    if (formSize?.height?.value) {
      sizeConfig.height = `${formSize.height.value}px`;
      if (formSize.height.scrollable) sizeConfig.overflowY = 'scroll';
    }
    return sizeConfig;
  };

  return (
    <Fragment>
      <div className='form' style={getSizeConfig()}>
        {load && <Loading isLoading={load} className='loader' />}
        {!load && (
          <div className='form-container'>
            <JsonForms
              schema={schema}
              uischema={uischema}
              data={data}
              renderers={[...materialRenderers, ...customRenderers]}
              cells={materialCells}
              onChange={onChange}
              readonly={readonly}
              validationMode={validationMode}
              i18n={{ locale: 'pt', translateError }}
              additionalErrors={additionalErrors}
              ajv={handleDefaultsAjv}
            />
          </div>
        )}
      </div>
    </Fragment>
  );
};

export default JsonForm;
