import { Crud } from '../../../components/crud/crud';
import { makeDadosFundacaoApi } from './dados-fundacao-api';
import { Data, headCells } from './dados-fundacao.headcell';
import dadosFundacaoUiSchema from './dados-fundacao.uischema';

function DadosFundacao() {
  const apiClient = makeDadosFundacaoApi('/dados-fundacao');

  return (
    <Crud<Data>
      headCells={headCells}
      titleConfig={{ value: 'Perfil Fundação', show: true }}
      apiClient={apiClient}
      uischema={dadosFundacaoUiSchema}
      initView
    ></Crud>
  );
}

export default DadosFundacao;
