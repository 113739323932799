import { HeadCell } from '../../../components/crud/protocols/head-cell';

export interface Data extends Record<string, any> {
  id: number;
  nome: string;
  email: string;
}

export const displayProperties = ['nome'];

export const moduleLabel = 'Perfil Fundação';

export const moduleName = 'DADOS_FUNDACAO';

export const headCells: readonly HeadCell<Data>[] = [
  {
    id: 'nome',
    numeric: false,
    disablePadding: false,
    label: 'Nome',
  },
  {
    id: 'email',
    numeric: false,
    disablePadding: false,
    label: 'email',
  },
];
