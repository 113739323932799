import { HeadCell } from '../../../components/crud/protocols/head-cell';

export interface Data extends Record<string, any> {
  id: number;
  titulo: string;
  objetivo: string;
  descricao: string;
  informacaoPublicacao: string;
  dataInicio: Date;
  dataTermino: Date;
}

export const moduleName = 'ORCAMENTO.LINHA_DE_ACAO.LINHA_DE_ACAO';

export const headCells: readonly HeadCell<Data>[] = [
  {
    id: 'titulo',
    numeric: false,
    disablePadding: false,
    label: 'Título',
  },
  {
    id: 'dataInicio',
    numeric: false,
    disablePadding: false,
    label: 'Data de Início',
    dateFormat: 'DD/MM/YYYY',
  },
  {
    id: 'dataTermino',
    numeric: false,
    disablePadding: false,
    label: 'Data de Término',
    dateFormat: 'DD/MM/YYYY',
  },
];
