const nivelBolsaUiSchema = {
  type: 'VerticalLayout',
  elements: [
    {
      type: 'Control',
      scope: '#/properties/codigo',
      label: 'Código',
    },
    {
      type: 'Control',
      scope: '#/properties/nivel',
      label: 'Nível',
    },
    {
      type: 'Select',
      scope: '#/properties/nivelAcademicoId',
      label: 'Nível Acadêmico',
    },
    {
      type: 'Control',
      scope: '#/properties/cargaHoraria',
      label: 'Carga Horária',
    },
    {
      type: 'MonetaryInput',
      scope: '#/properties/valorAtualBolsa',
      label: 'Valor',
    },
  ],
};

export default nivelBolsaUiSchema;
