import { Crud } from '../../../components/crud/crud';
import { onlyNumbers, positiveNumber, whiteSpaces } from '../../../helpers/custom-errors';
import { Data, headCells } from './banco.headcell';
import bancoUiSchema from './banco.uischema';

function Banco() {
  const customErrors = [
    onlyNumbers('codigo'),
    positiveNumber('codigo', 'O código deve ser de 001 a 999'),
    whiteSpaces('nome'),
  ];

  return (
    <Crud<Data>
      headCells={headCells}
      titleConfig={{ value: 'Banco', show: true }}
      uischema={bancoUiSchema}
      apiUrl={'/banco'}
      hideView
      queryFilters={[
        { chave: 'withDeleted', valor: true },
        { chave: 'orderBy', valor: 'codigo' },
        { chave: 'orderDirection', valor: 'asc' },
      ]}
      customErrors={customErrors}
    ></Crud>
  );
}

export default Banco;
