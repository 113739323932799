import { Crud } from '../../../components/crud/crud';
import { Data, headCells } from './programa.headcell';
import programaUiSchema from './programa.uischema';
import { useNavigate } from 'react-router-dom';
import { useCrudContext } from '../../../components/crud/crud-context';
import { Money } from '@styled-icons/boxicons-regular/Money';
import { Tooltip } from '@mui/material';
import { Star } from '@styled-icons/evaicons-solid/Star';

function Programa() {
  const navigate = useNavigate();
  const { parameterFilters, setParameterFilters } = useCrudContext();

  const edicaoPrograma = {
    permission: 'ORCAMENTO.PROGRAMA.EDICAO_PROGRAMA.EDICAO_PROGRAMA.EXIBIR_NO_MENU',
    action: (
      <Tooltip title='Edição' arrow>
        <Star size='24' color='#009900' />
      </Tooltip>
    ),
    handler: (row: any) => {
      setParameterFilters({
        ...parameterFilters,
        programa: row,
      });
      navigate(`/programa/${row?.id}/edicao-programa`);
    },
  };

  return (
    <Crud<Data>
      headCells={headCells}
      titleConfig={{ value: 'Programas', show: true }}
      uischema={programaUiSchema}
      apiUrl={'/programa'}
      queryFilters={[
        { chave: 'withDeleted', valor: true },
        { chave: 'orderBy', valor: 'dataInicio' },
        { chave: 'orderDirection', valor: 'ASC' },
      ]}
      customActions={[() => edicaoPrograma]}
      formSize={{ width: { value: 650 } }}
    ></Crud>
  );
}

export default Programa;
