import { HeadCell } from '../../../components/crud/protocols/head-cell';

export interface Data extends Record<string, any> {
  id: number;
  pergunta: string;
}

export const displayProperties = ['pergunta'];

export const moduleLabel = 'Plano de Apresentação';

export const moduleName = 'EDITAL.PLANO_APRESENTACAO';

export const headCells: readonly HeadCell<Data>[] = [
  {
    id: 'pergunta',
    numeric: false,
    disablePadding: false,
    label: 'Pergunta',
  },
];
