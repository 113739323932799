import { User } from '@styled-icons/boxicons-solid/User';
import { Home } from '@styled-icons/fluentui-system-filled/Home';
import { AcademicCap } from '@styled-icons/heroicons-solid/AcademicCap';
import { Attachment } from '@styled-icons/typicons/Attachment';
import { FormApi } from '../../api/generic-api';
import { CrudContextData, CrudStatesOptions } from '../../components/crud/crud-context';
import { stepHandlerErrors } from '../../utils/step-handler-errors';
import { circleFlagBuilder } from '../../components/circle-flag';
import {
  AreaDeConhecimentoApi,
  makeAreaDeConhecimentoApi,
} from '../fundacao/area-de-conhecimento/area-de-conhecimento-api';
import { createResource } from '../../utils/create-resource';

const iconConfig = [
  {
    category: 'Dados Pessoais',
    icon: User,
  },
  {
    category: 'Endereço',
    icon: Home,
  },
  {
    category: 'Dados Acadêmicos',
    icon: AcademicCap,
  },
  {
    category: 'Anexos',
    icon: Attachment,
  },
];

const disableIfNoOptions = (ctx: CrudContextData, apiListData: any[]) => {
  if (apiListData.length === 0) return true;
  return false;
};

const visibleIfHasFilter = (ctx: CrudContextData, apiListData: any[], queryFilterValues: any[]) => {
  const filterValue = queryFilterValues?.[0]?.value;
  if (filterValue !== null && filterValue !== undefined) return true;
  return false;
};

const hideIfBrasil = {
  effect: 'SHOW',
  condition: {
    scope: '#/properties/paisId-codigo',
    schema: {
      not: {
        const: 'brasil',
      },
    },
  },
};

const showIfBrasil = {
  effect: 'HIDE',
  condition: {
    scope: '#/properties/paisId-codigo',
    schema: {
      not: {
        const: 'brasil',
      },
    },
  },
};

const apiInstanceAreaDeConhecimento = makeAreaDeConhecimentoApi();

const editarPerfilUiSchema = {
  type: 'Categorization',
  elements: [
    {
      type: 'Category',
      label: 'Dados Pessoais',
      options: {
        stepHandler: {
          handler: stepHandlerErrors,
        },
      },
      elements: [
        {
          type: 'HorizontalLayout',
          elements: [
            {
              type: 'VerticalLayout',
              elements: [
                {
                  type: 'Control',
                  scope: '#/properties/nome',
                  label: 'Nome',
                  options: {
                    required: true,
                  },
                },
                {
                  type: 'Control',
                  scope: '#/properties/nomeSocial',
                  label: 'Nome social',
                },
                {
                  type: 'Date',
                  scope: '#/properties/dataNascimento',
                  label: 'Data de nascimento',
                  options: {
                    dateFormat: 'DD/MM/YYYY',
                    required: true,
                  },
                },
                {
                  type: 'Control',
                  scope: '#/properties/email',
                  label: 'E-mail',
                  options: {
                    mask: 'email',
                    disabled: true,
                  },
                },
              ],
            },
            {
              type: 'VerticalLayout',
              elements: [
                {
                  type: 'Select',
                  scope: '#/properties/racaCorId',
                  label: 'Raça/Cor',
                },
                {
                  type: 'Select',
                  scope: '#/properties/paisId',
                  label: 'País',
                  options: {
                    required: true,
                    handleChange: (
                      item: any,
                      handler: (path: string, value: any) => void,
                      formData: any,
                    ) => {
                      if (
                        formData?.['paisId-codigo'] &&
                        !['brasil'].includes(formData?.['paisId-codigo'])
                      ) {
                        handler('documento', '');
                      }
                    },
                    iconBuilder: circleFlagBuilder,
                  },
                },
                {
                  type: 'Control',
                  scope: '#/properties/documento',
                  label: 'CPF',
                  rule: showIfBrasil,
                  options: {
                    mask: '999.999.999-99',
                  },
                },
              ],
            },
          ],
        },
      ],
    },
    {
      type: 'Category',
      label: 'Endereço',
      options: {
        stepHandler: {
          handler: stepHandlerErrors,
          callback: (ctx: CrudContextData) => {
            const { formData, showError } = ctx;
            if (!formData?.endereco) {
              showError('Preencha o endereço antes de prosseguir.');
              return false;
            }
            return true;
          },
        },
      },
      elements: [
        {
          type: 'HorizontalLayout',
          elements: [
            {
              type: 'VerticalLayout',
              elements: [
                {
                  type: 'Cep',
                  scope: '#/properties/endereco/properties/cep',
                  label: 'CEP',
                  rule: showIfBrasil,
                  options: {
                    mask: '99999-999',
                    required: true,
                  },
                },
                {
                  type: 'Control',
                  scope: '#/properties/endereco/properties/cep',
                  label: 'ZipCode',
                  rule: hideIfBrasil,
                  options: {
                    mask: '999999999',
                    required: true,
                  },
                },
                {
                  type: 'Control',
                  scope: '#/properties/endereco/properties/logradouro',
                  options: {
                    required: true,
                  },
                },
                {
                  type: 'Control',
                  scope: '#/properties/endereco/properties/numero',
                  label: 'Número',
                  rule: showIfBrasil,
                  options: {
                    required: true,
                  },
                },
                {
                  type: 'Control',
                  scope: '#/properties/endereco/properties/complemento',
                },
              ],
            },
            {
              type: 'VerticalLayout',
              elements: [
                {
                  type: 'Control',
                  scope: '#/properties/endereco/properties/bairro',
                  label: 'Bairro',
                  rule: showIfBrasil,
                  options: {
                    required: true,
                  },
                },
                {
                  type: 'Control',
                  scope: '#/properties/endereco/properties/municipio',
                  label: 'Município',
                  options: {
                    required: true,
                  },
                },
                {
                  type: 'Control',
                  scope: '#/properties/endereco/properties/estado',
                  rule: showIfBrasil,
                  options: {
                    required: true,
                  },
                },
                {
                  type: 'Control',
                  scope: '#/properties/endereco/properties/estado',
                  label: 'Estado/Região',
                  rule: hideIfBrasil,
                  options: {
                    required: true,
                  },
                },
              ],
            },
          ],
        },
      ],
    },
    {
      type: 'Category',
      label: 'Dados Acadêmicos',
      options: {
        stepHandler: {
          handler: stepHandlerErrors,
          callback: (ctx: CrudContextData) => createResource(ctx, '/usuario'),
        },
      },
      elements: [
        {
          type: 'HorizontalLayout',
          elements: [
            {
              type: 'VerticalLayout',
              elements: [
                {
                  type: 'Select',
                  scope: '#/properties/instituicaoId',
                  label: 'Instituição',
                },
                {
                  type: 'Select',
                  scope: '#/properties/unidadeId',
                  label: 'Unidade',
                  options: {
                    filter: {
                      formFields: ['instituicaoId'],
                      handler: (formData: any, listData: any[], api: FormApi) => {
                        if (!formData.instituicaoId) return listData;
                        return api.getAll([
                          { chave: 'instituicaoId', valor: formData?.instituicaoId },
                        ]);
                      },
                    },
                  },
                },
                {
                  type: 'Select',
                  scope: '#/properties/nivelAcademicoId',
                  label: 'Nível Acadêmico',
                },
              ],
            },
            {
              type: 'VerticalLayout',
              elements: [
                {
                  type: 'Control',
                  scope: '#/properties/lattes',
                  label: 'Currículo Lattes',
                },
                {
                  type: 'Control',
                  scope: '#/properties/linkedin',
                  label: 'Linkedin',
                },
                {
                  type: 'Control',
                  scope: '#/properties/interesseConsultor',
                  label: 'Interesse em ser consultor?',
                },
              ],
            },
          ],
        },
        {
          type: 'Control',
          scope: '#/properties/usuarioAreaDeConhecimento',
          label: 'Suas Áreas de Conhecimento',
          options: {
            detail: {
              type: 'VerticalLayout',
              itemLabel: 'Área de Conhecimento',
              elements: [
                {
                  type: 'Select',
                  scope: '#/properties/grandeAreaId',
                  label: 'Grande área',
                  options: {
                    apiInstance: apiInstanceAreaDeConhecimento,
                    filter: {
                      displayProperties: ['grandeAreaNome'],
                      handler: (formData: any, listData: any[], api: AreaDeConhecimentoApi) => {
                        return api.buscarGrandesAreas();
                      },
                    },
                    disableHandler: disableIfNoOptions,
                  },
                },
                {
                  type: 'Select',
                  scope: '#/properties/areaId',
                  label: 'Área',
                  options: {
                    apiInstance: apiInstanceAreaDeConhecimento,
                    filter: {
                      displayProperties: ['areaNome'],
                      formFields: ['grandeAreaId'],
                      handler: (
                        formData: any,
                        listData: any[],
                        api: AreaDeConhecimentoApi,
                        queryFilterValues: any,
                      ) => {
                        if (!queryFilterValues?.[0].value) return [];
                        return api.buscarAreaPorGrandeAreaId(queryFilterValues?.[0].value);
                      },
                    },
                    disableHandler: disableIfNoOptions,
                    visibleHandler: visibleIfHasFilter,
                  },
                },
                {
                  type: 'Select',
                  scope: '#/properties/subAreaId',
                  label: 'Sub-área',
                  options: {
                    apiInstance: apiInstanceAreaDeConhecimento,
                    filter: {
                      displayProperties: ['subAreaNome'],
                      formFields: ['areaId'],
                      handler: (
                        formData: any,
                        listData: any[],
                        api: AreaDeConhecimentoApi,
                        queryFilterValues: any,
                      ) => {
                        if (!queryFilterValues?.[0].value) return [];
                        return api.buscarSubAreaPorAreaId(queryFilterValues?.[0].value);
                      },
                    },
                    disableHandler: disableIfNoOptions,
                    visibleHandler: visibleIfHasFilter,
                  },
                },
                {
                  type: 'Select',
                  scope: '#/properties/especialidadeId',
                  label: 'Especialidade',
                  options: {
                    apiInstance: apiInstanceAreaDeConhecimento,
                    filter: {
                      displayProperties: ['especialidadeNome'],
                      formFields: ['subAreaId'],
                      handler: (
                        formData: any,
                        listData: any[],
                        api: AreaDeConhecimentoApi,
                        queryFilterValues: any,
                      ) => {
                        if (!queryFilterValues?.[0].value) return [];
                        return api.buscarEspecialidadePorSubAreaId(queryFilterValues?.[0].value);
                      },
                    },
                    disableHandler: disableIfNoOptions,
                    visibleHandler: visibleIfHasFilter,
                  },
                },
              ],
            },
          },
        },
      ],
    },
    {
      type: 'Category',
      label: 'Anexos',
      options: {
        stepHandler: {
          handler: stepHandlerErrors,
          callback: (ctx: CrudContextData) => {
            const { showSuccess, updateCrudStates } = ctx;
            updateCrudStates(CrudStatesOptions.VIEW);
            showSuccess(' Editado com sucesso.');
          },
        },
      },
      elements: [
        {
          type: 'VerticalLayout',
          elements: [
            {
              type: 'File',
              scope: '#/properties/usuarioAnexo',
            },
          ],
        },
      ],
    },
  ],
  options: {
    variant: 'stepper',
    showNavButtons: true,
    icons: iconConfig,
  },
};

export default editarPerfilUiSchema;
