import BackIcon from '@mui/icons-material/ArrowBack';
import Visibility from '@mui/icons-material/Visibility';
import VisibilityOff from '@mui/icons-material/VisibilityOff';
import {
  Button,
  Checkbox,
  CssBaseline,
  FormControlLabel,
  IconButton,
  InputAdornment,
  TextField,
  Typography,
} from '@mui/material';
import { useEffect, useState } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { LoginApi, makeLoginApi } from '../../api/login-api';
import AlertCustom from '../../components/crud/alert-custom';
import { useCrudContext } from '../../components/crud/crud-context';
import LogoGov from '../../components/layout/images/govbr.png';
import LogoFundacao from '../../components/layout/logo-fundacao';
import { useAuthContext } from './auth/auth-context';
import './login.css';

interface LoginFormState {
  login: string;
  senha: string;
}

const Login = () => {
  const navigate = useNavigate();
  const [api, setApi] = useState<LoginApi>();
  const { login, isLoggedIn } = useAuthContext();
  const { openAlert, messageAlert, severityAlert, showError } = useCrudContext();
  const [showPassword, setShowPassword] = useState<boolean>(false);
  const [rememberMe, setRememberMe] = useState<boolean>(false);
  const [formData, setFormData] = useState<LoginFormState>({
    login: '',
    senha: '',
  });

  useEffect(() => {
    if (isLoggedIn) navigate('/home');
    const loginApi = makeLoginApi();
    setApi(loginApi);
    const rememberState = localStorage.getItem('rememberMe') === 'true';
    setRememberMe(rememberState);
    if (rememberState) {
      const userEmail = localStorage.getItem('userEmail') ?? '';
      setFormData({ login: userEmail, senha: '' });
    }
  }, []);

  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setFormData({
      ...formData,
      [e.target.name]: e.target.value,
    });
  };

  const handleSubmit = (e: React.FormEvent) => {
    e.preventDefault();
    const emailRegex = /^[a-zA-Z0-9._-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,4}$/;
    if (!formData.login?.match?.(emailRegex)) {
      showError('E-mail inválido');
      return;
    }
    localStorage.setItem('rememberMe', String(rememberMe));
    localStorage.setItem('userEmail', formData.login);
    api
      ?.login(formData)
      .then((res) => {
        const loginResponseData = res?.data;
        if (res?.status === 201 && loginResponseData.token) {
          setFormData({ login: '', senha: '' });
          login(loginResponseData);
        }
      })
      .catch((err: any) => {
        if (err?.response?.status === 401) showError('Usuário ou senha inválidos');
        if (err?.response?.status === 400)
          showError(
            err?.cause?.response?.data?.message?.[0] ||
              err?.response?.data?.message ||
              'Ocorreu um erro.',
          );
      });
  };

  const handleTogglePasswordVisibility = () => {
    setShowPassword(!showPassword);
  };

  return (
    <div className='div-group'>
      <div className='background' />

      <div className='login-container'>
        <CssBaseline />
        <div className='paper'>
          <LogoFundacao className='logo-image' />
          <Typography component='h1' variant='h5' style={{ color: '#454545', fontWeight: '700' }}>
            Logar no Sistema
          </Typography>
          <Typography component='h4' style={{ color: '#939495' }}>
            É ótimo te ver por aqui 😎
          </Typography>
          <form className='login-form' onSubmit={handleSubmit}>
            <TextField
              variant='outlined'
              margin='normal'
              required
              fullWidth
              id='login'
              label='Email'
              name='login'
              value={formData.login}
              onChange={handleChange}
            />
            <TextField
              variant='outlined'
              margin='none'
              style={{ margin: '0' }}
              required
              fullWidth
              name='senha'
              label='Senha'
              type={showPassword ? 'text' : 'password'}
              id='senha'
              value={formData.senha}
              onChange={handleChange}
              InputProps={{
                endAdornment: (
                  <InputAdornment position='end'>
                    <IconButton onClick={handleTogglePasswordVisibility}>
                      {showPassword ? <VisibilityOff /> : <Visibility />}
                    </IconButton>
                  </InputAdornment>
                ),
              }}
            />
            <div className='remember-me'>
              <FormControlLabel
                control={
                  <Checkbox
                    checked={rememberMe}
                    onChange={() => setRememberMe(!rememberMe)}
                    color='primary'
                  />
                }
                label='Lembrar de mim'
              />
              <Link to='/recuperar-senha' className='link'>
                Esqueceu sua senha?
              </Link>
            </div>

            <Button
              type='submit'
              fullWidth
              variant='contained'
              color='primary'
              style={{ backgroundColor: '#1E90FF' }}
            >
              Acessar
            </Button>
          </form>

          <div className='complete-line'>
            <div className='lines'></div>
            <div className='line-center'>ou</div>
            <div className='lines'></div>
          </div>

          <Button
            type='submit'
            fullWidth
            variant='contained'
            style={{
              color: '#1351B4',
              backgroundColor: '#DFE2E7',
              fontFamily: 'Raleway',
              fontWeight: '600',
            }}
          >
            Entrar com
            <img src={LogoGov} className='gov-image' />
          </Button>

          <div className='links-container'>
            Não tem conta?
            <Link to='/registrar' className='link'>
              Crie uma agora!
            </Link>
          </div>
        </div>
        <AlertCustom open={openAlert} message={messageAlert} severity={severityAlert}></AlertCustom>
      </div>
    </div>
  );
};

export default Login;

export const disableAutoRoute = true;
