import { HeadCell } from '../../../components/crud/protocols/head-cell';

export interface Data extends Record<string, any> {
  id: number;
  nome: string;
}

export const displayProperties = ['nome'];

export const moduleName = 'CONFIGURACOES_INICIAIS.RACA_COR';

export const moduleLabel = 'Raça/Cor';

export const headCells: readonly HeadCell<Data>[] = [
  {
    id: 'nome',
    numeric: false,
    disablePadding: false,
    label: 'Nome',
  },
];
