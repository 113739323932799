const diariasUiSchema = {
  type: 'VerticalLayout',
  elements: [
    {
      type: 'Control',
      scope: '#/properties/descricao',
      label: 'Descrição',
    },
    {
      type: 'Control',
      scope: '#/properties/localidade',
    },
    {
      type: 'Select',
      scope: '#/properties/nivelAcademicoId',
    },
    {
      type: 'Currency',
      elements: [
        {
          type: 'MonetaryInput',
          scope: '#/properties/valorDiaria',
          label: 'Valor Diária',
        },
        {
          type: 'Select',
          scope: '#/properties/moedaId',
        },
      ],
    },
  ],
};

export default diariasUiSchema;
