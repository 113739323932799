import { FormApi } from '../../../api/generic-api';

const instituicaoConvenioUiSchema = {
  type: 'VerticalLayout',
  elements: [
    {
      type: 'HorizontalLayout',
      elements: [
        {
          type: 'Select',
          scope: '#/properties/instituicaoId',
          options: {
            filter: {
              formFields: ['convenioId'],
              handler: (formData: any, listData: any[], api: FormApi) => {
                if (!formData.convenioId) return listData;
                let filters = [{ chave: 'withDeleted', valor: false }];
                if (!formData.id) {
                  filters = [...filters, { chave: 'convenioId', valor: formData.convenioId }];
                }
                return api.getAll(filters);
              },
            },
          },
        },
      ],
    },
    {
      type: 'HorizontalLayout',
      elements: [
        {
          type: 'LongText',
          scope: '#/properties/objetivoConvenio',
          label: 'Objetivo do Convênio',
        },
      ],
    },
    {
      type: 'HorizontalLayout',
      elements: [
        {
          type: 'VerticalLayout',
          elements: [
            {
              type: 'Select',
              scope: '#/properties/tipoParceriaId',
            },
            {
              type: 'Control',
              scope: '#/properties/porcentagemOutrosFins',
              label: '% para outros fins',
            },
          ],
        },
        {
          type: 'VerticalLayout',
          elements: [
            {
              type: 'MonetaryInput',
              scope: '#/properties/valorFirmado',
              label: 'Valor Firmado',
            },
          ],
        },
      ],
    },
  ],
};

export default instituicaoConvenioUiSchema;
