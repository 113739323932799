const programaUiSchema = {
  type: 'VerticalLayout',
  elements: [
    {
      type: 'HorizontalLayout',
      elements: [
        {
          label: 'Nome',
          type: 'Control',
          scope: '#/properties/nome',
        },
      ],
    },
    {
      type: 'HorizontalLayout',
      elements: [
        {
          label: 'Sigla',
          type: 'Control',
          scope: '#/properties/sigla',
        },
        {
          label: 'Objetivo',
          type: 'Control',
          scope: '#/properties/objetivo',
        },
      ],
    },
    {
      type: 'HorizontalLayout',
      elements: [
        {
          label: 'Descrição',
          type: 'Control',
          scope: '#/properties/descricao',
        },
      ],
    },
    {
      type: 'HorizontalLayout',
      elements: [
        {
          label: 'Data de Início',
          type: 'Date',
          scope: '#/properties/dataInicio',
        },
        {
          label: 'Data de Término',
          type: 'Date',
          scope: '#/properties/dataTermino',
        },
      ],
    },
  ],
};

export default programaUiSchema;
