const termoPoliticaPrivacidadeUiSchema = {
  type: 'VerticalLayout',
  elements: [
    {
      type: 'CkEditor',
      scope: '#/properties/politicaDePrivacidade',
    },
  ],
};

export default termoPoliticaPrivacidadeUiSchema;
