import AddIcon from '@mui/icons-material/Add';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import { Button, Divider, Grid } from '@mui/material';
import { DocumentText } from '@styled-icons/fluentui-system-filled/DocumentText';
import { AttachText } from '@styled-icons/fluentui-system-regular/AttachText';
import { useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { makeEditalApi } from '../../../api/edital-api';
import { FileApi } from '../../../api/file-api';
import { makeApi } from '../../../api/generic-api';
import { useCrudContext } from '../../../components/crud/crud-context';
import {
  BoxAnexoContent,
  BoxAnexoContentItem,
  BoxAnexoName,
  BoxAnexoNameItem,
  BoxContent,
  ButtomLabel,
  ButtonContent,
  ComponentBox,
  CustomButton,
  EmptyAnexoMsg,
  Header,
  PdfContent,
  ShowAnexoLabel,
} from '../../../components/layout/style/edital/edital-detalhes';
import ValidityChecker from '../../../components/validity-checker/validity-checker';
import { urlFor } from '../../../helpers/url-for';

function EditalDetalhes() {
  const navigate = useNavigate();
  const routeParams = useParams();
  const [edital, setEdital] = useState(null);
  const [anexos, setAnexos] = useState([]);
  const [anexoNome, setAnexoNome] = useState('');
  const [selectedAnexo, setSelectedAnexo] = useState(null);
  const [url, setUrl] = useState('');
  const { setCurrentTitle } = useCrudContext();
  const editalId = Number(routeParams['editalDetalhesId'] ?? null) ?? null;
  const fileApi = new FileApi();
  const title = 'Detalhes do edital';

  useEffect(() => {
    setCurrentTitle(title);
    makeApi('/edital')
      .get(editalId)
      .then((x) => {
        setEdital(x);
      });
  }, []);

  useEffect(() => {
    if (edital) {
      fileApi.getFile('edital', 'edital-anexo', edital.id).then(({ data }) => {
        setAnexos(data);
      });
    }
  }, [edital]);

  const AnexoItem = ({ label, isSelected, onClick }) => (
    <ShowAnexoLabel colorValue={isSelected ? 'lightgray' : 'white'} onClick={onClick}>
      {label}
    </ShowAnexoLabel>
  );

  const AnexoList = ({ items }) => {
    const handleAnexoClick = (index, anexo) => {
      setAnexoNome(anexo.nome);
      setSelectedAnexo(index);
      makeEditalApi()
        .getEditalFileToken()
        .then(({ fileToken }) => {
          setUrl(
            urlFor(`edital/${edital?.id}/edital-anexo/${anexo.id}?api-key=${fileToken}&#zoom=100`),
          );
        });
    };

    return (
      <BoxAnexoName>
        {items.map((item, index) => (
          <AnexoItem
            key={index}
            label={item.nome}
            isSelected={selectedAnexo === index}
            onClick={() => handleAnexoClick(index, item)}
          />
        ))}
      </BoxAnexoName>
    );
  };

  return (
    <>
      <ComponentBox>
        <div className='back-button-container'>
          <Button className='back-button' onClick={() => navigate('/login')}>
            <ArrowBackIcon></ArrowBackIcon>
            Voltar
          </Button>
        </div>
        <Header>
          {edital ? edital.nome : ''}
          <ValidityChecker item={edital ?? {}} />
        </Header>
        <Divider />
        <BoxContent>
          <Grid container spacing={2}>
            <Grid item xs={4}>
              <BoxAnexoNameItem>
                <AttachText size='24' color='#003D92' /> Arquivos
                <AnexoList items={anexos} />
              </BoxAnexoNameItem>
            </Grid>
            <Grid item xs={8}>
              <BoxAnexoContentItem>
                <DocumentText size='24' color='#003D92' /> Pré-Visualização: {anexoNome}
                <BoxAnexoContent>
                  {url ? (
                    <PdfContent src={url}></PdfContent>
                  ) : (
                    <EmptyAnexoMsg>Nenhum documento selecionado!</EmptyAnexoMsg>
                  )}
                </BoxAnexoContent>
              </BoxAnexoContentItem>
            </Grid>
          </Grid>
        </BoxContent>
        <ButtonContent>
          <CustomButton onClick={() => alert('Em construção!')}>
            <AddIcon></AddIcon>
            <ButtomLabel>Criar Proposta</ButtomLabel>
          </CustomButton>
        </ButtonContent>
      </ComponentBox>
    </>
  );
}

export default EditalDetalhes;
