import { useParams } from 'react-router-dom';
import { Crud } from '../../../components/crud/crud';
import { Data, headCells } from './edicao-recurso-linha-de-acao.headcell';
import edicaoRecursoLinhaDeAcaoUiSchema from './edicao-recurso-linha-de-acao.uischema';

function EdicaoRecursoLinhaDeAcao() {
  const { edicaoLinhaDeAcaoId } = useParams();
  const edicaoLinhaDeAcaoIdNumber = Number(edicaoLinhaDeAcaoId);

  const defaultForm = {
    'tipoRecursoLinhaDeAcaoId-codigo': 'convenio',
    edicaoLinhaDeAcaoId: edicaoLinhaDeAcaoIdNumber,
    valor: 0,
  };

  return (
    <Crud<Data>
      headCells={headCells}
      uischema={edicaoRecursoLinhaDeAcaoUiSchema}
      titleConfig={{ value: 'Recursos da Linha de Ação', show: true }}
      apiUrl={'/edicao-recurso-linha-de-acao'}
      defaultForm={defaultForm}
      queryFilters={[{ chave: 'edicaoLinhaDeAcaoId', valor: edicaoLinhaDeAcaoIdNumber }]}
      hideDelete
      hideUpdate
    ></Crud>
  );
}

export default EdicaoRecursoLinhaDeAcao;

export const rootViews = ['edicao-linha-de-acao'];
