import { HeadCell } from '../../../components/crud/protocols/head-cell';

export interface Data extends Record<string, any> {
  id: number;
  nome: string;
}

export const moduleName = 'ORCAMENTO.CONVENIO.TIPO_CONVENIO';

export const displayProperties = ['nome'];

export const moduleLabel = 'Tipo do Convênio';

export const rowsLength = 7;

export const headCells: readonly HeadCell<Data>[] = [
  {
    id: 'nome',
    numeric: false,
    disablePadding: false,
    label: 'Nome',
  },
  {
    id: 'descricao',
    numeric: false,
    disablePadding: false,
    label: 'Descrição',
  },
];
