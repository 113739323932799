import { HeadCell } from '../../../components/crud/protocols/head-cell';

export interface Data extends Record<string, any> {
  id: number;
  instituicaoId: number;
  tipoParceriaId: number;
  valorFirmado: number;
  valorFinal: number;
}

export const moduleName = 'ORCAMENTO.CONVENIO.INSTITUICAO_CONVENIO.INSTITUICAO_CONVENIO';

export const displayProperties = ['instituicao.nome'];

export const moduleLabel = 'Instituição Convênio';

export const headCells: readonly HeadCell<Data>[] = [
  {
    id: 'instituicaoId',
    numeric: true,
    disablePadding: false,
    label: 'Nome da Instituição',
    resource: {
      name: 'instituicao',
      target: 'nome',
    },
  },
  {
    id: 'tipoParceriaId',
    numeric: true,
    disablePadding: false,
    label: 'Parceria',
    resource: {
      name: 'tipoParceria',
      target: 'nome',
    },
  },
  {
    id: 'valorFirmado',
    numeric: true,
    disablePadding: false,
    label: 'Valor Firmado',
    mask: 'money',
  },
  {
    id: 'valorFinal',
    numeric: true,
    disablePadding: false,
    label: 'Valor Final Estimado',
    mask: 'money',
  },
  {
    id: 'somaDesembolso',
    numeric: true,
    disablePadding: false,
    label: 'Soma do Desembolso',
    mask: 'money',
  },
  {
    id: 'desembolsoPago',
    numeric: true,
    disablePadding: false,
    label: 'Desembolso Pago',
    mask: 'money',
  },
];
