import { HeadCell } from '../../components/crud/protocols/head-cell';

export interface Data extends Record<string, any> {
  nome: string;
}

export const displayProperties = ['nome'];

export const moduleLabel = 'Editais';

export const moduleName = 'EDITAL.EDITAL';

export const headCells: readonly HeadCell<Data>[] = [
  {
    id: 'nome',
    numeric: false,
    disablePadding: false,
    label: 'Nome',
  },
];
