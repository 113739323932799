const planoApresentacaoUiSchema = {
  type: 'VerticalLayout',
  elements: [
    {
      label: 'Pergunta',
      type: 'Control',
      scope: '#/properties/pergunta',
    },
    {
      label: 'Mínimo de palavras',
      type: 'Control',
      scope: '#/properties/minimo',
    },
    {
      label: 'Máximo de palavras',
      type: 'Control',
      scope: '#/properties/maximo',
    },
  ],
};

export default planoApresentacaoUiSchema;
