import { Button, Tooltip } from '@mui/material';
import { Crud } from '../../components/crud/crud';
import DoneIcon from '@mui/icons-material/Done';
import { Data, headCells } from './usuario.headcell';
import usuarioUiSchema from './usuario.uischema';
import { Gear } from '@styled-icons/bootstrap/Gear';
import { CrudStatesOptions, useCrudContext } from '../../components/crud/crud-context';
import { makeApi } from '../../api/generic-api';
import { fixDate } from '../../helpers/fix-date';
import { useNavigate } from 'react-router-dom';

function Usuario() {
  const {
    updateCrudStates,
    setLoad,
    setFormData,
    schema,
    id,
    setId,
    setValidationMode,
    crudStates,
    showSuccess,
    showError,
    disabledFields,
    setDisabledFields,
  } = useCrudContext();
  const navigate = useNavigate();
  const usuarioApi = makeApi('/usuario');
  const configUserActions = {
    permission: 'USUARIO.USUARIO.EXIBIR_NO_MENU',
    action: (
      <Tooltip title='Configurar usuário' arrow>
        <Gear size='24' />
      </Tooltip>
    ),
    handler: (row: any) => {
      setLoad(true);
      usuarioApi
        ?.get?.(row.id)
        .then((data: any) => {
          fixDate(data, schema);
          setFormData(data);
          setTimeout(() => setLoad(false), 200);
        })
        .catch((err) => {
          console.error(err);
          setLoad(false);
        });
      setId(row.id);
      navigate(`/usuario/${row.id}`);
      updateCrudStates(CrudStatesOptions.EDIT);
    },
  };

  const customSaveButton = {
    button: (
      <Button>
        <DoneIcon> Salvar</DoneIcon>
      </Button>
    ),
    handler: async (id: number, formData: any, defaultSave: () => Promise<void>) => {
      setValidationMode('ValidateAndShow');

      try {
        if (crudStates.edit) {
          const response = await usuarioApi.put(id, formData);
          if (response.status === 200) {
            setValidationMode('ValidateAndHide');
            updateCrudStates(CrudStatesOptions.LIST);
            showSuccess('Configuração salva com sucesso');
          } else {
            console.error(response);
          }
        } else {
          await defaultSave();
        }
      } catch (error: any) {
        console.error(error);
        let errorMessage = error?.cause?.response?.data?.message || error?.response?.data?.message;
        if (Array.isArray(errorMessage)) errorMessage = errorMessage?.[0];
        showError(errorMessage || 'Ocorreu um erro.');
      }
    },
  };

  return (
    <Crud<Data>
      headCells={headCells}
      titleConfig={{ value: 'Usuário', show: true }}
      uischema={usuarioUiSchema}
      apiUrl={'/usuario'}
      queryFilters={[
        { chave: 'withDeleted', valor: true },
        { chave: 'orderBy', valor: 'nome' },
        { chave: 'orderDirection', valor: 'asc' },
      ]}
      hideCreate
      hideView
      hideUpdate
      customActions={[() => configUserActions]}
      customSave={customSaveButton}
    ></Crud>
  );
}

export default Usuario;
