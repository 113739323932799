const linhaDeAcaoUiSchema = {
  type: 'VerticalLayout',
  elements: [
    {
      type: 'HorizontalLayout',
      elements: [
        {
          label: 'Título',
          type: 'Control',
          scope: '#/properties/titulo',
        },
      ],
    },
    {
      type: 'HorizontalLayout',
      elements: [
        {
          type: 'Control',
          scope: '#/properties/objetivo',
        },
        {
          label: 'Descrição',
          type: 'Control',
          scope: '#/properties/descricao',
        },
      ],
    },
    {
      type: 'HorizontalLayout',
      elements: [
        {
          label: 'Informação da Publicação',
          type: 'Control',
          scope: '#/properties/informacaoPublicacao',
        },
      ],
    },
    {
      type: 'HorizontalLayout',
      elements: [
        {
          label: 'Data de Início',
          type: 'Date',
          scope: '#/properties/dataInicio',
        },
        {
          label: 'Data de Término',
          type: 'Date',
          scope: '#/properties/dataTermino',
        },
      ],
    },
  ],
};

export default linhaDeAcaoUiSchema;
