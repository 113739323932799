import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import { Button, Divider } from '@mui/material';
import { DocumentText } from '@styled-icons/fluentui-system-filled/DocumentText';
import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { makeApi } from '../../../api/generic-api';
import { useCrudContext } from '../../../components/crud/crud-context';
import {
  BoxFlex,
  ButtonLabel,
  ComponentBox,
  CustomButton,
  Header,
  ListItemEdital,
  ListItemEditalName,
  Search,
  WidthFull,
  WidthFullBox,
  WidthFullListItem,
} from '../../../components/layout/style/edital/todos-editais';
import { SearchInput } from '../../../components/search-input/search-input';
import ValidityChecker from '../../../components/validity-checker/validity-checker';

function TodosEditais() {
  const [data, setData] = useState([]);
  const [searchInput, setSearchInput] = useState('');
  const navigate = useNavigate();
  const { setCurrentTitle } = useCrudContext();
  const title = 'Todos os editais';

  useEffect(() => {
    setCurrentTitle(title);
    makeApi('/edital')
      .getAll([{ chave: 'vigentes', valor: 'true' }])
      .then((x) => {
        setData(x);
      });
  }, []);

  const editalDetalhes = (id: number) => {
    navigate(`/edital-detalhes/${id}`);
  };

  const handleSearchChange = (event) => {
    setSearchInput(event.target.value);
  };

  const filteredData = data.filter((item) =>
    item.nome.toLowerCase().includes(searchInput.toLowerCase()),
  );

  return (
    <>
      <ComponentBox>
        <div className='back-button-container'>
          <Button className='back-button' onClick={() => navigate('/login')}>
            <ArrowBackIcon></ArrowBackIcon>
            Voltar
          </Button>
        </div>
        <Header>
          <h3>Editais</h3>
        </Header>
        <Search>
          <SearchInput
            value={searchInput}
            onChange={handleSearchChange}
            placeholder='Pesquisar por nome de edital'
          />
        </Search>
        <WidthFull>
          {filteredData.map((item, index) => (
            <React.Fragment key={index}>
              <ListItemEdital disablePadding>
                <WidthFullListItem>
                  <WidthFullBox>
                    <ListItemEditalName>{item.nome}</ListItemEditalName>
                    <BoxFlex>
                      <ValidityChecker item={item} />
                      <CustomButton onClick={() => editalDetalhes(item.id)}>
                        <DocumentText size='18' />
                        <ButtonLabel>Visualizar Edital</ButtonLabel>
                      </CustomButton>
                    </BoxFlex>
                  </WidthFullBox>
                </WidthFullListItem>
              </ListItemEdital>
              {index < filteredData.length - 1 && <Divider />}
            </React.Fragment>
          ))}
        </WidthFull>
      </ComponentBox>
    </>
  );
}

export default TodosEditais;
