import CloseIcon from '@mui/icons-material/Close';
import { Box, Button, IconButton, Modal } from '@mui/material';
import Typography from '@mui/material/Typography';
import React from 'react';

interface CustomButtons {
  position: 'flex-start' | 'center' | 'flex-end' | 'space-between' | 'space-evenly';
  buttons: {
    label: string;
    variant?: 'text' | 'contained' | 'outlined';
    disabled?: boolean;
    onClick?: () => void;
  }[];
}

interface CustomModalProps {
  title: React.ReactNode | string;
  content: React.ReactNode | string;
  openModalState: any;
  customButtons?: CustomButtons;
  isFixed?: boolean;
  hasWindowCloseBtn?: boolean;
  handleCloseModal?: () => void;
}

const CustomModal: React.FC<CustomModalProps> = ({
  title,
  content,
  openModalState,
  customButtons,
  isFixed,
  hasWindowCloseBtn,
  handleCloseModal,
}) => {
  const style = {
    position: 'absolute' as 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: '60%',
    maxHeight: '600px',
    overflowY: 'auto',
    bgcolor: 'background.paper',
    boxShadow: 24,
    p: 4,
    borderRadius: '3px',
  };

  return (
    <Modal
      open={openModalState}
      onClose={!isFixed ? handleCloseModal : undefined}
      aria-labelledby='modal-modal-title'
      aria-describedby='modal-modal-description'
    >
      <Box sx={style}>
        {hasWindowCloseBtn ? (
          <IconButton aria-label='close' style={{ float: 'right' }} onClick={handleCloseModal}>
            <CloseIcon />
          </IconButton>
        ) : null}
        <Typography id='modal-modal-title' variant='h6' component='h2' sx={{ mt: 6 }}>
          {title}
        </Typography>
        <Typography id='modal-modal-description' sx={{ mt: 3 }}>
          {content}
        </Typography>
        {customButtons && (
          <Box
            id='modal-modal-actions'
            sx={{ mt: 6, display: 'flex', justifyContent: customButtons.position }}
          >
            {customButtons.buttons.map((button: any, index: number) => (
              <Button
                key={index}
                onClick={button.onClick}
                sx={{ ml: 2 }}
                variant={button.variant ?? 'text'}
                disabled={button.disabled}
              >
                {button.label}
              </Button>
            ))}
          </Box>
        )}
      </Box>
    </Modal>
  );
};

export default CustomModal;
