import Box from '@mui/material/Box';
import Divider from '@mui/material/Divider';
import React, { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { makeApi } from '../../../api/generic-api';
import {
  ComponentBox,
  EditalEmpty,
  EditalHeaderLabel,
  EditalIcon,
  EditalList,
  FooterBox,
  FooterButton,
  Header,
  WhiteBackgroundList,
  WhiteBackgroundListItem,
  WhiteBackgroundListItemButton,
} from '../../../components/layout/style/edital/edital-home';
import ValidityChecker from '../../../components/validity-checker/validity-checker';

export const EditalHome = () => {
  const [data, setData] = React.useState([]);
  const navigate = useNavigate();

  useEffect(() => {
    makeApi('/edital')
      .getAll([
        { chave: 'vigentes', valor: 'true' },
        { chave: 'take', valor: 5 },
      ])
      .then((x) => {
        setData(x);
      });
  }, []);

  const editalDetalhes = (id: number) => {
    navigate(`/edital-detalhes/${id}`);
  };

  return (
    <ComponentBox>
      <Header>
        <EditalIcon />
        <EditalHeaderLabel>Editais</EditalHeaderLabel>
      </Header>
      <Divider />
      <nav aria-label='main mailbox folders'>
        <WhiteBackgroundList>
          {data.length ? (
            data.map((item, index) => (
              <React.Fragment key={index}>
                <WhiteBackgroundListItem disablePadding onClick={() => editalDetalhes(item.id)}>
                  <WhiteBackgroundListItemButton>
                    <Box>
                      <EditalList>{item.nome}</EditalList>
                      <ValidityChecker item={item} />
                    </Box>
                  </WhiteBackgroundListItemButton>
                </WhiteBackgroundListItem>
                {index < data.length - 1 && <Divider />}
              </React.Fragment>
            ))
          ) : (
            <EditalEmpty>Nenhum edital com vigência em aberto!</EditalEmpty>
          )}
        </WhiteBackgroundList>
        {
          <>
            <Divider />
            <FooterBox>
              <FooterButton>
                <a href='/todos-editais'>Ver Todos</a>
              </FooterButton>
            </FooterBox>
          </>
        }
      </nav>
    </ComponentBox>
  );
};
