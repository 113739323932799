import { HeadCell } from '../../../components/crud/protocols/head-cell';

export interface Data extends Record<string, any> {
  id: number;
  tipoTermoAditivoId: number;
  dataPublicacao: string;
  instituicaoId: number;
  valorAditivo: number;
}

export const moduleName = 'ORCAMENTO.CONVENIO.TERMO_ADITIVO_CONVENIO.TERMO_ADITIVO_CONVENIO';

export const moduleLabel = 'Termo Aditivo do Convênio';

export const headCells: readonly HeadCell<Data>[] = [
  {
    id: 'codigo',
    numeric: false,
    disablePadding: false,
    label: 'Código',
  },
  {
    id: 'tipoTermoAditivoId',
    numeric: true,
    disablePadding: false,
    label: 'Tipo de Termo Aditivo',
    resource: {
      name: 'tipoTermoAditivo',
      target: 'nome',
    },
  },
  {
    id: 'dataPublicacao',
    numeric: false,
    disablePadding: false,
    label: 'Data de Publicação',
    dateFormat: 'DD/MM/YYYY',
  },
  {
    id: 'dataAssinatura',
    numeric: false,
    disablePadding: false,
    label: 'Data de Assinatura',
    dateFormat: 'DD/MM/YYYY',
  },
];
