import { stepHandlerErrors } from '../../../utils/step-handler-errors';

const termoAceiteUiSchema = {
  type: 'VerticalLayout',
  elements: [
    {
      type: 'CkEditor',
      scope: '#/properties/termoDeAceite',
      options: {
        label: 'Termo de Aceite',
        required: true,
      },
    },
  ],
};

export default termoAceiteUiSchema;
