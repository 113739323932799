const historicoAjusteValorBolsaUiSchema = {
  type: 'VerticalLayout',
  elements: [
    {
      type: 'MonetaryInput',
      scope: '#/properties/valorAjuste',
      label: 'Valor',
    },
  ],
};

export default historicoAjusteValorBolsaUiSchema;
