import { FormApi, makeApi } from '../../../api/generic-api';

const edicaoRecursoProgramaUiSchema = {
  type: 'VerticalLayout',
  elements: [
    {
      type: 'Select',
      scope: '#/properties/linhaDeAcaoId',
      options: {
        apiInstance: makeApi('/edicao-linha-de-acao'),
        filter: {
          displayProperties: ['linhaDeAcao.titulo'],
          handler: (formData: any, listData: any[], api: FormApi) => {
            if (!formData.ano) return listData;
            const resultFn = async () => {
              return (await api.getAll([{ chave: 'ano', valor: formData?.ano }]))
                .map((x: any) => {
                  x.id = x.linhaDeAcaoId;
                  return x;
                })
                .filter(
                  (x: any) =>
                    formData.id ||
                    !formData.edicoesRecurso.some(
                      (y: any) => y.edicaoLinhaDeAcao.linhaDeAcaoId === x.linhaDeAcaoId,
                    ),
                );
            };
            return resultFn();
          },
        },
      },
    },
    {
      type: 'MonetaryInput',
      scope: '#/properties/valor',
    },
  ],
};

export default edicaoRecursoProgramaUiSchema;
