import { Crud } from '../../../components/crud/crud';
import { Data, headCells } from './tipo-convenio.headcell';
import tipoConvenioUiSchema from './tipo-convenio.uischema';

function TipoConvenio() {
  const defaultForm = { nome: '' };

  return (
    <Crud<Data>
      headCells={headCells}
      titleConfig={{ value: 'Tipo de Convênio', show: true }}
      apiUrl={'/tipo-convenio'}
      uischema={tipoConvenioUiSchema}
      defaultForm={defaultForm}
      queryFilters={[{ chave: 'withDeleted', valor: true }]}
      hideView
    ></Crud>
  );
}

export default TipoConvenio;
