import { getUischemaElements } from './get-uischema-elements';
import { scopeToPath } from './scope-to-path';

export const getUischemaConfigs = (uischema: any) => {
  const paths: string[] = [];
  const requiredPaths: string[] = [];
  const instancePaths: string[] = [];
  const requiredScopes: string[] = [];

  const elements = getUischemaElements(uischema);

  elements.forEach((el: any) => {
    const path = scopeToPath(el?.scope);
    if (el?.options?.required === true) {
      requiredScopes.push(el?.scope);
      requiredPaths.push(path);
    }
    paths.push(path);
    instancePaths.push(`/${path?.replace?.('.', '/')}`);
  });

  return {
    paths,
    requiredPaths,
    instancePaths,
    requiredScopes,
  };
};
