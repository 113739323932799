import { rankWith, uiTypeIs } from '@jsonforms/core';
import { withJsonFormsControlProps } from '@jsonforms/react';
import { Hidden, OutlinedInputProps, TextField } from '@mui/material';
import React, { ChangeEvent } from 'react';
import { useCrudContext } from '../../components/crud/crud-context';
import { translateErrors } from '../../components/crud/i18n';
import './renderer.css';

const moneyTester = rankWith(5, uiTypeIs('MonetaryInput'));

export const moneyRender = {
  tester: moneyTester,
  renderer: withJsonFormsControlProps(({ visible = true, schema, uischema, ...props }) => {
    const {
      errorsJsonForms,
      additionalErrors,
      validationMode,
      formData,
      crudStates,
      disabledFields,
    } = useCrudContext();
    const [value, setValue] = React.useState('');
    const [maxLength, setMaxLength] = React.useState<number | null>(null);

    React.useEffect(() => {
      if (schema?.maxLength) {
        setMaxLength(schema?.maxLength);
      }

      if (props.data) {
        setValue(formatReal(props.data));
      }
    }, [props?.data]);

    const isDisabled: boolean =
      crudStates.view ||
      (crudStates.edit && uischema?.options?.onlyCreate) ||
      uischema.options?.disabled ||
      disabledFields.includes(props.path);

    const hasError = () => {
      return (props.required && !formData?.[props.path]) || !!getError();
    };

    const getError = () => {
      const instancePath = `/${props?.path?.replace?.('.', '/')}`;
      const errorJsonForms = errorsJsonForms?.find?.(
        (error) =>
          error?.params?.missingProperty === props?.path || error?.instancePath === instancePath,
      );
      const additionalError = additionalErrors?.find?.(
        (error) =>
          error?.params?.missingProperty === props?.path || error?.instancePath === instancePath,
      );
      return errorJsonForms ?? additionalError;
    };

    const getErrorMessage = () => {
      const error = getError();
      return translateErrors?.(error) ?? error?.message ?? '';
    };

    const formatReal = (input: string) => {
      const numericValue = Number(input);
      const realValue = numericValue / 100;
      return realValue.toLocaleString('pt-BR', {
        minimumFractionDigits: 2,
        maximumFractionDigits: 2,
      });
    };

    const handleChange = (event: ChangeEvent<HTMLInputElement>) => {
      const originalCursorPosition = event.target.selectionStart ?? 0;
      const originalLength = event.target.value.length;

      const inputValue = event.target.value.replace(/[^0-9]/g, '');
      const formattedValue = formatReal(inputValue);
      setValue(formattedValue);
      props.handleChange(props?.path, Number(inputValue));

      const lengthDifference = formattedValue.length - originalLength;
      let newCursorPosition = originalCursorPosition + lengthDifference;
      newCursorPosition = Math.max(0, Math.min(newCursorPosition, formattedValue.length));

      if (uischema.options?.handleChange) {
        uischema.options?.handleChange?.(Number(inputValue), props.handleChange, formData);
      }

      event.target.setSelectionRange(newCursorPosition, newCursorPosition);
    };

    return (
      <Hidden xsUp={!visible}>
        <div className='custom-input-container'>
          <TextField
            {...props}
            variant='filled'
            className={`input ${
              (isDisabled && 'disabled-field') ||
              (hasError() && validationMode === 'ValidateAndShow' && 'has-error')
            }`}
            label={props.label}
            required={props.required}
            disabled={isDisabled}
            defaultValue={props?.data ?? ''}
            value={value}
            onChange={handleChange}
            InputProps={
              {
                disableUnderline: true,
                maxLength: maxLength ?? undefined,
              } as Partial<OutlinedInputProps>
            }
          />
          {validationMode === 'ValidateAndShow' && hasError() && (
            <span className='error-message'>{getErrorMessage()}</span>
          )}
        </div>
      </Hidden>
    );
  }),
};
