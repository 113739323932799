import { HeadCell } from '../../../components/crud/protocols/head-cell';

export interface Data extends Record<string, any> {
  id: number;
  valorAjuste: number;
  createdAt: Date;
}

export const moduleName =
  'BOLSA.MODALIDADE_DE_BOLSA.NIVEL_BOLSA.HISTORICO_AJUSTE_VALOR_BOLSA.HISTORICO_AJUSTE_VALOR_BOLSA';

export const moduleLabel = ' Ajuste do Valor da Bolsa';

export const headCells: readonly HeadCell<Data>[] = [
  {
    id: 'valorAjuste',
    numeric: true,
    disablePadding: false,
    label: 'Valor',
    mask: 'money',
  },
  {
    id: 'createdAt',
    numeric: false,
    disablePadding: false,
    label: 'Data da Atualização',
    dateFormat: 'DD/MM/YYYY',
  },
];
