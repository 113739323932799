import { Tooltip } from '@mui/material';
import { ClipboardPencil } from '@styled-icons/foundation/ClipboardPencil';
import dayjs from '../../../components/dayjs/sig-dayjs';
import { useNavigate } from 'react-router-dom';
import { Crud } from '../../../components/crud/crud';
import { useCrudContext } from '../../../components/crud/crud-context';
import { onlyNumbers } from '../../../helpers/custom-errors';
import { Data, headCells } from './tesouro.headcell';
import tesouroUiSchema from './tesouro.uischema';

function Tesouro() {
  const navigate = useNavigate();

  const customErrors = [onlyNumbers('ano')];
  const defaultForm = {
    ano: dayjs().format('YYYY'),
  };

  const { parameterFilters, setParameterFilters, clearForm } = useCrudContext();
  const deleteMessageHandler = (formData: any) => `Tesouro: ${formData?.ano}`;

  const ajusteTesouro = {
    permission:
      'ORCAMENTO.TESOURO.HISTORICO_AJUSTE_TESOURO.HISTORICO_AJUSTE_TESOURO.EXIBIR_NO_MENU',
    action: (
      <Tooltip title='Ajuste de Tesouro' arrow>
        <ClipboardPencil size='24' />
      </Tooltip>
    ),
    handler: (row: any) => {
      setParameterFilters({
        ...parameterFilters,
        tesouro: row,
      });
      clearForm();
      navigate(`/tesouro/${row.id}/historico-ajuste-tesouro`);
    },
  };

  return (
    <Crud<Data>
      headCells={headCells}
      titleConfig={{ value: 'Tesouro', show: true }}
      uischema={tesouroUiSchema}
      apiUrl={'/tesouro'}
      queryFilters={[
        { chave: 'withDeleted', valor: true },
        { chave: 'orderBy', valor: 'ano' },
        { chave: 'orderDirection', valor: 'desc' },
      ]}
      hideView
      hideUpdate
      customErrors={customErrors}
      defaultForm={defaultForm}
      deleteMessageHandler={deleteMessageHandler}
      customActions={[() => ajusteTesouro]}
    ></Crud>
  );
}

export default Tesouro;
