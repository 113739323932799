import { Button, CssBaseline, TextField, Typography } from '@mui/material';
import { useEffect, useState } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import AlertCustom from '../../components/crud/alert-custom';
import { useCrudContext } from '../../components/crud/crud-context';
import { LoginApi, makeLoginApi } from '../../api/login-api';
import './login.css';
import LogoFundacao from '../../components/layout/logo-fundacao';
import RescueImg from './rescue.png';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';

const RecuperarSenha = () => {
  const navigate = useNavigate();
  const [api, setApi] = useState<LoginApi>();
  const { openAlert, messageAlert, severityAlert, showError, showSuccess } = useCrudContext();
  const [login, setlogin] = useState<string>('');

  useEffect(() => {
    const loginApi = makeLoginApi();
    setApi(loginApi);
  }, []);

  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setlogin(e.target.value);
  };

  const handleSubmit = (e: React.FormEvent) => {
    e.preventDefault();
    const emailRegex = /^[a-zA-Z0-9._-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,4}$/;
    if (!login?.match?.(emailRegex)) {
      showError('E-mail inválido');
      return;
    }
    api
      ?.recuperarSenha(login)
      .then((res) => {
        showSuccess('E-mail enviado para recuperação de senha. Verifique sua caixa de entrada.');
      })
      .catch((err) => {
        showError(
          err?.cause?.response?.data?.message?.[0] ||
            err?.response?.data?.message ||
            'Ocorreu um erro.',
        );
      });
  };

  return (
    <div className='div-group'>
      <div className='background' />

      <div className='login-container'>
        <CssBaseline />

        <div className='back-button-container'>
          <Button className='back-button' onClick={() => navigate('/login')}>
            <ArrowBackIcon></ArrowBackIcon>
            Voltar
          </Button>
        </div>

        <div className='paper'>
          <LogoFundacao className='logo-image' />
          <Typography component='h1' variant='h5' style={{ color: '#454545', fontWeight: '700' }}>
            Olá, tripulante!
          </Typography>
          <Typography component='h4' style={{ color: '#939495' }}>
            Vamos enviar o resgate para uma nova senha{' '}
            <img width={18} height={18} src={RescueImg} alt='Resgate' />
          </Typography>
          <form className='login-form' onSubmit={handleSubmit}>
            <Typography
              component='h1'
              variant='subtitle1'
              style={{ color: '#454545', fontWeight: '500', marginTop: '20px' }}
            >
              Insira o e-mail para enviarmos a sua recuperação de senha.
            </Typography>
            <TextField
              variant='outlined'
              margin='normal'
              required
              fullWidth
              id='login'
              label='Email'
              name='login'
              value={login}
              onChange={handleChange}
            />
            <Button type='submit' fullWidth variant='contained' color='primary' className='submit'>
              Recuperar senha
            </Button>
          </form>
          <div className='links-container'>
            <Link to='/registrar' className='link'>
              Não tem conta? Crie uma agora!
            </Link>
            <Link to='/login' className='link'>
              Login
            </Link>
          </div>
        </div>
        <AlertCustom open={openAlert} message={messageAlert} severity={severityAlert}></AlertCustom>
      </div>
    </div>
  );
};

export default RecuperarSenha;

export const disableAutoRoute = true;
