import { Crud } from '../../components/crud/crud';
import { Data, headCells } from './edital.headcell';
import editalUischema from './edital.uischema';

function EditarPerfil() {
  return (
    <Crud<Data>
      headCells={headCells}
      titleConfig={{ value: 'Edital', show: true }}
      uischema={editalUischema}
      queryFilters={[
        { chave: 'withDeleted', valor: true },
        { chave: 'orderBy', valor: 'nome' },
        { chave: 'orderDirection', valor: 'ASC' },
      ]}
      apiUrl={'/edital'}
    ></Crud>
  );
}

export default EditarPerfil;
