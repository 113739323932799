import EditIcon from '@mui/icons-material/Edit';
import { IconButton, TableCell, TableCellProps, Tooltip } from '@mui/material';
import { CheckSquare } from '@styled-icons/boxicons-solid/CheckSquare';
import AddIcon from '@mui/icons-material/Add';
import { Blocked } from 'styled-icons/icomoon';
import { Data } from './area-de-conhecimento.view';
import { Secure } from '../../../components/auth/secure';
import { moduleName } from './area-de-conhecimento.headcell';

interface AreaDeConhecimentoActionsCellProps {
  row: Data;
  restoreHandler?: Function;
  editHandler?: Function;
  addHandler?: Function;
  openDeleteHandler?: Function;
  restoreAndUpdateHandler?: Function;
  sx?: TableCellProps['sx'];
}

export function AreaDeConhecimentoActionsCell(props: AreaDeConhecimentoActionsCellProps) {
  const { row, editHandler, openDeleteHandler, sx, addHandler, restoreAndUpdateHandler } = props;

  const addSubArea = row?.numeroNivel === 4;

  return (
    <TableCell className='actions' sx={{ display: 'flex', padding: 0, height: '48px', ...sx }}>
      {row.deletedAt && (
        <Tooltip title='Ativar' arrow>
          <Secure permission={`${moduleName}.RESTAURAR`}>
            <IconButton
              onClick={() => {
                restoreAndUpdateHandler?.(row);
              }}
              sx={{ margin: '0 auto' }}
            >
              <CheckSquare size='24' className='icon-restore'></CheckSquare>
            </IconButton>
          </Secure>
        </Tooltip>
      )}
      {!row.deletedAt && (
        <Tooltip title='Editar' arrow>
          <Secure permission={`${moduleName}.ATUALIZAR`}>
            <IconButton onClick={() => editHandler?.(row)}>
              <EditIcon className='icon-edit'></EditIcon>
            </IconButton>
          </Secure>
        </Tooltip>
      )}

      {!addSubArea && !row.deletedAt && (
        <Tooltip title='Adicionar sub-área' arrow>
          <Secure permission={`${moduleName}.CRIAR`}>
            <IconButton onClick={() => addHandler?.(row)}>
              <AddIcon color='success' />
            </IconButton>
          </Secure>
        </Tooltip>
      )}
      {!row.deletedAt && (
        <Tooltip title='Desativar' arrow>
          <Secure permission={`${moduleName}.DELETAR`}>
            <IconButton
              onClick={() => {
                openDeleteHandler?.(row);
              }}
            >
              <Blocked size='18' className='icon-delete'></Blocked>
            </IconButton>
          </Secure>
        </Tooltip>
      )}
    </TableCell>
  );
}
