import { HeadCell } from '../../../components/crud/protocols/head-cell';

export interface Data extends Record<string, any> {
  id: number;
  codigo: string;
}

export const displayProperties = ['codigo'];

export const moduleLabel = 'Tipo da Rubrica';

export const headCells: readonly HeadCell<Data>[] = [
  {
    id: 'codigo',
    numeric: false,
    disablePadding: false,
    label: 'Tipo',
  },
];
