import { JsonSchema, ValidationMode } from '@jsonforms/core';
import React, { createContext, useContext } from 'react';
import { Severity } from './alert-custom';

export interface CrudStates {
  add?: boolean;
  view?: boolean;
  edit?: boolean;
  list?: boolean;
}

export enum CrudStatesOptions {
  ADD,
  VIEW,
  EDIT,
  LIST,
}

export interface CrudContextData {
  crudStates: CrudStates;
  setCrudStates: React.Dispatch<any>;
  updateCrudStates: (option: CrudStatesOptions) => void;
  errorsJsonForms: any[];
  setErrorsJsonForm: (errors: any[]) => void;
  errorsCustom: any[];
  setErrorsCustom: (errors: any[]) => void;
  additionalErrors: any[];
  setAdditionalErrors: (additionalErrors: any[]) => void;
  validationMode: ValidationMode;
  setValidationMode: (value: ValidationMode) => void;
  formData: any;
  setFormData: (value: any) => void;
  apiListData: any;
  setApiListData: (value: any[]) => void;
  schema: JsonSchema;
  setSchema: React.Dispatch<React.SetStateAction<JsonSchema>>;
  currentUiSchema: any;
  setCurrentUiSchema: (value: any) => void;
  clearForm: () => void;
  severityAlert: Severity;
  messageAlert: string;
  openAlert: boolean;
  setOpenAlert: React.Dispatch<React.SetStateAction<boolean>>;
  showSuccess: (message: string) => void;
  showError: (message: string) => void;
  load: boolean;
  setLoad: React.Dispatch<React.SetStateAction<boolean>>;
  parameterFilters: any;
  setParameterFilters: React.Dispatch<any>;
  disabledFields: string[];
  setDisabledFields: React.Dispatch<React.SetStateAction<string[]>>;
  currentTitle: string;
  setCurrentTitle: (value: string) => void;
  currentApiUrl: string;
  setCurrentApiUrl: (value: string) => void;
  id: number;
  setId: (id: number) => void;
}

const CrudContext = createContext<CrudContextData | undefined>(undefined);

export const CrudProvider: React.FC = ({ children }) => {
  const [crudStates, setCrudStates] = React.useState<any>({ list: true });
  const [errorsJsonForms, setErrorsJsonForm] = React.useState<any[]>([]);
  const [errorsCustom, setErrorsCustom] = React.useState<any[]>([]);
  const [validationMode, setValidationMode] = React.useState<ValidationMode>('ValidateAndHide');
  const [apiListData, setApiListData] = React.useState<any>([]);
  const [formData, setFormData] = React.useState({});
  const [schema, setSchema] = React.useState<JsonSchema>({});
  const [currentUiSchema, setCurrentUiSchema] = React.useState({});
  const [additionalErrors, setAdditionalErrors] = React.useState<any[]>([]);
  const [load, setLoad] = React.useState(false);
  const [parameterFilters, setParameterFilters] = React.useState<any>({});
  const [disabledFields, setDisabledFields] = React.useState<string[]>([]);
  const [currentTitle, setCurrentTitle] = React.useState('');
  const [currentApiUrl, setCurrentApiUrl] = React.useState('');
  const [id, setId] = React.useState(-1);

  // Custom Alert
  const [openAlert, setOpenAlert] = React.useState(false);
  const [messageAlert, setMessageAlert] = React.useState('');
  const [severityAlert, setSeverityAlert] = React.useState<Severity>(Severity.SUCCESS);

  const clearForm = () => {
    setErrorsJsonForm([]);
    setErrorsCustom([]);
    setAdditionalErrors([]);
    setValidationMode('ValidateAndHide');
    setFormData({});
    setSchema({});
    setId(-1);
    setDisabledFields([]);
  };

  const updateCrudStates = (option: CrudStatesOptions) => {
    let data = {
      list: false,
      add: false,
      edit: false,
      view: false,
    };
    switch (option) {
      case CrudStatesOptions.LIST:
        data.list = true;
        break;
      case CrudStatesOptions.ADD:
        data.add = true;
        break;
      case CrudStatesOptions.EDIT:
        data.edit = true;
        break;
      case CrudStatesOptions.VIEW:
        data.view = true;
        break;
    }
    setCrudStates(data);
  };

  const showSuccess = (message: string) => {
    setMessageAlert(message);
    setSeverityAlert(Severity.SUCCESS);
    setOpenAlert(true);
    setTimeout(() => setOpenAlert(false), 3000);
  };

  const showError = (message: string) => {
    setMessageAlert(message);
    setSeverityAlert(Severity.ERROR);
    setOpenAlert(true);
    setTimeout(() => setOpenAlert(false), 3000);
  };

  return (
    <CrudContext.Provider
      value={{
        crudStates,
        setCrudStates,
        updateCrudStates,
        errorsJsonForms,
        setErrorsJsonForm,
        errorsCustom,
        setErrorsCustom,
        additionalErrors,
        setAdditionalErrors,
        validationMode,
        setValidationMode,
        apiListData,
        setApiListData,
        formData,
        setFormData,
        schema,
        setSchema,
        clearForm,
        currentUiSchema,
        setCurrentUiSchema,
        severityAlert,
        messageAlert,
        openAlert,
        setOpenAlert,
        showSuccess,
        showError,
        load,
        setLoad,
        parameterFilters,
        setParameterFilters,
        disabledFields,
        setDisabledFields,
        currentTitle,
        setCurrentTitle,
        currentApiUrl,
        setCurrentApiUrl,
        id,
        setId,
      }}
    >
      {children}
    </CrudContext.Provider>
  );
};

export const useCrudContext = () => {
  const context = useContext(CrudContext);

  if (!context) {
    throw new Error('useCrudContext deve ser usado dentro de um CrudProvider');
  }

  return context;
};
