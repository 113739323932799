import { HeadCell } from '../../../components/crud/protocols/head-cell';

export interface Data extends Record<string, any> {
  id: number;
  nome: string;
}

export const moduleName = 'CONFIGURACOES_INICIAIS.TIPO_ANEXO';

export const headCells: readonly HeadCell<Data>[] = [
  {
    id: 'nome',
    numeric: false,
    disablePadding: false,
    label: 'Tipos de Anexos',
  },
];
