import { makeApi } from '../../../api/generic-api';

const getCodigoPaisInstituicao = async (
  instituicao: any,
  updater: (path: string, value: any) => void,
) => {
  const instSchema = await makeApi('instituicao').getSchema();
  const paisIdSchema = instSchema?.properties?.paisId;
  const country = instituicao?.pais;
  let foreignEnum;
  if ('codigo' in country && (foreignEnum = (paisIdSchema as any)?.foreignEnum)) {
    const [codeKey] =
      Object.entries(foreignEnum).find(([, value]) => value === country.codigo) ?? [];
    setTimeout(() => updater('paisId-codigo', codeKey), 200);
  }
};

const hideIfBrasil = {
  effect: 'SHOW',
  condition: {
    scope: '#/properties/paisId-codigo',
    schema: {
      not: {
        const: 'brasil',
      },
    },
  },
};

const showIfBrasil = {
  effect: 'HIDE',
  condition: {
    scope: '#/properties/paisId-codigo',
    schema: {
      not: {
        const: 'brasil',
      },
    },
  },
};

const unidadeUiSchema = {
  type: 'VerticalLayout',
  elements: [
    {
      type: 'HorizontalLayout',
      elements: [
        {
          type: 'Select',
          scope: '#/properties/instituicaoId',
          label: 'Instituição',
          options: {
            handleChange: getCodigoPaisInstituicao,
          },
        },
      ],
    },
    {
      type: 'HorizontalLayout',
      elements: [
        {
          type: 'VerticalLayout',
          elements: [
            {
              type: 'Control',
              scope: '#/properties/nome',
              label: 'Nome',
            },
            {
              type: 'Cep',
              scope: '#/properties/endereco/properties/cep',
              label: 'CEP',
              rule: showIfBrasil,
              options: {
                mask: '99999-999',
              },
            },
            {
              type: 'Text',
              scope: '#/properties/endereco/properties/cep',
              label: 'ZipCode',
              rule: hideIfBrasil,
              options: {
                maxLength: 9,
              },
            },
            {
              type: 'Control',
              scope: '#/properties/endereco/properties/municipio',
              label: 'Município',
            },
            {
              type: 'Control',
              scope: '#/properties/endereco/properties/bairro',
              label: 'Bairro',
              rule: hideIfBrasil,
              options: {
                required: true,
              },
            },
          ],
        },
        {
          type: 'VerticalLayout',
          elements: [
            {
              type: 'Control',
              scope: '#/properties/sigla',
              label: 'Sigla',
            },
            {
              type: 'Control',
              scope: '#/properties/endereco/properties/estado',
              label: 'Estado/Região',
              // rule: hideIfBrasil,
            },
            {
              type: 'Control',
              scope: '#/properties/endereco/properties/logradouro',
            },
            {
              type: 'Control',
              scope: '#/properties/endereco/properties/complemento',
              rule: hideIfBrasil,
            },
          ],
        },
      ],
    },
    {
      type: 'HorizontalLayout',
      elements: [
        {
          type: 'VerticalLayout',
          elements: [
            {
              type: 'Control',
              scope: '#/properties/endereco/properties/bairro',
              label: 'Bairro',
              rule: showIfBrasil,
              options: {
                required: true,
              },
            },
          ],
        },
        {
          type: 'VerticalLayout',
          elements: [
            {
              type: 'HorizontalLayout',
              elements: [
                {
                  type: 'VerticalLayout',
                  elements: [
                    {
                      type: 'Control',
                      scope: '#/properties/endereco/properties/numero',
                      label: 'Número',
                      rule: showIfBrasil,
                      options: {
                        required: true,
                      },
                    },
                  ],
                },
                {
                  type: 'VerticalLayout',
                  elements: [
                    {
                      type: 'Control',
                      scope: '#/properties/endereco/properties/complemento',
                      rule: showIfBrasil,
                    },
                  ],
                },
              ],
            },
          ],
        },
      ],
    },
  ],
};

export default unidadeUiSchema;
