import { FiletypeXls } from '@styled-icons/bootstrap/FiletypeXls';
import { Attachment } from '@styled-icons/typicons/Attachment';
import { Newspaper } from 'styled-icons/heroicons-outline';
import { DocumentAttach } from '@styled-icons/ionicons-sharp/DocumentAttach';
import { ShoppingBag } from '@styled-icons/material/ShoppingBag';
import { CrudContextData } from '../../components/crud/crud-context';
import { createResource } from '../../utils/create-resource';
import { stepHandlerBack } from '../../utils/step-handler-back';
import { stepHandlerErrors } from '../../utils/step-handler-errors';
import { customCalendarDayIcon } from './customCalendarDayIcon';
import { stepHandlerDocumentoProposta } from './handlers/stepHandlerDocumentoProposta';
import { FormApi, GenericApi } from '../../api/generic-api';

const iconConfig = [
  {
    category: 'Dados do Edital',
    icon: Newspaper,
  },
  {
    category: 'Chamadas',
    icon: customCalendarDayIcon,
  },
  {
    category: 'Documentos da Proposta',
    icon: DocumentAttach,
  },
  {
    category: 'Bolsas',
    icon: ShoppingBag,
  },
  {
    category: 'Anexos do Edital',
    icon: Attachment,
  },
];

const visibleIfHasFilter = (ctx: CrudContextData, apiListData: any[], queryFilterValues: any[]) => {
  const filterValue = queryFilterValues?.[0]?.value;
  if (filterValue !== null && filterValue !== undefined) return true;
  return false;
};

const formatLabel = (item: any) => {
  const valueInReal = item.valorAtualBolsa / 100;
  const formattedValue = new Intl.NumberFormat('pt-BR', {
    style: 'currency',
    currency: 'BRL',
  }).format(valueInReal);
  return `${item.nivel} (${item.cargaHoraria}H - ${formattedValue})`;
};

const editalUiSchema = {
  type: 'Categorization',
  elements: [
    {
      type: 'Category',
      label: 'Dados do Edital',
      options: {
        stepHandler: {
          handler: stepHandlerErrors,
          url: '/edital',
        },
      },
      elements: [
        {
          type: 'Control',
          scope: '#/properties/nome',
          label: 'Título do Edital',
          options: {
            required: true,
          },
        },
        {
          type: 'Select',
          scope: '#/properties/programaId',
          label: 'Programa',
          options: {
            required: true,
          },
        },
      ],
    },
    {
      type: 'Category',
      label: 'Chamadas',
      options: {
        stepHandler: {
          handler: stepHandlerErrors,
          callback: (ctx: CrudContextData) => createResource(ctx, '/edital'),
        },
      },
      elements: [
        {
          type: 'Control',
          scope: '#/properties/chamadaEdital',
          label: 'Chamadas',
          options: {
            itemLayout: 'SimpleItemLayout',
            detail: {
              type: 'HorizontalLayout',
              elements: [
                {
                  type: 'Control',
                  scope: '#/properties/inicio',
                  label: 'Data de Início',
                  options: {
                    format: 'date-time',
                    dateTimeFormat: 'DD-MM-YY HH:mm:ss',
                    ampm: false,
                  },
                },
                {
                  type: 'Control',
                  scope: '#/properties/termino',
                  label: 'Data de Fim',
                  options: {
                    format: 'date-time',
                    dateTimeFormat: 'DD-MM-YY HH:mm:ss',
                    ampm: false,
                  },
                },
                {
                  type: 'VerticalLayout',
                },
              ],
            },
          },
        },
      ],
    },
    {
      type: 'Category',
      label: 'Documentos da Proposta',
      options: {
        stepHandler: {
          handler: stepHandlerDocumentoProposta,
          url: '/edital',
        },
      },
      elements: [
        {
          type: 'Control',
          scope: '#/properties/documentoProposta',
          label: 'Documentos da Proposta',
          options: {
            detail: {
              type: 'VerticalLayout',
              elements: [
                {
                  type: 'HorizontalLayout',
                  elements: [
                    {
                      type: 'Control',
                      scope: '#/properties/nome',
                      label: 'Nome do Documento da Proposta',
                    },
                    {
                      type: 'Control',
                      scope: '#/properties/descricao',
                      label: 'Descrição do Documento da Proposta',
                    },
                  ],
                },
                {
                  type: 'HorizontalLayout',
                  elements: [
                    {
                      type: 'Select',
                      scope: '#/properties/formatoArquivo',
                      label: 'Formato do Arquivo',
                    },
                    {
                      type: 'Control',
                      scope: '#/properties/tamanhoArquivo',
                      label: 'Tamanho Máximo do Arquivo',
                    },
                  ],
                },
              ],
            },
          },
        },
      ],
    },
    {
      type: 'Category',
      label: 'Bolsas',
      options: {
        stepHandler: {
          handler: stepHandlerDocumentoProposta,
          url: '/edital',
        },
      },
      elements: [
        {
          type: 'Control',
          scope: '#/properties/bolsaEdital',
          label: 'Bolsas',
          options: {
            itemLayout: 'SimpleItemLayout',
            detail: {
              type: 'HorizontalLayout',
              elements: [
                {
                  type: 'Select',
                  scope: '#/properties/modalidadeBolsaId',
                  label: 'Modalidade da Bolsa',
                },
                {
                  type: 'Select',
                  scope: '#/properties/nivelBolsaId',
                  label: 'Nível da Bolsa',
                  options: {
                    filter: {
                      formFields: ['modalidadeBolsaId'],
                      handler: (
                        { bolsaEdital }: any,
                        listData: any[],
                        api: GenericApi,
                        queryFilterValues: any,
                        path: string,
                      ) => {
                        if (!queryFilterValues?.[0].value) return [];
                        const index = Number(path.split('.').at(-2));
                        if (Number.isNaN(index)) return [];
                        return api
                          .getAll([
                            { chave: 'modalidadeBolsaId', valor: queryFilterValues?.[0].value },
                          ])
                          .then((response) =>
                            response.filter((x: any) =>
                              bolsaEdital
                                .filter((_: any, i: number) => i !== index)
                                .every((y: any) => y.nivelBolsaId !== x.id),
                            ),
                          );
                      },
                    },
                    formatOptionLabel: formatLabel,
                    visibleHandler: visibleIfHasFilter,
                  },
                },
              ],
            },
          },
        },
      ],
    },
    {
      type: 'Category',
      label: 'Anexos do Edital',
      options: {
        stepHandler: {
          handler: stepHandlerBack,
          url: '/edital',
        },
      },
      elements: [
        {
          type: 'VerticalLayout',
          elements: [
            {
              type: 'File',
              scope: '#/properties/editalAnexo',
              options: {
                fileConfig: [
                  {
                    name: 'edital',
                  },
                  {
                    name: 'editalAnexo',
                  },
                ],
              },
            },
          ],
        },
      ],
    },
  ],
  options: {
    variant: 'stepper',
    showNavButtons: true,
    icons: iconConfig,
  },
};

export default editalUiSchema;
