import { Tooltip } from '@mui/material';
import { BuildingMultiple } from '@styled-icons/fluentui-system-filled/BuildingMultiple';
import { useNavigate } from 'react-router-dom';
import { Crud } from '../../../components/crud/crud';
import { useCrudContext } from '../../../components/crud/crud-context';
import { Data, headCells } from './convenio.headcell';
import convenioUiSchema from './convenio.uischema';
import { Money } from '@styled-icons/boxicons-regular/Money';

function Convenio() {
  const navigate = useNavigate();
  const { parameterFilters, setParameterFilters } = useCrudContext();
  const defaultForm = { nome: '' };

  const termoAditivoAction = {
    permission: 'ORCAMENTO.CONVENIO.TERMO_ADITIVO_CONVENIO.TERMO_ADITIVO_CONVENIO.EXIBIR_NO_MENU',
    action: (
      <Tooltip title='Termos Aditivos' arrow>
        <Money size='24' color='#009900' />
      </Tooltip>
    ),
    handler: (row: any) => {
      setParameterFilters({
        ...parameterFilters,
        convenio: row,
      });
      navigate(`/convenio/${row?.id}/termo-aditivo-convenio`);
    },
  };

  const instituicaoConvenio = {
    permission: 'ORCAMENTO.CONVENIO.INSTITUICAO_CONVENIO.INSTITUICAO_CONVENIO.EXIBIR_NO_MENU',
    action: (
      <Tooltip title='Instituição do Convênio' arrow>
        <BuildingMultiple size='24' />
      </Tooltip>
    ),
    handler: (row: any) => {
      setParameterFilters({
        ...parameterFilters,
        convenio: row,
      });
      navigate(`/convenio/${row?.id}/instituicao-convenio`);
    },
  };

  return (
    <Crud<Data>
      headCells={headCells}
      titleConfig={{ value: 'Convênio', show: true }}
      apiUrl={'/convenio'}
      uischema={convenioUiSchema}
      defaultForm={defaultForm}
      queryFilters={[
        { chave: 'withDeleted', valor: true },
        { chave: 'orderBy', valor: 'inicioVigencia' },
        { chave: 'orderDirection', valor: 'desc' },
      ]}
      customActions={[() => instituicaoConvenio, () => termoAditivoAction]}
      customSave={{ show: false }}
    ></Crud>
  );
}

export default Convenio;
