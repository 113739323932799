import {
  ArrayLayoutProps,
  RankedTester,
  composePaths,
  computeLabel,
  createDefaultValue,
  isObjectArrayWithNesting,
  rankWith,
} from '@jsonforms/core';
import { withJsonFormsArrayLayoutProps } from '@jsonforms/react';
import AddIcon from '@mui/icons-material/Add';
import { Box, Button, Hidden, Typography } from '@mui/material';
import map from 'lodash/map';
import merge from 'lodash/merge';
import range from 'lodash/range';
import React, { useCallback } from 'react';
import { ArrayToolbar } from '../../components/layout/array-toolbar';
import ExpandableItemLayout from '../../components/layout/expandable-item-layout';
import SimpleItemLayout from '../../components/layout/simple-item-layout';
import './material-array-layout-render.css';

const MaterialArrayLayoutComponent = (props: ArrayLayoutProps) => {
  const [expanded, setExpanded] = React.useState<string | boolean>(false);
  const [disableAdd, setDisableAdd] = React.useState<boolean>(false);
  const {
    enabled,
    data,
    path,
    schema,
    uischema,
    errors,
    addItem,
    renderers,
    cells,
    label,
    required,
    rootSchema,
    config,
    uischemas,
    translations,
    description,
  } = props;

  const itemLayout = (uischema as any)?.options?.itemLayout;

  React.useEffect(() => {
    setDisableAdd(data === 0);
  }, [data]);

  const innerCreateDefaultValue = useCallback(
    () => createDefaultValue(props.schema, props.rootSchema),
    [props.schema],
  );
  const handleChange = React.useCallback(
    (panel: string) => (_event: any, expandedPanel: boolean) => {
      setExpanded(expandedPanel ? panel : false);
    },
    [],
  );
  const isExpanded = (index: number) => expanded === composePaths(props.path, `${index}`);

  const appliedUiSchemaOptions = merge({}, config, props.uischema.options);

  return (
    <Box className='array-layout'>
      <ArrayToolbar
        translations={translations}
        label={computeLabel(label, required!, appliedUiSchemaOptions.hideRequiredAsterisk)}
        description={description!}
        errors={errors}
        path={path}
        enabled={enabled}
        addItem={addItem}
        createDefault={innerCreateDefaultValue}
        disableAdd={disableAdd}
      />
      <Box className='array-layout-container'>
        {data > 0 ? (
          map(range(data), (index) => {
            return itemLayout === 'SimpleItemLayout' ? (
              <SimpleItemLayout
                enabled={enabled}
                index={index}
                schema={schema}
                path={path}
                uischema={uischema}
                renderers={renderers}
                cells={cells}
                key={index}
                rootSchema={rootSchema}
                config={config}
                childLabelProp={appliedUiSchemaOptions.elementLabelProp}
                uischemas={uischemas}
                translations={translations}
              />
            ) : (
              <ExpandableItemLayout
                enabled={enabled}
                index={index}
                expanded={isExpanded(index)}
                schema={schema}
                path={path}
                handleExpansion={handleChange}
                uischema={uischema}
                renderers={renderers}
                cells={cells}
                key={index}
                rootSchema={rootSchema}
                enableMoveUp={false}
                enableMoveDown={false}
                config={config}
                childLabelProp={appliedUiSchemaOptions.elementLabelProp}
                uischemas={uischemas}
                translations={translations}
              />
            );
          })
        ) : (
          <Box className='no-data-container'>
            <Typography className='no-data-label'>Nenhuma informação cadastrada</Typography>
            {enabled && (
              <Button
                onClick={addItem(path, innerCreateDefaultValue())}
                variant='contained'
                startIcon={<AddIcon />}
                className='add-button'
              >
                Adicionar
              </Button>
            )}
          </Box>
        )}
      </Box>
    </Box>
  );
};

const MaterialArrayLayout = React.memo(MaterialArrayLayoutComponent);

const MaterialArrayLayoutRenderer = ({ visible, addItem, ...props }: ArrayLayoutProps) => {
  const addItemCb = useCallback((p: string, value: any) => addItem(p, value), [addItem]);

  return (
    <Hidden xsUp={!visible}>
      <MaterialArrayLayout visible={visible} addItem={addItemCb} {...props} />
    </Hidden>
  );
};

const materialArrayLayoutTester: RankedTester = rankWith(5, isObjectArrayWithNesting);

export const materialArrayLayoutRenderer = {
  tester: materialArrayLayoutTester,
  renderer: withJsonFormsArrayLayoutProps(MaterialArrayLayoutRenderer),
};
