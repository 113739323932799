import client from '../config/axios-config';
import { FormApi, GenericApi } from './generic-api';

export interface INotificacaoCount {
  [key: string]: number;
}

export interface IModuleList {
  modules: string[];
}

export interface INotificacaoApi extends FormApi {
  findAllModules(): Promise<IModuleList>;
  countUnread(): Promise<INotificacaoCount>;
  marcarComoLida(ids: number[]): Promise<void>;
  multipleDelete(ids: number[]): Promise<void>;
}

export class NotificacaoApi extends GenericApi implements INotificacaoApi {
  constructor() {
    super('/notificacao');
  }

  async getAll(
    options: { chave: string; valor: any }[] = [{ chave: 'withDeleted', valor: false }],
  ): Promise<{ data: any; count: number }> {
    try {
      const urlBuild = options.reduce(
        (acc, { chave, valor }, i) => `${acc}${i === 0 ? '?' : '&'}${chave}=${String(valor) ?? ''}`,
        this.url,
      );
      const response = await client.get<any>(urlBuild);
      return response?.data;
    } catch (error: any) {
      if (error?.response?.status === 401) {
        localStorage.removeItem('token');
        window.location.href = '/login';
      }
      throw new Error('GetAll error: ', { cause: error });
    }
  }

  async findAllModules(): Promise<IModuleList> {
    try {
      const response = await client.get<any>(`${this.url}/modules`);
      return response.data;
    } catch (error: any) {
      if (error?.response?.status === 401) {
        localStorage.removeItem('token');
        window.location.href = '/login';
      }
      throw new Error('FindAllModules error: ', { cause: error });
    }
  }

  async countUnread(): Promise<INotificacaoCount> {
    try {
      const response = await client.get<INotificacaoCount>(`${this.url}/countUnread`);
      return response.data;
    } catch (error: any) {
      if (error?.response?.status === 401) {
        localStorage.removeItem('token');
        window.location.href = '/login';
      }
      throw new Error('CountUnread error: ', { cause: error });
    }
  }

  async marcarComoLida(ids: number[]): Promise<void> {
    try {
      await client.put<any>(`${this.url}/lida`, { ids });
    } catch (error: any) {
      if (error?.response?.status === 401) {
        localStorage.removeItem('token');
        window.location.href = '/login';
      }
      throw new Error('MarcarComoLida error: ', { cause: error });
    }
  }

  async multipleDelete(ids: number[]): Promise<void> {
    try {
      await client.delete<any>(`${this.url}/delete`, { data: { ids } });
    } catch (error: any) {
      if (error?.response?.status === 401) {
        localStorage.removeItem('token');
        window.location.href = '/login';
      }
      throw new Error('Delete error: ', { cause: error });
    }
  }
}

export const makeNotificacaoApi = () => {
  return new NotificacaoApi();
};
