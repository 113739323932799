import { HeadCell } from '../../../components/crud/protocols/head-cell';

export interface Data extends Record<string, any> {
  id: number;
  nome: string;
  sigla: string;
  objetivo: string;
  descricao: string;
  dataInicio: Date;
  dataTermino: Date;
}

export const moduleName = 'ORCAMENTO.PROGRAMA.PROGRAMA';

export const moduleLabel = 'Programas';

export const displayProperties = ['nome'];

export const headCells: readonly HeadCell<Data>[] = [
  {
    id: 'nome',
    numeric: false,
    disablePadding: false,
    label: 'Nome',
  },
  {
    id: 'dataInicio',
    numeric: false,
    disablePadding: false,
    label: 'Data de Início',
    dateFormat: 'DD/MM/YYYY',
  },
  {
    id: 'dataTermino',
    numeric: false,
    disablePadding: false,
    label: 'Data de Término',
    dateFormat: 'DD/MM/YYYY',
  },
];
