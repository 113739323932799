import { useParams } from 'react-router-dom';
import { Crud } from '../../../components/crud/crud';
import { Data, headCells } from './termo-aditivo-convenio.headcell';
import termoAditivoConvenioUiSchema from './termo-aditivo-convenio.uischema';

function TermoAditivoConvenio() {
  const { convenioId } = useParams();
  const convenioIdNumber = Number(convenioId);

  return (
    <Crud<Data>
      titleConfig={{ value: 'Termos Aditivos do Convênio', show: true }}
      queryFilters={[{ chave: 'convenioId', valor: convenioId }]}
      defaultForm={{ convenioId: convenioIdNumber }}
      uischema={termoAditivoConvenioUiSchema}
      apiUrl={'/termo-aditivo-convenio'}
      customSave={{ show: false }}
      headCells={headCells}
      hideDelete
      hideUpdate
    ></Crud>
  );
}

export const rootViews = ['convenio'];

export default TermoAditivoConvenio;
