import { Box } from '@mui/material';
import React from 'react';
import { CircleFlag } from 'react-circle-flags';
import { Crud } from '../../components/crud/crud';
import CustomAvatarEditor from '../../components/layout/custom-avatar-editor';
import { makeEditarPerfilApi } from './editar-perfil-api';
import './editar-perfil.css';
import { Data, headCells } from './editar-perfil.headcell';
import editarPerfilUiSchema from './editar-perfil.uischema';
import { useCrudContext } from '../../components/crud/crud-context';
import { cloneDeep } from 'lodash';
import { validateCPF } from '../../utils/cpf-validator';

function EditarPerfil() {
  const apiClient = makeEditarPerfilApi('usuario');

  const { formData, crudStates, showSuccess, showError } = useCrudContext();
  const [userImageData, setUserImageData] = React.useState(formData);
  const [userHeaderData, setUserHeaderData] = React.useState(formData);

  const defaultForm = { ...cloneDeep(formData) };

  const customErrors = [validateCPF('documento')];

  React.useEffect(() => {
    if (Object.keys(formData).length && crudStates.view) {
      setUserImageData(formData.fotoPerfil);
      setUserHeaderData(formData);
    }
  }, [crudStates.view, formData]);

  const saveHandler = async (profileImage: any) => {
    try {
      await apiClient?.atualizarFotoPerfil?.({ fotoPerfil: profileImage }).then(() => {
        showSuccess('Foto atualizada');
        setUserImageData(profileImage);
      });
    } catch (error: any) {
      showError(error?.cause?.response?.data?.message?.[0] || 'Ocorreu um erro.');
    }
  };

  return (
    <>
      <Box className='editar-perfil-header'>
        <CustomAvatarEditor
          username={userHeaderData?.nome}
          image={userImageData}
          saveHandler={saveHandler}
        />
        <Box className='usuario-info'>
          <Box className='usuario-info-nome'>{userHeaderData?.nome}</Box>
          <Box className='usuario-info-pais'>
            <CircleFlag
              countryCode={userHeaderData ? userHeaderData.pais?.sigla?.toLowerCase() : ''}
            />
            {userHeaderData?.pais?.nomePt}
          </Box>
        </Box>
      </Box>

      <Crud<Data>
        headCells={headCells}
        titleConfig={{ value: 'Editar Perfil', show: false }}
        uischema={editarPerfilUiSchema}
        apiClient={apiClient}
        defaultForm={defaultForm}
        customErrors={customErrors}
        customSave={{ show: false }}
        initView
      ></Crud>
    </>
  );
}

export default EditarPerfil;
