import {
  Box,
  Collapse,
  IconButton,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from '@mui/material';
import React from 'react';
import { ArrowIosDownwardOutline, ArrowIosUpwardOutline } from 'styled-icons/evaicons-outline';
import { Data } from './area-de-conhecimento.view';
import { AreaDeConhecimentoActionsCell } from './area-de-conhecimento-actions-cell';
import AreaDeConhecimentoDeleteDialog from './area-de-conhecimento-delete-dialog';

type RecursiveTableProps<T> = {
  data?: Data[];
  expandHandler?: Function;
  editHandler?: Function;
  addHandler?: Function;
  hideActions?: boolean;
  hideDelete?: boolean;
  hideUpdate?: boolean;
  hideCreate?: boolean;
  hideView?: boolean;
  openDeleteHandler?: Function;
  destroyHandler: Function;
  restoreHandler?: Function;
};

export default function RecursiveTable<T extends Record<string, any>>(
  props: RecursiveTableProps<T>,
) {
  const { data, expandHandler, editHandler, addHandler, restoreHandler, destroyHandler } = props;
  const [tableData, setTableData] = React.useState<Data[] | undefined>([]);
  const [openDialog, setOpenDialog] = React.useState(false);
  const [selectedRow, setSelectedRow] = React.useState<Data>();

  React.useEffect(() => {
    setTableData(data);
  }, [data]);

  const restoreAndUpdateHandler = (row: Data) => {
    restoreHandler?.(row, (data: any) => {
      setTableData(data);
    });
  };

  const handlerOpenDelete = (data: Data) => {
    setSelectedRow(data);
    setOpenDialog(true);
  };

  const handlerCloseDelete = () => {
    setOpenDialog(false);
    setTimeout(() => {
      setSelectedRow(undefined);
    }, 100);
  };

  const modifiedDestroy = () => {
    destroyHandler(selectedRow, setTableData);
    handlerCloseDelete();
  };

  const defineHeader = (numeroNivel: number | undefined) => {
    switch (numeroNivel) {
      case 1:
        return 'Grande área';
      case 2:
        return 'Área';
      case 3:
        return 'Sub-área';
      case 4:
        return 'Especialidade';
      default:
        return 'Área de Conhecimento';
    }
  };

  return (
    <>
      <Box sx={{ width: '100%' }}>
        <Paper sx={{ width: '100%', mb: 2 }}>
          <TableContainer>
            <Table>
              <TableHead sx={{ backgroundColor: '#1351b4' }}>
                <TableRow>
                  <TableCell colSpan={6} sx={{ color: '#fff', textAlign: 'center' }}>
                    {tableData && tableData.length > 0 && defineHeader(tableData[0].numeroNivel)}
                  </TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {tableData?.map((row: any) => (
                  <CustomExpandableRow
                    row={row}
                    key={row.id}
                    getChildren={expandHandler}
                    addHandler={addHandler}
                    restoreHandler={restoreHandler}
                    editHandler={editHandler}
                    openDeleteHandler={handlerOpenDelete}
                    restoreAndUpdateHandler={restoreAndUpdateHandler}
                    destroyHandler={destroyHandler}
                  />
                ))}
              </TableBody>
            </Table>
          </TableContainer>
        </Paper>
      </Box>
      <AreaDeConhecimentoDeleteDialog
        openDialog={openDialog}
        body={''}
        handleCloseDelete={handlerCloseDelete}
        destroyHandler={modifiedDestroy}
      />
    </>
  );
}

type CustomExpandableRowProps = {
  row: Data;
  getChildren?: Function;
  addHandler?: Function;
  editHandler?: Function;
  openDeleteHandler?: Function;
  restoreHandler?: Function;
  restoreAndUpdateHandler?: Function;
  destroyHandler: Function;
};

const CustomExpandableRow = (props: CustomExpandableRowProps) => {
  const {
    row,
    getChildren,
    addHandler,
    editHandler,
    openDeleteHandler,
    restoreHandler,
    restoreAndUpdateHandler,
    destroyHandler,
  } = props;
  const [apiData, setApiData] = React.useState<Data[] | undefined>([]);
  const [open, setOpen] = React.useState(false);

  const getName = (row: Data) => {
    switch (row.numeroNivel) {
      case 1:
        return row.grandeAreaNome;
      case 2:
        return row.areaNome;
      case 3:
        return row.subAreaNome;
      case 4:
        return row.especialidadeNome;
    }
  };

  const expandRow = async (row: Data) => {
    if (open) {
      setOpen(!open);
    } else {
      const data = await getChildren?.(row);
      if (data && data?.length > 0) {
        setApiData(data);
        setOpen(!open);
      }
    }
  };

  const sx = {
    cursor: 'pointer',
    backgroundColor: row.deletedAt ? '#dfdfdf' : '#FFF',
  };

  const addSubArea = row?.numeroNivel === 4;

  return (
    <>
      <TableRow sx={sx} key={row?.id}>
        {!addSubArea && (
          <TableCell
            sx={{ padding: 0, width: '1%', height: '48px', paddingLeft: 2, borderBottom: 0 }}
          >
            <IconButton
              size='small'
              onClick={() => {
                expandRow(row);
              }}
            >
              {open ? <ArrowIosUpwardOutline size={24} /> : <ArrowIosDownwardOutline size={24} />}
            </IconButton>
          </TableCell>
        )}
        <TableCell
          component='td'
          align='left'
          sx={{ padding: 0, height: '48px', paddingLeft: 2, width: '100%', borderBottom: 0 }}
        >
          {getName(row)}
        </TableCell>
        <AreaDeConhecimentoActionsCell
          row={row}
          addHandler={addHandler}
          editHandler={editHandler}
          openDeleteHandler={openDeleteHandler}
          restoreHandler={restoreHandler}
          restoreAndUpdateHandler={restoreAndUpdateHandler}
          sx={{ paddingRight: 2, borderBottom: 0 }}
        />
      </TableRow>
      {apiData && (
        <TableRow key={`tableRow-${row?.id}`}>
          <TableCell style={{ paddingBottom: 0, paddingTop: 0 }} colSpan={6}>
            <Collapse in={open} timeout='auto' unmountOnExit>
              <Box sx={{ margin: 1 }}>
                <RecursiveTable
                  data={apiData}
                  expandHandler={getChildren}
                  addHandler={addHandler}
                  editHandler={editHandler}
                  restoreHandler={restoreHandler}
                  destroyHandler={destroyHandler}
                  hideView
                />
              </Box>
            </Collapse>
          </TableCell>
        </TableRow>
      )}
    </>
  );
};
