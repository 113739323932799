import { GenericApi } from '../../../api/generic-api';
import client from '../../../config/axios-config';

export class TermoPoliticaPrivacidadeApi extends GenericApi {
  constructor(url: string) {
    super(url);
  }

  async get(): Promise<any> {
    try {
      const response = await client.get<any>(`${this.url}`);
      return response.data;
    } catch (error: any) {
      if (error?.response?.status === 401) {
        localStorage.removeItem('token');
        window.location.href = '/login';
      }
      throw new Error('Get error: ', { cause: error });
    }
  }

  async put(_: number, data: any): Promise<any> {
    try {
      const response = await client.put<any>(this.url, data);
      return response;
    } catch (error: any) {
      if (error?.response?.status === 401) {
        localStorage.removeItem('token');
        window.location.href = '/login';
      }
      throw new Error('Put error: ', { cause: error });
    }
  }
}

export const makeTermoPoliticaPrivacidadeApi = () => {
  return new TermoPoliticaPrivacidadeApi('termo-politica-privacidade');
};
