import perfilUiSchema from './perfil.uischema';
import { Data, headCells } from './perfil.headcell';
import { Crud } from '../../../components/crud/crud';
import { whiteSpaces } from '../../../helpers/custom-errors';
import { useNavigate } from 'react-router-dom';
import { useCrudContext } from '../../../components/crud/crud-context';
import { Tooltip } from '@mui/material';
import { LockClosed } from 'styled-icons/zondicons';

function Perfil() {
  const navigate = useNavigate();
  const customErrors = [whiteSpaces('nome'), whiteSpaces('descricao')];
  const { parameterFilters, setParameterFilters } = useCrudContext();

  const perfilActions = {
    permission: 'PERMISSIONAMENTO.PERFIL.PERFIL_PERMISSIONAMENTO.EXIBIR_NO_MENU',
    action: (
      <Tooltip title='Permissões do Perfil' arrow>
        <LockClosed size='24' color='#545454' />
      </Tooltip>
    ),
    handler: (row: any) => {
      setParameterFilters({
        ...parameterFilters,
        perfil: row,
      });
      navigate(`/perfil-permissionamento`);
    },
  };

  return (
    <Crud<Data>
      headCells={headCells}
      titleConfig={{ value: 'Perfil', show: true }}
      customErrors={customErrors}
      uischema={perfilUiSchema}
      apiUrl={'/perfil'}
      queryFilters={[{ chave: 'withDeleted', valor: true }]}
      customActions={[() => perfilActions]}
    ></Crud>
  );
}

export default Perfil;
