import { sentenceCase } from 'change-case';

export const translateErrors = (error: any) => {
  let errorMessage;
  switch (error?.keyword) {
    case 'maxLength':
      errorMessage = `Máximo de ${error?.schema} caracteres`;
      break;
    case 'minLength':
      errorMessage = `Mínimo de ${error?.schema} caracteres`;
      break;
    case 'positiveNumber':
      errorMessage = `O campo deve ser um número positivo`;
      break;
    case 'type':
      errorMessage = `O valor precisa ser ${translateType(error?.params?.type)}`;
      break;
    default:
      errorMessage = errorsTranslate?.[error?.keyword];
  }
  return errorMessage;
};

const translateType = (type?: string) => {
  switch (type) {
    case 'string':
      return 'texto';
    case 'number':
      return 'número';
    case 'boolean':
      return 'booleano';
    case 'array':
      return 'lista';
    case 'object':
      return 'objeto';
    case 'integer':
      return 'inteiro';
    default:
      return type;
  }
};

export const errorsTranslate: any = {
  required: 'Campo obrigatório',
  format: 'Formato inválido',
  maxLength: 'Máximo de X caracteres',
};

export const translateAction = (action: string) => {
  return actionsTranslate[action] ?? sentenceCase(action.replace('_', ' ').toLowerCase());
};

const actionsTranslate: any = {
  // Actions
  BUSCAR_TODOS: 'Buscar todos',
  BUSCAR_POR_ID: 'Buscar por id',
  ESTRUTURA_DO_DOCUMENTO: 'Estrutura do documento',
  BUSCAR_CONFIGURACOES_DA_FUNDACAO: 'Buscar configurações da fundação',
  BUSCAR_EXCLUIDOS: 'Buscar excluídos',
  BAIXAR_ARQUIVO: 'Baixar arquivo',
  BUSCAR_ANEXOS: 'Buscar anexos',
  ENVIAR_ARQUIVO: 'Enviar arquivo',
  BUSCAR_TODAS_AS_PERMISSOES: 'Buscar todas as permissões',
  BUSCAR_PERMISSOES_POR_ID_DO_PERFIL: 'Buscar permissões por id do perfil',

  // Modules
  CARGO_PERMISSAO: 'Cargo permissão',
  PERMISSAO: 'Permissão',
  USUARIO: 'Usuário',
  CONFIGURACOES_FUNDACAO: 'Configurações fundação',
  CONFIGURACOES_INICIAIS: 'Configurações iniciais',
  AREA_DE_CONHECIMENTO: 'Área de conhecimento',
  DEPARTAMENTO_FUNDACAO: 'Departamento fundação',
  DEPARTAMENTO_USUARIO: 'Departamento usuário',
  DOCUMENTO_PROPOSTA: 'Documento da proposta',
  ENDERECO: 'Endereço',
  NIVEL_ACADEMICO: 'Nível acadêmico',
  PAIS: 'País',
  RACA_COR: 'Raça/Cor',
  VALOR_DIARIA: 'Valor diária',
  INDICADOR_PRODUCAO: 'Indicador de Produção',
  INSTITUICAO: 'Instituição',
  INSTITUICAO_SUGERIDA: 'Instituição sugerida',
  CONVENIO: 'Convênio',
  LINHA_DE_ACAO: 'Linha de ação',
  EDICAO_LINHA_DE_ACAO: 'Edição Linha de ação',
  EDICAO_RECURSO_LINHA_DE_ACAO: 'Recursos da Linha de ação',
  PROGRAMA: 'Programa',
  PRODUCAO: 'Produção',
  EDICAO_PROGRAMA: 'Edição Programa',
  EDICAO_RECURSO_PROGRAMA: 'Recursos do Programa',
  CONVENIO_ANEXO: 'Convênio anexo',
  INSTITUICAO_CONVENIO: 'Instituição convênio',
  STATUS_CONVENIO: 'Status convênio',
  TIPO_CONVENIO: 'Tipo convênio',
  HISTORICO_AJUSTE_INSTITUICAO_CONVENIO: 'Histórico ajuste instituição convênio',
  TIPO_HISTORICO_AJUSTE_INSTITUICAO_CONVENIO: 'Tipo histórico ajuste instituição convênio',
  TERMO_ADITIVO_CONVENIO: 'Termo aditivo convênio',
  FONTE_DE_RECURSO: 'Fonte de recurso',
  HISTORICO_AJUSTE_TESOURO: 'Histórico ajuste tesouro',
  TESOURO_HISTORICO: 'Tesouro histórico',
  PESSOA_JURIDICA: 'Pessoa jurídica',
  ALTERAR_SENHA: 'Alterar senha',
  CONFIRMAR_EMAIL: 'Confirmar e-mail',
  RECUPERAR_SENHA: 'Recuperar senha',
  ORCAMENTO: 'Orçamento',
  RECURSO_LINHA_DE_ACAO: 'Recurso linha de ação',
  USUARIO_ANEXO: 'Usuário anexo',
  NIVEL_BOLSA: 'Nível de bolsa',
  HISTORICO_AJUSTE_VALOR_BOLSA: 'Ajuste do Valor',
};
