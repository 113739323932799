import { Box, Divider, Drawer, List, Toolbar, Tooltip } from '@mui/material';
import React, { ReactNode } from 'react';
import { ChevronLeftCircle, ChevronRightCircle } from 'styled-icons/boxicons-regular';
import { CrudStatesOptions } from '../../../crud/crud-context';
import LogoFundacao from '../../logo-fundacao';
import { NavCategory } from '../nav-category';
import { navItems } from './items';

export type Item = {
  route: string;
  icon: ReactNode;
  title: string;
  state?: CrudStatesOptions;
};

export interface NavDrawerProps {
  handleMainContent: (open: boolean) => void;
}

export function NavDrawer(props: NavDrawerProps) {
  const { handleMainContent } = props;
  const [open, setOpen] = React.useState(true);
  const [drawerWidth, setDrawerWidth] = React.useState(240);

  const toggleDrawer = () => {
    setDrawerWidth(!open ? 240 : 60);
    setOpen(!open);
    handleMainContent(!open);
  };

  return (
    <Drawer
      variant='permanent'
      open={open}
      className='sidebar'
      sx={{
        zIndex: 10,
        fontFamily: 'Raleway',
        fontSize: '14px',
        fontWeight: 'medium',
        display: { xs: 'none', sm: 'block' },
        '& .MuiDrawer-paper': {
          boxSizing: 'border-box',
          width: drawerWidth,
          transition: 'width 0.5s',
        },
      }}
    >
      <Box>
        <Toolbar
          sx={{
            width: '100%',
            display: 'flex',
            justifyContent: 'center',
            padding: '0px',
          }}
        >
          <div
            style={{
              height: '70px',
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
            }}
          >
            {open ? <LogoFundacao width={74.07} /> : <LogoFundacao width={50} />}
          </div>
        </Toolbar>
        <Box onClick={toggleDrawer} sx={{ cursor: 'pointer', padding: '5px', height: '50px' }}>
          {open ? (
            <div
              style={{
                display: 'flex',
                justifyContent: 'start',
                alignItems: 'center',
                paddingLeft: '15px',
              }}
            >
              <Box className='nav-icon'>
                <ChevronLeftCircle size={20} />
              </Box>
              <p>Recolher</p>
            </div>
          ) : (
            <Tooltip arrow title='Expandir Menu' placement='right'>
              <Box
                className='nav-icon'
                sx={{
                  display: 'flex',
                  justifyContent: 'start',
                  alignItems: 'center',
                  borderRadius: '5px',
                  padding: '15px 0px',
                  paddingLeft: '15px',
                  ':hover': { backgroundColor: '#d4e0e4' },
                }}
              >
                <ChevronRightCircle size={20} />
              </Box>
            </Tooltip>
          )}
        </Box>
      </Box>

      {/* {open && (
        <Box paddingRight={1} paddingLeft={1}>
          <Divider />
        </Box>
      )} */}
      <Box paddingRight={1} paddingLeft={1}>
        <Divider />
      </Box>

      <List component='nav' className='nav-container'>
        {Object.values(navItems).map((category, index) => (
          <Tooltip
            arrow
            key={index}
            title={category.title}
            placement='right'
            disableHoverListener={open}
          >
            <Box
              sx={{
                padding: '0px 5px',
                width: drawerWidth,
              }}
            >
              <NavCategory
                key={index}
                title={category.title}
                icon={category.icon}
                items={category.items}
                open={open}
                setOpen={setOpen}
                toggleDrawer={toggleDrawer}
              />
            </Box>
          </Tooltip>
        ))}
      </List>
      <Box sx={{ pb: '28px' }} />
    </Drawer>
  );
}
