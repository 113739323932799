import { Crud } from '../../../components/crud/crud';
import nivelAcademicoUiSchema from './nivel-academico.uischema';
import { Data, headCells } from './nivel-academico.headcell';

function NivelAcademico() {
  return (
    <Crud<Data>
      headCells={headCells}
      titleConfig={{ value: 'Nível acadêmico', show: true }}
      uischema={nivelAcademicoUiSchema}
      apiUrl={'/nivel-academico'}
      hideActions
      rowsLength={12}
    ></Crud>
  );
}

export default NivelAcademico;
