import { HeadCell } from '../../../components/crud/protocols/head-cell';

export interface Data extends Record<string, any> {
  nome: string;
  sigla: string;
  instituicaoId: string;
}

export const moduleName = 'INSTITUICAO.UNIDADE.UNIDADE';

export const displayProperties = ['nome'];

export const moduleLabel = 'Unidade';

export const headCells: readonly HeadCell<Data>[] = [
  {
    id: 'nome',
    numeric: false,
    disablePadding: false,
    label: 'Unidade',
  },
  {
    id: 'instituicaoId',
    numeric: false,
    disablePadding: false,
    label: 'Instituição',
    resource: {
      name: 'instituicao',
      target: 'nome',
    },
  },
  {
    id: 'sigla',
    numeric: false,
    disablePadding: false,
    label: 'Sigla',
  },
];
