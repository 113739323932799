const racaCorUiSchema = {
  type: 'VerticalLayout',
  elements: [
    {
      type: 'Control',
      scope: '#/properties/nome',
    },
  ],
};

export default racaCorUiSchema;
