import {
  School as AcademicLevelIcon,
  Timeline as ActionLineIcon,
  Handshake as AgreementIcon,
  FileCopy as AgreementTypesIcon,
  AttachFile as AttachmentTypesIcon,
  AccountBalance as BanksIcon,
  CurrencyExchange as CoinsIcon,
  Today as DailiesIcon,
  OtherHouses as FoundationDepartmentsIcon,
  Badge as FoundationProfileIcon,
  Business as InstitutionIcon,
  Class as KnowledgeAreaIcon,
  Checklist as MultipleChoiceIcon,
  NoteAlt,
  Notifications,
  Article as PrivacyPolicyIcon,
  Person as ProfilesIcon,
  Description as ProgramIcon,
  Diversity3 as RacesIcon,
  Backpack as ScholarshipType,
  Draw as SignaturesIcon,
  Assignment as TermAccepted,
  Paid as TreasuryIcon,
  Apartment as UnityIcon,
  People as UsersIcon,
} from '@mui/icons-material';
import AccountBalanceIcon from '@mui/icons-material/AccountBalance';
import DashboardIcon from '@mui/icons-material/Dashboard';
import { Production } from '@styled-icons/fluentui-system-regular';
import { Item } from '.';
import { CrudStatesOptions } from '../../../crud/crud-context';

import { Backpack, HistoryEdu, ManageAccounts, Settings, Wallet } from '@mui/icons-material';

export const institutionItems: Item[] = [
  {
    route: '/instituicao',
    icon: <InstitutionIcon />,
    title: 'Instituições',
  },
  {
    route: '/unidade',
    icon: <UnityIcon />,
    title: 'Unidades',
  },
];

export const bagItems: Item[] = [
  {
    route: '/modalidade-bolsa',
    icon: <ScholarshipType />,
    title: 'Modalidade de Bolsa',
  },
];

export const budgetItems: Item[] = [
  {
    route: '/convenio',
    icon: <AgreementIcon />,
    title: 'Convênios',
  },
  {
    route: '/linha-de-acao',
    icon: <ActionLineIcon />,
    title: 'Linhas de Ações',
  },
  {
    route: '/tesouro',
    icon: <TreasuryIcon />,
    title: 'Tesouro',
  },
  {
    route: '/programa',
    icon: <ProgramIcon />,
    title: 'Programas',
  },
];

export const noticesItems: Item[] = [
  {
    route: '/plano-apresentacao',
    icon: <NoteAlt />,
    title: 'Plano de Apresentação',
  },
  {
    route: '/termo-aceite',
    icon: <TermAccepted />,
    title: 'Termo de Aceite',
  },
  {
    route: '/indicador-producao',
    icon: <Production width={25} height={25} />,
    title: 'Indicadores de Produção',
  },
  {
    route: '/edital',
    icon: <NoteAlt />,
    title: 'Publicar Edital',
  },
  {
    route: '/multipla-escolha',
    icon: <MultipleChoiceIcon width={25} height={25} />,
    title: 'Múltipla Escolha',
  },
];

export const peopleItems: Item[] = [
  {
    route: '/usuario',
    icon: <UsersIcon />,
    title: 'Usuários',
  },
  {
    route: '/perfil',
    icon: <ProfilesIcon />,
    title: 'Perfis',
  },
];

export const initialSettingsItems: Item[] = [
  {
    route: '/notificacao',
    icon: <Notifications />,
    title: 'Notificações',
    state: CrudStatesOptions.VIEW,
  },
  {
    route: '/departamento-fundacao',
    icon: <FoundationDepartmentsIcon />,
    title: 'Departamentos',
  },
  {
    route: '/dados-fundacao',
    icon: <FoundationProfileIcon />,
    title: 'Perfil Fundação',
    state: CrudStatesOptions.VIEW,
  },
  {
    route: '/valor-diaria',
    icon: <DailiesIcon />,
    title: 'Diárias',
  },
  {
    route: '/banco',
    icon: <BanksIcon />,
    title: 'Bancos',
  },
  {
    route: '/rubrica',
    icon: <SignaturesIcon />,
    title: 'Rubricas',
  },
  {
    route: '/tipo-convenio',
    icon: <AgreementTypesIcon />,
    title: 'Tipos de Convênio',
  },
  {
    route: '/moeda',
    icon: <CoinsIcon />,
    title: 'Moeda',
  },
  {
    route: '/raca-cor',
    icon: <RacesIcon />,
    title: 'Raça / Cor',
  },
  {
    route: '/nivel-academico',
    icon: <AcademicLevelIcon />,
    title: 'Nível Acadêmico',
  },
  {
    route: '/area-de-conhecimento',
    icon: <KnowledgeAreaIcon />,
    title: 'Área de Conhecimento',
  },
  {
    route: '/termo-politica-privacidade',
    icon: <PrivacyPolicyIcon />,
    title: 'Termo de Política e Privacidade',
    state: CrudStatesOptions.VIEW,
  },
  {
    route: '/tipo-anexo',
    icon: <AttachmentTypesIcon />,
    title: 'Tipos de Anexos',
  },
];

export const noticesSubItems: Item[] = [
  {
    route: '/tipo-anexo',
    icon: <AttachmentTypesIcon />,
    title: 'Tipos de Anexos',
  },
];

export const navItems = {
  dashboard: {
    title: 'Painel',
    icon: <DashboardIcon />,
    items: [],
  },
  institution: {
    title: 'Instituição',
    icon: <AccountBalanceIcon />,
    items: institutionItems,
  },
  budget: {
    title: 'Orçamento',
    icon: <Wallet />,
    items: budgetItems,
  },
  bag: {
    title: 'Bolsas',
    icon: <Backpack />,
    items: bagItems,
  },
  notices: {
    title: 'Edital',
    icon: <HistoryEdu />,
    items: noticesItems,
  },
  people: {
    title: 'Pessoas',
    icon: <ManageAccounts />,
    items: peopleItems,
  },
  initialSettings: {
    title: 'Configurações Iniciais',
    icon: <Settings />,
    items: initialSettingsItems,
  },
};
