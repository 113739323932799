import { rankWith, uiTypeIs } from '@jsonforms/core';
import { withJsonFormsControlProps } from '@jsonforms/react';
import { Hidden, OutlinedInputProps, TextField } from '@mui/material';
import React, { FocusEvent } from 'react';
import { withMask } from 'use-mask-input';
import { useCrudContext } from '../../components/crud/crud-context';
import { translateErrors } from '../../components/crud/i18n';
import client from '../../config/axios-config';
import './renderer.css';

const cepRenderTester = rankWith(5, uiTypeIs('Cep'));

export const cepRender = {
  tester: cepRenderTester,
  renderer: withJsonFormsControlProps((props) => {
    const fieldRef = React.useRef<HTMLDivElement>(null);

    React.useEffect(() => {
      fieldRef?.current?.childNodes?.forEach?.((childNode) => {
        if (childNode.nodeType === 1 && (childNode as HTMLElement).tagName === 'INPUT') {
          const inputElement = childNode as HTMLInputElement;
          if (inputElement && props.data !== undefined) {
            inputElement.value = props.data;
          } else if (inputElement) {
            inputElement.value = '';
          }
        }
      });
    }, [props.data]);

    const { errorsJsonForms, additionalErrors, validationMode, crudStates, disabledFields } =
      useCrudContext();

    const isDisabled: boolean =
      crudStates.view ||
      disabledFields.includes(props.path) ||
      (crudStates.edit && props.uischema?.options?.onlyCreate) ||
      disabledFields.includes(props.path);

    const hasError = () => {
      return ((props.required || props.uischema.options?.required) && !props.data) || !!getError();
    };

    const getError = () => {
      const instancePath = `/${props?.path?.replace?.('.', '/')}`;
      const pathSplitted = props?.path.split('.');
      const lastPath = pathSplitted[pathSplitted.length - 1];
      const errorJsonForms = errorsJsonForms?.find?.(
        (error) =>
          [lastPath, props?.path].includes(error?.params?.missingProperty) ||
          error?.instancePath === instancePath,
      );
      const additionalError = additionalErrors?.find?.(
        (error) =>
          [lastPath, props?.path].includes(error?.params?.missingProperty) ||
          error?.instancePath === instancePath,
      );
      return errorJsonForms ?? additionalError;
    };

    const getErrorMessage = () => {
      const error = getError();
      return translateErrors?.(error) ?? error?.message ?? '';
    };

    const handleChange = async (event: FocusEvent<HTMLInputElement>) => {
      const cep = event?.target?.value;
      props.handleChange(props?.path, cep);
      if (cep?.replace?.(/\D/g, '')?.length === 8) {
        const response = await client.get(`https://viacep.com.br/ws/${cep}/json/`);
        const { logradouro, bairro, localidade, uf } = response.data;
        props.handleChange('endereco.logradouro', logradouro || '');
        props.handleChange('endereco.bairro', bairro || '');
        props.handleChange('endereco.municipio', localidade || '');
        props.handleChange('endereco.estado', uf || '');
      }
    };

    return (
      <Hidden xsUp={!props.visible}>
        <div className='custom-input-container' ref={fieldRef}>
          <TextField
            variant='filled'
            className={`input
              ${isDisabled ? 'disabled-field' : ''}
              ${validationMode === 'ValidateAndShow' && hasError() ? 'has-error' : ''}
            `}
            label={props.label}
            required={props.required}
            disabled={isDisabled}
            defaultValue={props.data ?? ''}
            inputRef={
              props?.uischema?.options?.mask ? withMask(props.uischema.options.mask) : undefined
            }
            onBlur={handleChange}
            InputProps={{ disableUnderline: true } as Partial<OutlinedInputProps>}
          />
          {validationMode === 'ValidateAndShow' && hasError() && (
            <span className='error-message'>{getErrorMessage()}</span>
          )}
        </div>
      </Hidden>
    );
  }),
};
