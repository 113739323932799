import { makeCustomRequiredIfBrasil, makePositiveNumberIfBrasil } from '../ instituicao-errors';
import { Crud } from '../../../components/crud/crud';
import { Data, headCells } from './unidade.headcell';
import unidadeUiSchema from './unidade.uischema';

function Unidade() {
  const defaultForm = { endereco: {} };
  const customErrors = [
    makeCustomRequiredIfBrasil('cnpj'),
    makeCustomRequiredIfBrasil('endereco.bairro'),
    makeCustomRequiredIfBrasil('endereco.numero'),
    makePositiveNumberIfBrasil('endereco.numero'),
  ];

  return (
    <Crud<Data>
      titleConfig={{ value: 'Unidade', show: true }}
      apiUrl={'/unidade'}
      headCells={headCells}
      defaultForm={defaultForm}
      uischema={unidadeUiSchema}
      customErrors={customErrors}
      queryFilters={[
        { chave: 'withDeleted', valor: true },
        { chave: 'orderDirection', valor: 'asc' },
      ]}
    ></Crud>
  );
}

export default Unidade;
