import Box from '@mui/material/Box';
import Checkbox from '@mui/material/Checkbox';
import Paper from '@mui/material/Paper';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TablePagination from '@mui/material/TablePagination';
import TableRow from '@mui/material/TableRow';
import first from 'lodash/first';
import * as React from 'react';
import { getNestedValues } from '../../../helpers/get-nested-values';
import { useAuthContext } from '../../../views/login/auth/auth-context';
import { HeadCell } from '../protocols/head-cell';
import '../style/data-table.css';
import { ActionsTableCell } from './actions-table-cell';
import { EnhancedTableHead } from './table-head';
import { EnhancedTableToolbar } from './table-toolbar';
import dayjs from '../../dayjs/sig-dayjs';

type OrderDirection = 'asc' | 'desc';

export type Order<T> = {
  direction: OrderDirection;
  attribute: keyof T;
};

type DataTableProps<T> = {
  rows: T[];
  headCells: readonly HeadCell<T>[];
  checkboxes?: boolean;
  title?: string;
  viewHandler?: Function;
  editHandler?: Function;
  addHandler?: Function;
  deleteHandler?: Function;
  hideActions?: boolean;
  hideDelete?: boolean;
  hideUpdate?: boolean;
  hideCreate?: boolean;
  hideView?: boolean;
  onItemSelect?: Function;
  customActions?: ((
    row: any,
  ) => { action: JSX.Element; handler: (row: any) => Promise<void> | void } | undefined)[];
  restoreHandler?: Function;
  rowsLength?: number;
  customTableHeader?: React.ReactNode;
  customTableFooter?: React.ReactNode;
  order?: Order<T>;
  moduleName?: string;
};

export default function DataTable<T extends Record<string, any>>(props: DataTableProps<T>) {
  const {
    rows,
    headCells,
    checkboxes,
    title,
    viewHandler,
    editHandler,
    addHandler,
    deleteHandler,
    hideActions,
    hideDelete,
    hideUpdate,
    hideCreate,
    hideView,
    onItemSelect,
    customActions,
    restoreHandler,
    rowsLength,
    customTableHeader,
    customTableFooter,
    order,
    moduleName,
  } = props;
  const [currOrder, setCurrOrder] = React.useState<Order<T>>(
    order ?? { attribute: 'id', direction: 'asc' },
  );
  const [selected, setSelected] = React.useState<readonly string[]>([]);
  const [page, setPage] = React.useState(0);
  const { configuracoesFundacao } = useAuthContext();
  const configuracaoPaginacao =
    configuracoesFundacao?.paginacao
      ?.split(',')
      .map((x: any) => parseInt(x))
      .filter((x: any) => !isNaN(x)) ?? [];
  const [rowsPerPage, setRowsPerPage] = React.useState<number>(first(configuracaoPaginacao) ?? 15);
  const [rowsPerPageOptions, setRowsPerPageOptions] = React.useState(
    configuracaoPaginacao?.length ? configuracaoPaginacao : [15, 30, 50],
  );

  const handleRequestSort = (event: React.MouseEvent<unknown>, property: keyof T) => {
    const isAsc = currOrder.attribute === property && currOrder.direction === 'asc';
    setCurrOrder({
      attribute: property,
      direction: isAsc ? 'desc' : 'asc',
    });
  };

  const handleSelectAllClick = (event: React.ChangeEvent<HTMLInputElement>) => {
    if (event.target.checked) {
      const newSelected = rows?.map((n: any) => n.name);
      setSelected(newSelected);
      return;
    }
    setSelected([]);
  };

  const handleClick = (
    event: React.MouseEvent<unknown>,
    name: string,
    id: number,
    row: any,
    checkboxes?: boolean,
    onItemSelect?: Function,
  ) => {
    if (checkboxes) {
      const selectedIndex = selected?.indexOf?.(name);
      let newSelected: readonly string[] = [];

      if (selectedIndex === -1) {
        newSelected = newSelected?.concat?.(selected, name);
      } else if (selectedIndex === 0) {
        newSelected = newSelected?.concat?.(selected?.slice?.(1));
      } else if (selectedIndex === selected.length - 1) {
        newSelected = newSelected?.concat?.(selected?.slice?.(0, -1));
      } else if (selectedIndex > 0) {
        newSelected = newSelected?.concat?.(
          selected?.slice?.(0, selectedIndex),
          selected?.slice?.(selectedIndex + 1),
        );
      }

      setSelected(newSelected);
    }
    if (onItemSelect) {
      onItemSelect(row);
    }
  };

  const handleChangePage = (event: unknown, newPage: number) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event: React.ChangeEvent<HTMLInputElement>) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const isSelected = (name: string) => selected.indexOf(name) !== -1;

  // Avoid a layout jump when reaching the last page with empty rows.
  const emptyRows = page > 0 ? Math.max(0, (1 + page) * rowsPerPage - rows.length) : 0;

  const getResourceValue = (row: any, cell: any) => {
    const resource = cell?.resource;
    let value = '';
    if (typeof resource?.target === 'function') {
      value = resource?.target?.(row);
    } else {
      value = row?.[resource?.name]?.[resource?.target];
    }
    if (resource?.name === 'pais') {
      const flag = row[resource?.name]?.sigla;
      if (flag) {
        return (
          <Box sx={{ display: 'flex', alignItems: 'center', gap: 1 }}>
            <img
              src={`https://hatscripts.github.io/circle-flags/flags/${flag.toLowerCase()}.svg`}
              width='25'
            />
            {value}
          </Box>
        );
      }
    }

    return value || '-';
  };

  const getCellContentValue = (row: any, cell: any) => {
    if (cell.resource) return getResourceValue(row, cell);
    if (cell.computedRowLabel) return cell.computedRowLabel(row);

    let crrValue = getNestedValues(row, cell.id);

    if (cell.dateFormat && crrValue) {
      crrValue = dayjs(crrValue, 'YYYY-MM-DD', 'UTC').format(cell.dateFormat);
    }

    if (cell.mask) {
      if (cell.mask === 'money') {
        crrValue = parseInt(crrValue) / 100;
        if (Number.isNaN(crrValue)) crrValue = 0;
        crrValue =
          'R$ ' +
          crrValue.toLocaleString('pt-br', {
            minimumFractionDigits: 2,
            maximumFractionDigits: 2,
          });
      }
    }

    return crrValue || '-';
  };

  return (
    <Box className='data-table'>
      <Paper className='paper'>
        <EnhancedTableToolbar
          className='toolbar'
          numSelected={selected.length}
          title={title}
          moduleName={moduleName}
          addHandler={addHandler}
          customTableHeader={customTableHeader}
          hideCreate={hideCreate || hideActions}
        />
        <TableContainer className='container'>
          <Table className='table' aria-labelledby='tableTitle' size='medium'>
            <EnhancedTableHead
              numSelected={selected.length}
              headCells={headCells}
              order={currOrder}
              onSelectAllClick={handleSelectAllClick}
              onRequestSort={handleRequestSort}
              rowCount={rows?.length}
              checkboxes={checkboxes}
              hideActions={hideActions}
            />
            <TableBody className='body'>
              {rows?.map((row: any, index: number) => {
                const isItemSelected = isSelected(String(row?.name));
                const labelId = `enhanced-table-checkbox-${index}`;

                return (
                  <TableRow
                    className={`table-row ${row.deletedAt ? 'table-row-deleted' : ''}`}
                    hover
                    onClick={(event) =>
                      handleClick(
                        event,
                        String(row?.name),
                        Number(row?.id),
                        row,
                        checkboxes,
                        onItemSelect,
                      )
                    }
                    role='checkbox'
                    aria-checked={isItemSelected}
                    tabIndex={-1}
                    key={`${row.name}-${row.id}`}
                    selected={isItemSelected}
                  >
                    {checkboxes && (
                      <TableCell padding='checkbox'>
                        <Checkbox
                          color='primary'
                          checked={isItemSelected}
                          inputProps={{
                            'aria-labelledby': labelId,
                          }}
                        />
                      </TableCell>
                    )}

                    {headCells?.map((cell: any, index) => {
                      return (
                        <TableCell
                          className='cell'
                          key={String(cell.id) + row.id}
                          component='th'
                          id={String(row.id)}
                          scope='row'
                          align='center'
                        >
                          {getCellContentValue(row, cell)}
                        </TableCell>
                      );
                    })}

                    {!hideActions && (
                      <ActionsTableCell
                        row={row}
                        hideView={hideView}
                        hideUpdate={hideUpdate}
                        hideDelete={hideDelete}
                        restoreHandler={restoreHandler}
                        viewHandler={viewHandler}
                        editHandler={editHandler}
                        deleteHandler={deleteHandler}
                        customActions={customActions}
                        moduleName={moduleName}
                      />
                    )}
                  </TableRow>
                );
              })}
              {emptyRows > 0 && (
                <TableRow
                  style={{
                    height: 53 * emptyRows,
                  }}
                >
                  <TableCell colSpan={6} />
                </TableRow>
              )}
            </TableBody>
          </Table>
        </TableContainer>
        <TablePagination
          className='pagination'
          rowsPerPageOptions={rowsPerPageOptions}
          component='div'
          count={rows?.length}
          rowsPerPage={rowsPerPage}
          page={page}
          labelRowsPerPage='Linhas por página'
          labelDisplayedRows={(pagInfo) => `${pagInfo.from}-${pagInfo.to} de ${pagInfo.count}`}
          onPageChange={handleChangePage}
          onRowsPerPageChange={handleChangeRowsPerPage}
        />
        {customTableFooter && (
          <EnhancedTableToolbar
            hideCreate={true}
            numSelected={selected.length}
            customTableFooter={customTableFooter}
          />
        )}
      </Paper>
    </Box>
  );
}

function descendingComparator<T>(a: T, b: T, orderBy?: keyof T) {
  const compareField = orderBy || 'id';
  const firstElement = a as any;
  const secondElement = b as any;
  if (secondElement[compareField] < firstElement[compareField]) {
    return -1;
  }
  if (secondElement[compareField] > firstElement[compareField]) {
    return 1;
  }
  return 0;
}

function getComparator<Key extends keyof any>(
  order: OrderDirection,
  orderBy?: Key,
): (a: { [key in Key]: number | string }, b: { [key in Key]: number | string }) => number {
  return order === 'desc'
    ? (a, b) => descendingComparator(a, b, orderBy)
    : (a, b) => -descendingComparator(a, b, orderBy);
}
