import LogoutIcon from '@mui/icons-material/Logout';
import NotificationsIcon from '@mui/icons-material/Notifications';
import PersonIcon from '@mui/icons-material/Person';
import PersonOutlineIcon from '@mui/icons-material/PersonOutline';
import { Badge, Button, Divider, ListItemIcon, ListItemText, Menu, MenuItem } from '@mui/material';
import MuiAppBar, { AppBarProps as MuiAppBarProps } from '@mui/material/AppBar';
import Box from '@mui/material/Box';
import CssBaseline from '@mui/material/CssBaseline';
import Toolbar from '@mui/material/Toolbar';
import { ThemeProvider, createTheme, styled } from '@mui/material/styles';
import { cloneDeep } from 'lodash';
import * as React from 'react';
import { Link, useLocation } from 'react-router-dom';
import { useNotificacaoContext } from '../../views/fundacao/notificacao/notificacao-context';
import { useAuthContext } from '../../views/login/auth/auth-context';
import BreadcrumbsCustom from '../breadcrumb/breadcrumb';
import { BreadcrumbsContext } from '../breadcrumb/breadcrumb-context';
import { useCrudContext } from '../crud/crud-context';
import { NavDrawer } from './components/nav-drawer';
import { DarkModeToggle } from './dark-mode-toggle/dark-mode-toggle';
import { useThemeContext } from './dark-mode-toggle/theme-context';
import Footer from './footer';
import LogoFundacao from './logo-fundacao';
import './style/layout.css';

const drawerWidth: number = 240;

interface AppBarProps extends MuiAppBarProps {
  open?: boolean;
}

const AppBar = styled(MuiAppBar, {
  shouldForwardProp: (prop) => prop !== 'open',
})<AppBarProps>(({ theme, open }) => ({
  /*  zIndex: theme.zIndex.drawer + 1,
  transition: theme.transitions.create(['width', 'margin'], {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  backgroundColor: '#E6E6E6',
  Maxheight: '52px',
  ...(open && {
    marginLeft: drawerWidth,
    width: `calc(100% - ${drawerWidth}px)`,
    transition: theme.transitions.create(['width', 'margin'], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
  }), */
}));

const defaultTheme = createTheme();

export default function Layout({ children }: { children: React.ReactNode }) {
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const { loadNotificationCount, notificationCount, borderColor } = useNotificacaoContext();
  const { logout } = useAuthContext();
  const { setCurrentTitle, currentTitle, crudStates } = useCrudContext();
  const { breadcrumb, setBreadcrumb } = React.useContext(BreadcrumbsContext);
  const [mainContentMarginLeft, setMainContentMarginLeft] = React.useState('240px');
  const { isDarkMode } = useThemeContext();

  let location = useLocation();

  React.useEffect(() => {
    // Configurações do Breadcrumb
    if (location.pathname !== '/home') {
      const current = {
        name: currentTitle,
        pathname: location.pathname,
        defaultState: crudStates,
      };
      const index = breadcrumb?.findIndex((i) => i.pathname === location.pathname);
      const targetBreadcrumb = breadcrumb?.[index];
      if (currentTitle && targetBreadcrumb?.name !== currentTitle) {
        if (breadcrumb[index]) {
          breadcrumb[index].name = currentTitle;
        }
      }
      if (index === -1) {
        const newBreadcrumb = [...breadcrumb, current];
        setBreadcrumb(newBreadcrumb);
      } else {
        const breadcrumbCopy = cloneDeep(breadcrumb);
        breadcrumbCopy.splice(index + 1);
        setBreadcrumb(breadcrumbCopy);
      }
    } else {
      setCurrentTitle('');
      setBreadcrumb([]);
    }

    loadNotificationCount();
  }, [currentTitle]);

  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleDrawerOpenMainContent = (open: boolean) => {
    if (open) {
      setMainContentMarginLeft('240px');
    } else {
      setMainContentMarginLeft('60px');
    }
  };

  const listItemIconCss = {
    '& .MuiSvgIcon-root': {
      minWidth: '40px',
      textAlign: 'center',
      justifyContent: 'center',
      height: '20px',
    },
  };

  const menuItemCss = {
    '.MuiListItemIcon-root': {
      //padding: '10px',
    },
  };

  return (
    <ThemeProvider theme={defaultTheme}>
      <Box sx={{ display: 'flex' }}>
        <CssBaseline />

        <NavDrawer handleMainContent={handleDrawerOpenMainContent} />

        <AppBar position='fixed' className='appbar' sx={{ zIndex: 1 }}>
          <Toolbar disableGutters className='toolbar-layout-topside'>
            <LogoFundacao className='logo-fundacao' />
            <div className='notification-menu'>
              <Link to='/notificacao'>
                <Button type='button'>
                  <Badge
                    badgeContent={notificationCount}
                    max={99}
                    sx={{
                      '& .MuiBadge-badge': {
                        backgroundColor: borderColor,
                        color: 'white',
                      },
                    }}
                  >
                    <NotificationsIcon></NotificationsIcon>
                  </Badge>
                </Button>
              </Link>
            </div>
            <div className='user-menu'>
              <Button onClick={handleClick}>
                <PersonIcon></PersonIcon>
              </Button>
              <Menu
                anchorEl={anchorEl}
                open={Boolean(anchorEl)}
                onClose={handleClose}
                sx={{
                  '& .MuiPaper-root': {
                    width: '170px',
                    height: '150px',
                  },
                }}
              >
                <Link to='/editar-perfil'>
                  <MenuItem sx={menuItemCss} onClick={handleClose}>
                    <ListItemIcon sx={listItemIconCss}>
                      <PersonOutlineIcon />
                    </ListItemIcon>
                    <ListItemText primary='Perfil' />
                  </MenuItem>
                </Link>

                <MenuItem sx={menuItemCss}>
                  <ListItemIcon sx={listItemIconCss}>
                    <DarkModeToggle />
                  </ListItemIcon>
                  <ListItemText primary={!isDarkMode ? 'Modo Claro' : 'Modo Escuro'} />
                </MenuItem>

                <Divider />

                <MenuItem sx={menuItemCss} onClick={logout} style={{ paddingBottom: '40px' }}>
                  <ListItemIcon sx={listItemIconCss}>
                    <LogoutIcon />
                  </ListItemIcon>
                  <ListItemText primary='Sair' />
                </MenuItem>
              </Menu>
            </div>
          </Toolbar>
        </AppBar>

        <div
          className='main-content'
          style={{ marginLeft: mainContentMarginLeft, transition: 'margin 0.5s' }}
        >
          <div style={{ padding: '15px', width: '100%' }}>
            <BreadcrumbsCustom />
            {children}
          </div>
        </div>
      </Box>
      <Footer />
    </ThemeProvider>
  );
}
