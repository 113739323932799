import { Tooltip } from '@mui/material';
import { Money } from '@styled-icons/boxicons-regular/Money';
import { useNavigate, useParams } from 'react-router-dom';
import { Crud } from '../../../components/crud/crud';
import { useCrudContext } from '../../../components/crud/crud-context';
import { positiveNumberOrEmpty } from '../../../helpers/custom-errors';
import { Data, headCells } from './instituicao-convenio.headcell';
import instituicaoConvenioUiSchema from './instituicao-convenio.uischema';

function InstituicaoConvenio() {
  const navigate = useNavigate();
  const { parameterFilters, setParameterFilters } = useCrudContext();
  const customErrors = [positiveNumberOrEmpty('valorFirmado')];

  const { convenioId } = useParams();
  const convenioIdNumber = Number(convenioId);

  const defaultForm = { convenioId: convenioIdNumber };

  const cronogramaDesembolsoAction = {
    permission:
      'ORCAMENTO.CONVENIO.INSTITUICAO_CONVENIO.CRONOGRAMA_DESEMBOLSO.CRONOGRAMA_DESEMBOLSO.EXIBIR_NO_MENU',
    action: (
      <Tooltip title='Cronograma de Desembolso' arrow>
        <Money size='24' color='#ff9900' />
      </Tooltip>
    ),
    handler: (row: any) => {
      setParameterFilters({
        ...parameterFilters,
        instituicaoConvenio: row,
      });
      navigate(`/convenio/${convenioId}/instituicao-convenio/${row.id}/cronograma-desembolso`);
    },
  };

  return (
    <Crud<Data>
      headCells={headCells}
      apiUrl={'/instituicao-convenio'}
      titleConfig={{ value: 'Instituição do Convênio', show: true }}
      uischema={instituicaoConvenioUiSchema}
      customErrors={customErrors}
      defaultForm={defaultForm}
      queryFilters={[
        { chave: 'orderBy', valor: 'nome' },
        { chave: 'orderDirection', valor: 'asc' },
        { chave: 'convenioId', valor: convenioId },
      ]}
      hideDelete
      hideUpdate
      customActions={[() => cronogramaDesembolsoAction]}
    ></Crud>
  );
}

export default InstituicaoConvenio;

export const rootViews = ['convenio'];
