import { HeadCell } from '../../components/crud/protocols/head-cell';

export interface Data extends Record<string, any> {
  nome: string;
  sigla: string;
  paisId: string;
}

export const moduleName = 'INSTITUICAO.INSTITUICAO';

export const displayProperties = ['nome'];

export const moduleLabel = 'Instituição';

export const headCells: readonly HeadCell<Data>[] = [
  {
    id: 'nome',
    numeric: false,
    disablePadding: false,
    label: 'Nome',
  },
  {
    id: 'sigla',
    numeric: false,
    disablePadding: false,
    label: 'Sigla',
  },
  {
    id: 'paisId',
    numeric: false,
    disablePadding: false,
    label: 'País',
    resource: {
      name: 'pais',
      target: 'nomePt',
    },
  },
];
