import { ListItemButton, ListItemIcon, ListItemText } from '@mui/material';
import React, { useContext } from 'react';
import { Link, useLocation } from 'react-router-dom';
import { authStringsFor } from '../../../../helpers/auth-strings-for';
import { useAuthContext } from '../../../../views/login/auth/auth-context';
import { BreadcrumbsContext } from '../../../breadcrumb/breadcrumb-context';
import { CrudStatesOptions, useCrudContext } from '../../../crud/crud-context';

export interface NavItemProps {
  title: string;
  route: string;
  icon: any;
  state?: CrudStatesOptions;
  subItem?: boolean;
}

export function NavItem(props: NavItemProps) {
  const { route, state = CrudStatesOptions.LIST, subItem = false, icon, title } = props;

  const { updateCrudStates, clearForm } = useCrudContext();
  const { breadcrumb } = useContext(BreadcrumbsContext);
  let location = useLocation();

  const clickHandler = () => {
    breadcrumb.splice(0, breadcrumb.length);
    updateCrudStates(state);
    clearForm();
  };

  const isActive = location.pathname.startsWith(route);

  const activeColor = isActive ? '#017096' : '';

  return (
    <Link to={route}>
      <ListItemButton
        onClick={() => (location.pathname === route ? {} : clickHandler())}
        sx={{
          backgroundColor: isActive ? '#d4e0e4' : '',
          borderRadius: '5px',
          '&:hover': {
            backgroundColor: isActive ? '#d4e0e4' : '#f0f0f0',
          },
          padding: '3px 10px',
        }}
      >
        <ListItemIcon className='nav-icon'>
          {React.cloneElement(icon, {
            style: {
              color: activeColor,
            },
          })}
        </ListItemIcon>
        <ListItemText
          primary={title}
          primaryTypographyProps={{
            style: {
              color: activeColor,
              fontFamily: 'Raleway',
              fontSize: '14px',
              fontWeight: 'medium',
            },
          }}
        />
      </ListItemButton>
    </Link>
  );
}
