const multiplaEscolhaUiSchema = {
  type: 'VerticalLayout',
  elements: [
    {
      type: 'LongText',
      scope: '#/properties/pergunta',
      label: 'Texto da Pergunta',
    },
    {
      type: 'Control',
      scope: '#/properties/multiplaSelecao',
      label: 'Múltipla Seleção?',
    },
    {
      type: 'Control',
      scope: '#/properties/obrigatoria',
      label: 'Pergunta Obrigatória?',
    },
    {
      type: 'Control',
      scope: '#/properties/alternativa',
      label: 'Adicionar Alternativa',
      options: {
        detail: {
          type: 'HorizontalLayout',
          elements: [
            {
              type: 'Control',
              scope: '#/properties/alternativa',
              label: 'Texto Alternativa',
            },
          ],
        },
      },
    },
  ],
};

export default multiplaEscolhaUiSchema;
