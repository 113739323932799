import { Crud } from '../../../components/crud/crud';
import { whiteSpaces } from '../../../helpers/custom-errors';
import { Data, headCells } from './plano-apresentacao.headcell';
import planoApresentacaoUiSchema from './plano-apresentacao.uischema';

function PlanoApresentacao() {
  const customErrors = [whiteSpaces('pergunta')];
  const defaultForm = { minimo: 0, maximo: 0 };

  return (
    <Crud<Data>
      headCells={headCells}
      titleConfig={{ value: 'Plano de Apresentação', show: true }}
      customErrors={customErrors}
      uischema={planoApresentacaoUiSchema}
      apiUrl={'/plano-apresentacao'}
      defaultForm={defaultForm}
      queryFilters={[{ chave: 'withDeleted', valor: true }]}
    ></Crud>
  );
}

export default PlanoApresentacao;
