import { Tooltip } from '@mui/material';
import { useNavigate, useParams } from 'react-router-dom';
import { Money } from 'styled-icons/fluentui-system-filled';
import { Crud } from '../../../components/crud/crud';
import { useCrudContext } from '../../../components/crud/crud-context';
import { Data, headCells } from './edicao-programa.headcell';
import edicaoProgramaUiSchema from './edicao-programa.uischema';

function edicaoPrograma() {
  const navigate = useNavigate();
  const { parameterFilters, setParameterFilters } = useCrudContext();
  const { programaId } = useParams();
  const programaIdNumber = Number(programaId);

  const edicaoRecursoPrograma = {
    permission: 'ORCAMENTO.PROGRAMA.EDICAO_PROGRAMA.EDICAO_RECURSO_PROGRAMA.EDICAO_RECURSO_PROGRAMA.EXIBIR_NO_MENU',
    action: (
      <Tooltip title='Recursos' arrow>
        <Money size='24' color='#009900' />
      </Tooltip>
    ),
    handler: (row: any) => {
      setParameterFilters({
        ...parameterFilters,
        edicaoPrograma: row,
      });
      navigate(`/edicao-programa/${row?.id}/edicao-recurso-programa`);
    },
  };

  const defaultForm = {
    programaId: programaIdNumber,
    valorTotal: 0,
  };

  return (
    <Crud<Data>
      headCells={headCells}
      uischema={edicaoProgramaUiSchema}
      titleConfig={{ value: 'Edições do Programa', show: true }}
      apiUrl={'/edicao-programa'}
      defaultForm={defaultForm}
      queryFilters={[{ chave: 'programaId', valor: programaId }]}
      hideDelete
      hideUpdate
      customActions={[() => edicaoRecursoPrograma]}
    ></Crud>
  );
}

export default edicaoPrograma;

export const rootViews = ['programa'];
