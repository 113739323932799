import { Crud } from '../../../components/crud/crud';
import { whiteSpaces } from '../../../helpers/custom-errors';
import { Data, headCells } from './rubrica.headcell';
import rubricasUiSchema from './rubrica.uischema';

function Rubrica() {
  const customErrors = [whiteSpaces('codigo'), whiteSpaces('nome')];
  const defaultForm = { nome: '', codigo: '' };

  return (
    <Crud<Data>
      headCells={headCells}
      titleConfig={{ value: 'Rubrica', show: true }}
      uischema={rubricasUiSchema}
      apiUrl={'/rubrica'}
      customErrors={customErrors}
      defaultForm={defaultForm}
      queryFilters={[
        { chave: 'withDeleted', valor: true },
        { chave: 'orderBy', valor: 'codigo' },
        { chave: 'orderDirection', valor: 'asc' },
      ]}
      hideView
    ></Crud>
  );
}

export default Rubrica;
