import { Tooltip } from '@mui/material';
import { Gear } from '@styled-icons/octicons/Gear';
import React from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { Crud } from '../../../components/crud/crud';
import { useCrudContext } from '../../../components/crud/crud-context';
import { Data, headCells } from './nivel-bolsa.headcell';
import nivelBolsaUiSchema from './nivel-bolsa.uischema';

function NivelBolsa() {
  const navigate = useNavigate();
  const { parameterFilters, setParameterFilters, crudStates, disabledFields, setDisabledFields } =
    useCrudContext();
  const { modalidadeBolsaId } = useParams();
  const modalidadeBolsaIdNumber = Number(modalidadeBolsaId);
  const modalidadeBolsa = parameterFilters?.['modalidadeBolsa'];

  const defaultForm = {
    modalidadeBolsaId: modalidadeBolsaIdNumber,
    modalidadeBolsa: modalidadeBolsa,
  };

  React.useEffect(() => {
    if (crudStates.edit) {
      setDisabledFields([...disabledFields, 'valorAtualBolsa']);
    }
  }, [crudStates.edit]);

  const historicoAjusteValorBolsa = {
    permission:
      'BOLSA.MODALIDADE_DE_BOLSA.NIVEL_BOLSA.HISTORICO_AJUSTE_VALOR_BOLSA.HISTORICO_AJUSTE_VALOR_BOLSA.EXIBIR_NO_MENU',
    action: (
      <Tooltip title='Ajuste do Valor' arrow>
        <Gear size='24' />
      </Tooltip>
    ),
    handler: (row: any) => {
      setParameterFilters({
        ...parameterFilters,
        nivelBolsa: row,
      });
      navigate(
        `/modalidade-bolsa/${modalidadeBolsaId}/nivel-bolsa/${row?.id}/historico-ajuste-valor-bolsa`,
      );
    },
  };

  const showHistoricoAjusteValorBolsa = (row: any) => {
    if (row.deletedAt === null) return historicoAjusteValorBolsa;
  };

  return (
    <Crud<Data>
      titleConfig={{ value: 'Níveis de Bolsa', show: true }}
      apiUrl={'/nivel-bolsa'}
      headCells={headCells}
      uischema={nivelBolsaUiSchema}
      queryFilters={[
        { chave: 'modalidadeBolsaId', valor: modalidadeBolsaId },
        { chave: 'orderBy', valor: 'sigla' },
      ]}
      customActions={[showHistoricoAjusteValorBolsa]}
      defaultForm={defaultForm}
    ></Crud>
  );
}

export default NivelBolsa;

export const rootViews = ['modalidade-bolsa'];
