import EditIcon from '@mui/icons-material/Edit';
import VisibilityIcon from '@mui/icons-material/Visibility';
import { IconButton, TableCell, TableCellProps, Tooltip } from '@mui/material';
import { CheckSquare } from '@styled-icons/boxicons-solid/CheckSquare';
import { Blocked } from '@styled-icons/icomoon/Blocked';
import { Secure } from '../../auth/secure';

interface ActionsTableCellProps {
  row: any;
  hideView?: boolean;
  hideUpdate?: boolean;
  hideDelete?: boolean;
  restoreHandler?: Function;
  viewHandler?: Function;
  editHandler?: Function;
  deleteHandler?: Function;
  customActions?: ((
    row: any,
  ) =>
    | { permission?: string; action: JSX.Element; handler: (row: any) => Promise<void> | void }
    | undefined)[];
  sx?: TableCellProps['sx'];
  moduleName?: string;
}

export function ActionsTableCell(props: ActionsTableCellProps) {
  const {
    row,
    hideView,
    hideUpdate,
    hideDelete,
    restoreHandler,
    viewHandler,
    editHandler,
    deleteHandler,
    customActions,
    sx,
    moduleName,
  } = props;

  // Para possibilitar mostrar a customAction apenas para algumas linhas da tabela,
  // processor é uma função que retorna uma action (ícone) e um handler (funcao)
  // assim, pode-se decidir quando uma action é mostrada nos arquivos .view
  const getCustomAction = (
    processor: (
      row: any,
    ) =>
      | { permission?: string; action: JSX.Element; handler: (row: any) => Promise<void> | void }
      | undefined,
    index: number,
    row: any,
  ) => {
    const processorResult = processor(row);

    const permission = processorResult?.permission;
    const action = processorResult?.action;
    const handler = processorResult?.handler;

    return (
      action && (
        <Secure key={index} permission={`${permission}`}>
          <IconButton className='custom-action' onClick={() => handler?.(row)}>
            {action}
          </IconButton>
        </Secure>
      )
    );
  };

  return (
    <TableCell className='actions' sx={{ ...sx }}>
      {row.deletedAt && (
        <Secure key={'ativar'} permission={`${moduleName}.RESTAURAR`}>
          <Tooltip title='Ativar' arrow>
            <IconButton
              onClick={() => {
                restoreHandler?.(row?.id);
              }}
            >
              <CheckSquare size='24' className='icon-restore'></CheckSquare>
            </IconButton>
          </Tooltip>
        </Secure>
      )}
      {!hideView && !row.deletedAt && (
        <Secure key={'visualizar'} permission={`${moduleName}.BUSCAR_POR_ID`}>
          <Tooltip title='Visualizar' arrow>
            <IconButton
              onClick={() => {
                viewHandler?.(row?.id);
              }}
            >
              <VisibilityIcon className='icon-view'></VisibilityIcon>
            </IconButton>
          </Tooltip>
        </Secure>
      )}
      {!hideUpdate && !row.deletedAt && (
        <Secure key={'editar'} permission={`${moduleName}.ATUALIZAR`}>
          <Tooltip title='Editar' arrow>
            <IconButton onClick={() => editHandler?.(row?.id)}>
              <EditIcon className='icon-edit'></EditIcon>
            </IconButton>
          </Tooltip>
        </Secure>
      )}
      {!hideDelete && !row.deletedAt && (
        <Secure key={'desativar'} permission={`${moduleName}.DELETAR`}>
          <Tooltip title='Desativar' arrow>
            <IconButton
              onClick={() => {
                deleteHandler?.(row?.id);
              }}
            >
              <Blocked size='18' className='icon-delete'></Blocked>
            </IconButton>
          </Tooltip>
        </Secure>
      )}
      {customActions?.map((processor, index) => getCustomAction(processor, index, row))}
    </TableCell>
  );
}
