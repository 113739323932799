import { rankWith, uiTypeIs } from '@jsonforms/core';
import { withJsonFormsControlProps } from '@jsonforms/react';
import CheckBoxOutlineBlankIcon from '@mui/icons-material/CheckBoxOutlineBlank';
import CheckBoxIcon from '@mui/icons-material/CheckBox';
import { Autocomplete, Hidden, TextField } from '@mui/material';
import Checkbox from '@mui/material/Checkbox';
import React from 'react';
import { GenericApi, makeApi } from '../../api/generic-api';
import { useCrudContext } from '../../components/crud/crud-context';
import { displayProperties, moduleLabels } from '../../views';
import { isEqual } from 'lodash';
import { translateErrors } from '../../components/crud/i18n';
import './renderer.css';
import './auto-complete-checkbox.css';

const autoCompleteCheckboxTester = rankWith(6, uiTypeIs('AutoCompleteCb'));

export const autoCompleteCheckboxRender = {
  tester: autoCompleteCheckboxTester,
  renderer: withJsonFormsControlProps(({ visible = true, schema, uischema, ...props }) => {
    const [api, setApi] = React.useState<GenericApi | null>(null);
    const [options, setOptions] = React.useState<any>([]);
    const [apiListData, setApiListData] = React.useState<any>([]);
    const [selectedItem, setSelectedItem] = React.useState<any>([]);
    const [lastFilterValues, setLastFilterValues] = React.useState<any>([]);
    const apiUrl = (schema as any)?.foreignRoute || (uischema as any)?.options?.route || '';

    const {
      errorsJsonForms,
      errorsCustom,
      validationMode,
      formData,
      crudStates,
      disabledFields,
      id,
    } = useCrudContext();

    React.useEffect(() => {
      if (apiUrl && !api) {
        const apiInstance = makeApi(apiUrl);
        setApi(apiInstance as any);
      }
    }, [api, apiUrl]);

    React.useEffect(() => {
      const filter = uischema?.options?.filter;
      if (!filter) {
        api?.getAll?.().then((dataFromApi) => {
          const mappedData = dataFromApi.map((item: any) => ({
            label: item.nome,
            value: item.id,
            codigo: item.codigo ?? '',
            data: item,
          }));
          setApiListData(mappedData);
          loadSavedItem(mappedData);
          setTimeout(() => loadEnumDefault(mappedData), 100);
        });
      } else if (api) filterHandler();
    }, [api]);

    React.useEffect(() => {
      const options = apiListData?.map?.((item: any) => ({
        label: item.label,
        value: item.value,
        codigo: item.codigo ?? '',
        data: item.data,
      }));
      setOptions(options);
    }, [apiListData]);

    const isDisabled: boolean =
      crudStates.view ||
      uischema?.options?.disabled ||
      (crudStates.edit && uischema?.options?.onlyCreate) ||
      disabledFields.includes(props.path);

    const filterHandler = () => {
      const filter = (uischema as any)?.options?.filter;
      if (!filter) return;

      const { formFields, handler } = filter;

      const queryFilterValues =
        formFields?.map?.((filter: any) => ({
          filter,
          value: formData?.[filter],
        })) ?? [];

      if (handler && !isEqual(queryFilterValues, lastFilterValues)) {
        handler?.(formData, apiListData, api)?.then?.((dataFromApi: any) => {
          const mappedData = dataFromApi.map((item: any) => ({
            label: item.nome,
            value: item.id,
            codigo: item.codigo ?? '',
            data: item,
          }));
          setApiListData(mappedData);
        });

        if (!(queryFilterValues.length && !lastFilterValues?.length)) {
          setTimeout(() => handleChange(undefined, null), 50);
        }
        setLastFilterValues(queryFilterValues);
      }
    };

    const displayPropertiesComputed = (item: any) => {
      if (!item) return '';

      const properties = displayProperties?.find((option) => option?.id === apiUrl);
      const values: string[] = [];

      if (typeof properties?.value === 'function') return properties?.value(item);

      let propValues = properties?.value as string[];
      propValues?.forEach?.((prop: any) => values?.push?.(item?.[prop]));
      return values?.join?.(', ');
    };

    const getLabel = () => {
      const uiSchemaLabel = uischema.label;
      const moduleLabel = moduleLabels?.find((headCell) => headCell?.id === apiUrl)?.value;
      return uiSchemaLabel ?? moduleLabel;
    };

    const getError = () => {
      const requiredError = errorsJsonForms?.find?.(
        (error) => error?.params?.missingProperty === props?.path,
      );
      const customRequiredError = errorsCustom?.find?.(
        (error) => error?.params?.missingProperty === props?.path,
      );
      return requiredError ?? customRequiredError;
    };

    const hasError = () => {
      return (props.required && !formData?.[props.path]) || !!getError();
    };

    const getErrorMessage = () => {
      const error = getError();
      return translateErrors?.(error) ?? error?.message ?? '';
    };

    const handleChange = (event: any, values: any) => {
      const mappedValues = values.map((value: any) => ({
        usuarioId: id,
        perfilId: value.value,
      }));
      props.handleChange(props.path, mappedValues);
      setSelectedItem(values);
    };

    const loadEnumDefault = (listData: any) => {
      if (!props.data && formData?.[`${props?.path}-codigo`]) {
        const foreignEnum = (schema as any)?.foreignEnum;
        const enumCode = foreignEnum[formData[`${props?.path}-codigo`]];
        const item = listData?.find?.((item: any) => item.codigo === enumCode);
        props.handleChange(props?.path, item?.value);
      }
    };

    const loadSavedItem = (dataFromApi: any[]) => {
      if (props?.data?.length) {
        const savedItems: any = [];
        props.data?.forEach?.((dataItem: any) => {
          const item = dataFromApi?.find((item) => item?.value === Number(dataItem?.perfilId));
          if (item) {
            const option = {
              label: item.label,
              value: item.value,
              codigo: item.codigo,
              data: item.data,
            };
            savedItems.push(option);
          }
        });
        setSelectedItem(savedItems);
      }
    };

    return (
      <Hidden xsUp={!visible}>
        <div className='custom-input-container'>
          <Autocomplete
            multiple
            className={`custom-autocomplete-checkbox ${
              (isDisabled && 'disabled-field') ||
              (hasError() && validationMode === 'ValidateAndShow' && 'has-error')
            }`}
            options={options}
            getOptionLabel={(option) => option?.label}
            isOptionEqualToValue={(option, value) => option?.value === value?.value}
            onChange={handleChange}
            value={selectedItem}
            disabled={isDisabled}
            disableCloseOnSelect
            renderOption={(props, option, { selected }) => (
              <li {...props}>
                <Checkbox
                  icon={<CheckBoxOutlineBlankIcon fontSize='small' />}
                  checkedIcon={<CheckBoxIcon fontSize='small' />}
                  checked={selected}
                />
                {option.label}
              </li>
            )}
            renderInput={(params) => (
              <TextField
                {...params}
                label={getLabel()}
                required={props.required}
                InputProps={{
                  ...params.InputProps,
                }}
                InputLabelProps={{
                  shrink: formData[props.path] && formData[props.path].length > 0,
                }}
              />
            )}
          />
          {validationMode === 'ValidateAndShow' && hasError() && (
            <span className='error-message'>{getErrorMessage()}</span>
          )}
        </div>
      </Hidden>
    );
  }),
};
