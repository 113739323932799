import NavigateNextIcon from '@mui/icons-material/NavigateNext';
import { Box, SxProps, Theme } from '@mui/material';
import Breadcrumbs from '@mui/material/Breadcrumbs';
import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';
import { Home } from '@styled-icons/material/Home';
import * as React from 'react';
import { Link } from 'react-router-dom';
import { BreadcrumbsContext } from './breadcrumb-context';
import { useCrudContext } from '../crud/crud-context';
import './breadcrumb.css';

export default function BreadcrumbsProvider() {
  const { breadcrumb, setBreadcrumb } = React.useContext(BreadcrumbsContext);
  const { setCrudStates, setCurrentTitle } = useCrudContext();

  const getClassNames = (index: number, array: any[]) => {
    let classNames = 'breadcrumb-content';
    const isLast = index === array.length - 1;
    if (isLast) {
      classNames += ' breadcrumb-last';
    }
    return classNames;
  };

  const breadcrumbs = [
    <Link to={'/'} key={'/'}>
      <Box
        className='home-link'
        onClick={() => {
          setCurrentTitle('');
          setBreadcrumb([]);
        }}
      >
        <Home size={26} className='home-icon' />
      </Box>
    </Link>,
    breadcrumb?.map((item, index, array) => {
      return (
        <Link
          to={item.pathname}
          key={item.pathname}
          onClick={() => (item.defaultState ? setCrudStates(item.defaultState) : {})}
        >
          <Typography className={getClassNames(index, array)}>{item.name}</Typography>
        </Link>
      );
    }),
  ];

  return (
    <Stack spacing={1} sx={{ margin: 1 }}>
      <Breadcrumbs
        separator={<NavigateNextIcon className='nav-icon' fontSize='small' />}
        aria-label='breadcrumb'
      >
        {breadcrumbs}
      </Breadcrumbs>
    </Stack>
  );
}
