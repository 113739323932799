import { GenericApi } from '../../../api/generic-api';
import client from '../../../config/axios-config';

export class CronogramaDesembolsoApi extends GenericApi {
  constructor(url: string) {
    super(url);
  }

  async makePayment(_: number, data: any): Promise<any> {
    try {
      const response = await client.put<any>(`${this.url}/makePayment`, data);
      return response;
    } catch (error: any) {
      if (error?.response?.status === 401) {
        localStorage.removeItem('token');
        window.location.href = '/login';
      }

      throw new Error('Put error: ', { cause: error });
    }
  }
}

export const makeCronogramaDesembolsoApi = (url: string) => new CronogramaDesembolsoApi(url);
