import { customLength, customRequired } from '../../../helpers/custom-errors';

export const valorError = {
  keyword: 'positiveNumber',
  message: 'O campo deve ser um número positivo.',
  propertyName: 'valor',
  instancePath: `/valor`,
  schemaPath: '#/properties/cronograma-desembolso/properties/valor/positiveNumber',
  params: {},
};

export const valorLengthError = {
  keyword: 'lengthError',
  message: 'O número deve ter no máximo 8 dígitos.',
  propertyName: 'valor',
  instancePath: `/valor`,
  schemaPath: '#/properties/cronograma-desembolso/properties/valor/positiveNumber',
  params: {},
};

export const dataPrevistaRequired = customRequired('dataPrevista');
export const dataEfetivaRequired = customRequired('dataEfetiva');
export const instituicaoConvenioIdRequired = customRequired('instituicaoConvenioId');
export const parcelaRequired = customRequired('parcela');
