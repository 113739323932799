import { HeadCell } from '../../../components/crud/protocols/head-cell';

export interface Data extends Record<string, any> {
  id: number;
  nome: string;
  inicioVigencia: Date;
  terminoVigencia: Date;
  dataAssinatura: Date;
  dataPublicacao: Date;
  justificativa: Date;
  status: string;
  tipoConvenio: number;
}

export const moduleName = 'ORCAMENTO.CONVENIO.CONVENIO';

export const displayProperties = ['nome'];

export const moduleLabel = 'Convênio';

export const headCells: readonly HeadCell<Data>[] = [
  {
    id: 'nome',
    numeric: false,
    disablePadding: false,
    label: 'Nome',
  },
  {
    id: 'tipoConvenio',
    numeric: true,
    disablePadding: false,
    label: 'Tipo',
    resource: {
      name: 'tipoConvenio',
      target: 'nome',
    },
  },
  {
    id: 'inicioVigencia',
    numeric: false,
    disablePadding: false,
    label: 'Início da Vigência',
    dateFormat: 'DD/MM/YYYY',
  },
  {
    id: 'terminoVigencia',
    numeric: false,
    disablePadding: false,
    label: 'Término da Vigência',
    dateFormat: 'DD/MM/YYYY',
  },
  {
    id: 'valorFirmado',
    numeric: true,
    disablePadding: false,
    label: 'Valor Firmado',
    mask: 'money',
  },
  {
    id: 'valorFinal',
    numeric: true,
    disablePadding: false,
    label: 'Valor Final',
    mask: 'money',
  },
  {
    id: 'valorDesembolso',
    numeric: true,
    disablePadding: false,
    label: 'Valor Desembolso',
    mask: 'money',
  },
  {
    id: 'desembolsoPago',
    numeric: true,
    disablePadding: false,
    label: 'Desembolso Pago',
    mask: 'money',
  },
  {
    id: 'valorAlocado',
    numeric: true,
    disablePadding: false,
    label: 'Valor Alocado',
    mask: 'money',
  },
];
