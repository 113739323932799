import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import {
  Box,
  Button,
  Checkbox,
  CssBaseline,
  FormControlLabel,
  Grid,
  Step,
  StepButton,
  StepIconProps,
  StepLabel,
  Stepper,
  Typography,
} from '@mui/material';
import { Home } from '@styled-icons/fluentui-system-filled/Home';
import { Person } from '@styled-icons/fluentui-system-filled/Person';
import { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { GenericApi, makeApi } from '../../api/generic-api';
import AlertCustom from '../../components/crud/alert-custom';
import { useCrudContext } from '../../components/crud/crud-context';
import ButtonCustom from '../../components/custom-input/custom-button';
import InputCustom from '../../components/custom-input/custom-input';
import LogoFundacao from '../../components/layout/logo-fundacao';
import { makeTermoPoliticaPrivacidadeApi } from '../fundacao/termo-politica-privacidade/termo-politica-privacidade-api';
import './login.css';

interface LoginFormState {
  nome: string;
  nomeSocial?: string;
  dataNascimento?: Date | null;
  email: string;
  senha: string;
  senhaConfirmar: string;
}

const Registrar = () => {
  const navigate = useNavigate();
  const [api, setApi] = useState<GenericApi>();
  const { openAlert, messageAlert, severityAlert, showError, showSuccess } = useCrudContext();
  const [formData, setFormData] = useState<LoginFormState>({
    nome: '',
    nomeSocial: '',
    dataNascimento: null,
    email: '',
    senha: '',
    senhaConfirmar: '',
  });
  const [termoPoliticaPrivacidade, setTermoPoliticaPrivacidadeApi] = useState({});
  const [activeStep, setActiveStep] = useState(0);
  const steps = ['Informações Pessoais', 'Aceite de Termos'];
  const [isEndOfScroll, setIsEndOfScroll] = useState(false);
  const [checked, setChecked] = useState(false);

  useEffect(() => {
    const usuarioApi = makeApi('usuario');
    setApi(usuarioApi);
    const termoPoliticaPrivacidadeApi = makeTermoPoliticaPrivacidadeApi();
    termoPoliticaPrivacidadeApi
      ?.get?.()
      ?.then?.((res) => {
        if (!!res) {
          const text: string = res?.politicaDePrivacidade || '';
          setTermoPoliticaPrivacidadeApi(text);
          if (text.length < 1200) {
            setIsEndOfScroll(true);
          }
        }
      })
      ?.catch((e) => console.log(e));
  }, []);

  const handleScroll = (e: any) => {
    const bottom = e.target.scrollHeight - e.target.scrollTop === e.target.clientHeight;
    if (bottom) {
      setIsEndOfScroll(true);
    }
  };

  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setFormData({
      ...formData,
      [e.target.name]: e.target.value,
    });
  };

  const handleSubmit = (e: React.FormEvent) => {
    e.preventDefault();

    if (formData.senha !== formData.senhaConfirmar) {
      showError('A senha e a confirmação da senha não conferem.');
      return;
    }

    // String deve ter entre 8 e 64 char, contendo 1 letra minuscula, 1 maiuscula, 1 numero e 1 caracter especial, pelo menos.
    const validations =
      /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[!@#$%^&*()-_=+{};:'",<.>\/?\\[\]`~])[\w!@#$%^&*()-_=+{};:'",<.>\/?\\[\]`~]{8,64}$/;
    if (!formData.senha?.match?.(validations)) {
      showError(
        'A senha deve ter entre 8 e 64 caracteres, contendo pelo menos 1 letra minúscula, 1 maiúscula, 1 número e 1 caracter especial.',
      );
      return;
    }

    if (checked && activeStep > 0) {
      api
        ?.post?.(formData)
        ?.then?.((res) => {
          if (res?.status === 201) {
            showSuccess('Conta criada com sucesso.');
            navigate('/login');
          }
        })
        .catch((err: any) => {
          showError(
            err?.cause?.response?.data?.message?.[0] ||
              err?.response?.data?.message?.[0] ||
              'Erro ao criar usuário.',
          );
          console.error('Erro ao criar usuário:', err?.cause);
        });
    }

    if (steps.length > activeStep + 1) setActiveStep(activeStep + 1);
  };

  function IconStepByStep(props: StepIconProps) {
    const icons: { [index: string]: React.ReactElement } = {
      1: <Person size={28} color='#ebf0f4' />,
      2: <Home size={28} color='#ebf0f4' />,
    };
    let sx;
    if (String(activeStep + 1) == String(props.icon)) {
      sx = { backgroundColor: '#1351b4' };
    } else {
      sx = { backgroundColor: '#d9d9d9' };
    }

    return (
      <Box sx={{ ...sx, padding: '10px', borderRadius: '100%' }}>{icons[String(props.icon)]}</Box>
    );
  }

  return (
    <div className='div-group'>
      <div className='background' />

      <div className='login-container'>
        <div className='back-button-container'>
          <Button className='back-button' onClick={() => navigate('/login')}>
            <ArrowBackIcon></ArrowBackIcon>
            Voltar
          </Button>
        </div>
        <CssBaseline />
        <div className='paper'>
          <LogoFundacao className='logo-image' />
          <form className='login-form' onSubmit={handleSubmit}>
            <Stepper nonLinear activeStep={activeStep}>
              {steps.map((label, index) => (
                <Step key={label}>
                  <StepButton color='inherit' onClick={() => setActiveStep(index)}>
                    <StepLabel StepIconComponent={IconStepByStep}>
                      <Typography
                        sx={{
                          fontFamily: 'Raleway',
                          fontSize: '16px',
                          fontWeight: 600,
                          width: 100,
                          textAlign: 'left',
                          lineHeight: 1.2,
                        }}
                      >
                        {label}
                      </Typography>
                    </StepLabel>
                  </StepButton>
                </Step>
              ))}
            </Stepper>
            {activeStep == 0 && (
              <Grid>
                <InputCustom
                  required
                  label='Nome completo'
                  name='nome'
                  value={formData.nome}
                  onChange={handleChange}
                />
                <InputCustom
                  label='Nome Social'
                  name='nomeSocial'
                  value={formData.nomeSocial}
                  onChange={handleChange}
                />
                <InputCustom
                  required
                  type='date'
                  name='dataNascimento'
                  label='Data de Nascimento'
                  value={formData.dataNascimento}
                  onChange={handleChange}
                />
                <InputCustom
                  required
                  label='Email'
                  name='email'
                  value={formData.email}
                  onChange={handleChange}
                />
                <InputCustom
                  required
                  name='senha'
                  label='Senha'
                  type='password'
                  value={formData.senha}
                  onChange={handleChange}
                />
                <InputCustom
                  required
                  name='senhaConfirmar'
                  label='Confirmar Senha'
                  type='password'
                  value={formData.senhaConfirmar}
                  onChange={handleChange}
                />
                <Box sx={{ display: 'flex', justifyContent: 'right' }}>
                  <ButtonCustom color='primary' onClick={handleSubmit}>
                    Próximo
                  </ButtonCustom>
                </Box>
              </Grid>
            )}
            {activeStep == 1 && (
              <Grid>
                <Box
                  onScroll={handleScroll}
                  sx={{
                    minWidth: 550,
                    padding: '0px 15px',
                    height: 400,
                    overflowY: 'auto',
                    marginTop: 3,
                  }}
                >
                  <Typography variant='h5' sx={{ fontWeight: 700 }}>
                    Termo de Política e Privacidade
                  </Typography>
                  <Typography variant='body1' sx={{ marginTop: 1 }}>
                    <div
                      style={{ wordWrap: 'break-word' }}
                      dangerouslySetInnerHTML={{ __html: String(termoPoliticaPrivacidade) }}
                    ></div>
                  </Typography>
                </Box>
                <Box sx={{ margin: '10px 0px' }}>
                  <FormControlLabel
                    disabled={isEndOfScroll ? false : true}
                    control={<Checkbox checked={checked} onClick={() => setChecked(!checked)} />}
                    label='Li e aceitei a Política de Privacidade e os Termos de Uso'
                  />
                </Box>
                <Box sx={{ display: 'flex', justifyContent: 'space-between', gap: 1 }}>
                  <ButtonCustom variant='outline' onClick={() => navigate('/login')}>
                    Não aceito
                  </ButtonCustom>
                  <ButtonCustom type='submit' color='primary' disabled={!checked}>
                    Finalizar
                  </ButtonCustom>
                </Box>
              </Grid>
            )}
          </form>
          <AlertCustom
            open={openAlert}
            message={messageAlert}
            severity={severityAlert}
          ></AlertCustom>
        </div>
      </div>
    </div>
  );
};

export default Registrar;

export const disableAutoRoute = true;
