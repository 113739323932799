import { JsonSchema } from '@jsonforms/core';
import client from '../config/axios-config';

export interface FormApi {
  get url(): string;
  getSchema: () => Promise<JsonSchema | undefined>;
  get: (id: number) => Promise<any>;
  getAll: (options?: { chave: string; valor: any }[]) => Promise<any>;
  put: (id: number, data: any) => Promise<any>;
  post: (data: any) => Promise<any>;
  delete: (id: number) => Promise<any>;
  restore: (id: number) => Promise<any>;
}

export class GenericApi implements FormApi {
  constructor(public readonly url: string) {}

  async getSchema(): Promise<JsonSchema | undefined> {
    try {
      const response = await client.get<JsonSchema>(`${this.url}/schema`);
      return response.data;
    } catch (error: any) {
      if (error?.response?.status === 401) {
        localStorage.removeItem('token');
        window.location.href = '/login';
      }
      throw new Error('GetSchema error: ', { cause: error });
    }
  }

  async get(id: number): Promise<any> {
    try {
      const response = await client.get<any>(`${this.url}/${id}`);
      return response.data;
    } catch (error: any) {
      if (error?.response?.status === 401) {
        localStorage.removeItem('token');
        window.location.href = '/login';
      }
      throw new Error('Get error: ', { cause: error });
    }
  }

  async getAll(
    options: { chave: string; valor: any }[] = [{ chave: 'withDeleted', valor: false }],
  ): Promise<any> {
    try {
      const urlBuild = options.reduce(
        (acc, { chave, valor }, i) => `${acc}${i === 0 ? '?' : '&'}${chave}=${String(valor) ?? ''}`,
        this.url,
      );
      const response = await client.get<any>(urlBuild);
      return response?.data?.data;
    } catch (error: any) {
      if (error?.response?.status === 401) {
        localStorage.removeItem('token');
        window.location.href = '/login';
      }
      throw new Error('GetAll error: ', { cause: error });
    }
  }

  async restore(id: number): Promise<any> {
    try {
      const response = await client.put<any>(`${this.url}/restore/${id}`);
      return response;
    } catch (error: any) {
      if (error?.response?.status === 401) {
        localStorage.removeItem('token');
        window.location.href = '/login';
      }
      throw new Error('Restore error: ', { cause: error });
    }
  }

  async put(id: number, data: any): Promise<any> {
    try {
      const response = await client.put<any>(`${this.url}/${id}`, data);
      return response;
    } catch (error: any) {
      if (error?.response?.status === 401) {
        localStorage.removeItem('token');
        window.location.href = '/login';
      }
      throw new Error('Put error: ', { cause: error });
    }
  }

  async post(data: any): Promise<any> {
    try {
      const response = await client.post<any>(`${this.url}`, data);
      return response;
    } catch (error: any) {
      if (error?.response?.status === 401) {
        localStorage.removeItem('token');
        window.location.href = '/login';
      }
      throw new Error('Post error: ', { cause: error });
    }
  }

  async delete(id: number): Promise<any> {
    try {
      const response = await client.delete<any>(`${this.url}/${id}`);
      return response;
    } catch (error: any) {
      if (error?.response?.status === 401) {
        localStorage.removeItem('token');
        window.location.href = '/login';
      }
      throw new Error('Delete error: ', { cause: error });
    }
  }
}

export const makeApi = (url: string) => {
  return new GenericApi(url);
};
