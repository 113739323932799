import { Crud } from '../../../components/crud/crud';
import { Data, headCells } from './multipla-escolha.headcell';
import multiplaEscolhaUiSchema from './multipla-escolha.uischema';

const defaultForm = { multiplaSelecao: false, obrigatoria: false };

function MultiplaEscolha() {
  return (
    <Crud<Data>
      headCells={headCells}
      titleConfig={{ value: 'Múltipla Escolha', show: true }}
      queryFilters={[{ chave: 'withDeleted', valor: true }]}
      uischema={multiplaEscolhaUiSchema}
      apiUrl={'/multipla-escolha'}
      defaultForm={defaultForm}
    ></Crud>
  );
}

export default MultiplaEscolha;
