import { kebabCase } from 'change-case';
import fileClient from '../config/axios-file-config';

export interface IFileData {
  addFile: (
    file: File,
    setFileProgress: Function,
    baseUrl: string,
    path: string,
    formDataId?: number,
  ) => any;
  removeFile: (baseUrl: string, path: string, formDataId: number, fileRemoveId: number) => any;
  getFile: (baseUrl: string, path: string, formDataId?: number) => any;
}

export class FileApi implements IFileData {
  async addFile(
    file: File,
    setFileProgress: Function,
    baseUrl: string,
    path: string,
    formDataId?: number,
    category?: string,
  ): Promise<any> {
    const localFormData = new FormData();
    localFormData.append('file', file);

    const urlParams = [baseUrl, formDataId, kebabCase(path), category];
    const url = urlParams.filter((x) => x).join('/');

    const response = await fileClient.post<any>(url, localFormData, {
      onUploadProgress: (ProgressEvent: any) => {
        const percentCompleted = Math.round((ProgressEvent.loaded * 100) / ProgressEvent.total);
        setFileProgress((prevProgress: any) => ({
          ...prevProgress,
          [file.name]: percentCompleted,
        }));
      },
    });

    return response.data;
  }

  async removeFile(
    baseUrl: string,
    path: string,
    fileId: number | string,
    formDataId?: number,
  ): Promise<any> {
    const urlParams = [baseUrl, formDataId, kebabCase(path), fileId];
    const url = urlParams.filter((x) => x).join('/');

    await fileClient.delete<any>(url);
  }

  async getFile(baseUrl: string, path: string, formDataId?: number): Promise<any> {
    const urlParams = [baseUrl, formDataId, kebabCase(path)];
    const url = urlParams.filter((x) => x).join('/');

    const response = await fileClient.get<any>(url);
    return response;
  }
}
