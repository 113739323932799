import { Crud } from '../../../components/crud/crud';
import { whiteSpaces, positiveNumber } from '../../../helpers/custom-errors';
import { Data, headCells } from './tipo-anexo.headcell';
import tipoAnexoUiSchema from './tipo-anexo.uischema';

function TipoAnexo() {
  const customErrors = [whiteSpaces('nome'), positiveNumber('tamanhoArquivo')];

  return (
    <Crud<Data>
      defaultForm={{}}
      headCells={headCells}
      apiUrl={'/tipo-anexo'}
      titleConfig={{ value: 'Tipos de Anexos', show: true }}
      customErrors={customErrors}
      uischema={tipoAnexoUiSchema}
      queryFilters={[{ chave: 'withDeleted', valor: true }]}
    ></Crud>
  );
}

export default TipoAnexo;
