import { rankWith, uiTypeIs } from '@jsonforms/core';
import { withJsonFormsControlProps } from '@jsonforms/react';
import { Autocomplete, Hidden, OutlinedInputProps, TextField } from '@mui/material';
import { DownArrow } from '@styled-icons/boxicons-solid/DownArrow';
import React, { ChangeEvent, SyntheticEvent } from 'react';
import { GenericApi, makeApi } from '../../api/generic-api';
import { useCrudContext } from '../../components/crud/crud-context';
import { translateErrors } from '../../components/crud/i18n';
import { Coin, Option, Props } from './protocols/currency-render.type';
import './renderer.css';
import { scopeToPath } from '../../helpers/scope-to-path';
import { camelCase } from 'change-case';

const currencyTester = rankWith(5, uiTypeIs('Currency'));

export const currencyRender = {
  tester: currencyTester,
  renderer: withJsonFormsControlProps((anyProps) => {
    // cast props to expected type
    const props = anyProps as Props;

    const { errorsJsonForms, additionalErrors, validationMode, crudStates, disabledFields } =
      useCrudContext();

    const [api, setApi] = React.useState<GenericApi | null>(null);
    const [apiListData, setApiListData] = React.useState<any>([]);

    const [options, setOptions] = React.useState<any>([]);
    const [selected, setSelected] = React.useState<Option>();

    const [moneyValue, setMoneyValue] = React.useState('');

    const selectUiSchema =
      props.uischema.elements.find((e) => e.type === 'Select') ?? props.uischema.elements[1];
    const moneyUiSchema =
      props.uischema.elements.find((e) => e.type === 'MonetaryInput') ?? props.uischema.elements[0];

    // -------------------------------------------------------------------------
    // *                                Shared
    // -------------------------------------------------------------------------
    const getError = (path: string): string | undefined => {
      const instancePath = `/${path?.replace?.('.', '/')}`;

      const errorJsonForms = errorsJsonForms?.find?.(
        (error) => error?.params?.missingProperty === path || error?.instancePath === instancePath,
      );

      const additionalError = additionalErrors?.find?.(
        (error) => error?.params?.missingProperty === path || error?.instancePath === instancePath,
      );

      return errorJsonForms ?? additionalError;
    };

    const hasError = (scope: string): boolean => {
      const path = scopeToPath(scope);
      return (
        ((props.required || props.uischema.options?.required) && !props.data) || !!getError(path)
      );
    };

    const getErrorMessage = (scope: string) => {
      const path = scopeToPath(scope);
      const error = getError(path);
      return translateErrors(error) ?? '';
    };

    // -------------------------------------------------------------------------
    // *                            SelectSpecific
    // -------------------------------------------------------------------------

    React.useEffect(() => {
      const coinUrl = props.schema.properties?.moedaId.foreignRoute;

      if (coinUrl && !api) {
        const apiInstance = makeApi(coinUrl);
        setApi(apiInstance as any);
      }
    }, [api]);

    React.useEffect(() => {
      api?.getAll?.().then((apiData: any[] | undefined) => {
        if (apiData) {
          const filterList = selectUiSchema.options?.filterList;

          if (filterList) {
            filterList(apiData).then?.((filteredData: any[]) => {
              setApiListData(filteredData);
            });
          } else {
            setApiListData(apiData);
          }
        }
      });
    }, [api]);

    React.useEffect(() => {
      getOptions();

      if ((props.data && !selected) || selected?.value !== Number(props.data)) {
        const item = apiListData.find((item: any) => item.id === Number(props.data.moedaId));

        if (item) {
          const option = {
            label: item.simbolo,
            value: item.id,
          };

          setSelected(option);
        }
      }
    }, [props.data, apiListData]);

    const isSelectDisabled: boolean =
      crudStates.view ||
      selectUiSchema.options?.disabled ||
      ((crudStates.edit && selectUiSchema.options?.onlyCreate) ?? false) ||
      disabledFields.includes(scopeToPath(selectUiSchema.scope));

    const getOptions = (): void => {
      const options = apiListData.map((item: Coin) => ({
        label: item.simbolo,
        value: item.id,
      }));

      setOptions(options);
    };

    const handleSelectChange = (_: SyntheticEvent, option: Option) => {
      const coinRoute = props.schema.properties?.moedaId.foreignRoute!;
      const path = scopeToPath(selectUiSchema.scope);
      setSelected(option);
      props.handleChange(path, option.value);
      props.handleChange(camelCase(coinRoute), { id: option.value });
    };

    const select = (
      <Autocomplete
        className={`currency-select ${
          (isSelectDisabled && 'disabled-field') ||
          (hasError(moneyUiSchema.scope) && validationMode === 'ValidateAndShow' && 'has-error')
        }`}
        options={options}
        value={selected}
        getOptionLabel={(option: any) => option?.label}
        onChange={handleSelectChange}
        isOptionEqualToValue={(option: any, value: any) => option?.value === value?.value}
        disabled={isSelectDisabled}
        renderInput={(params) => (
          <TextField
            {...props}
            {...params}
            required={props.required}
            InputProps={{
              ...params.InputProps,
              endAdornment: <DownArrow size={5} />,
            }}
            InputLabelProps={{ shrink: false }}
          />
        )}
      />
    );

    // -------------------------------------------------------------------------
    // *                            MoneySpecific
    // -------------------------------------------------------------------------

    React.useEffect(() => {
      if (props.data.valorDiaria) {
        setMoneyValue(formatReal(props.data.valorDiaria));
      }
    }, []);

    const isMoneyDisabled: boolean =
      crudStates.view ||
      moneyUiSchema.options?.disabled ||
      ((crudStates.edit && moneyUiSchema.options?.onlyCreate) ?? false) ||
      disabledFields.includes(moneyUiSchema.scope);

    const formatReal = (input: string) => {
      const numericValue = Number(input);
      const realValue = numericValue / 100;
      return realValue.toLocaleString('pt-BR', {
        minimumFractionDigits: 2,
        maximumFractionDigits: 2,
      });
    };

    const handleMoneyChange = (event: ChangeEvent<HTMLInputElement>) => {
      const originalCursorPosition = event.target.selectionStart ?? 0;
      const originalLength = event.target.value.length;

      const inputValue = event.target.value.replace(/[^0-9]/g, '');
      const formattedValue = formatReal(inputValue);
      setMoneyValue(formattedValue);

      const path = scopeToPath(moneyUiSchema.scope);
      props.handleChange(path, Number(inputValue));

      const lengthDifference = formattedValue.length - originalLength;
      let newCursorPosition = originalCursorPosition + lengthDifference;
      newCursorPosition = Math.max(0, Math.min(newCursorPosition, formattedValue.length));

      event.target.setSelectionRange(newCursorPosition, newCursorPosition);
    };

    const money = (
      <TextField
        {...props}
        variant='filled'
        className={`currency-input ${
          (isMoneyDisabled && 'disabled-field') ||
          (hasError(moneyUiSchema.scope) && validationMode === 'ValidateAndShow' && 'has-error')
        }`}
        required={props.required}
        disabled={isMoneyDisabled}
        defaultValue={props?.data ?? ''}
        value={moneyValue}
        placeholder='Valor da diária'
        onChange={handleMoneyChange}
        InputProps={
          {
            disableUnderline: true,
          } as Partial<OutlinedInputProps>
        }
      />
    );

    return (
      <Hidden xsUp={!props.visible}>
        <div className='custom-input-container'>
          <div className='row'>
            {select}
            {money}
          </div>
          {validationMode === 'ValidateAndShow' && hasError(selectUiSchema.scope) && (
            <span className='error-message'>{getErrorMessage(selectUiSchema.scope)}</span>
          )}
          {!hasError(selectUiSchema.scope) &&
            validationMode === 'ValidateAndShow' &&
            hasError(moneyUiSchema.scope) && (
              <span className='error-message'>{getErrorMessage(moneyUiSchema.scope)}</span>
            )}
        </div>
      </Hidden>
    );
  }),
};
