const tipoAnexoUiSchema = {
  type: 'VerticalLayout',
  elements: [
    {
      type: 'Control',
      scope: '#/properties/nome',
      label: 'Nome',
    },
    {
      type: 'Control',
      scope: '#/properties/descricao',
      label: 'Descrição',
    },
    {
      type: 'Select',
      scope: '#/properties/formatoArquivo',
      label: 'Formato do arquivo',
    },
    {
      type: 'Control',
      scope: '#/properties/tamanhoArquivo',
      label: 'Tamanho do arquivo (MB)',
    },
  ],
};

export default tipoAnexoUiSchema;
