const moedaUiSchema = {
  type: 'VerticalLayout',
  elements: [
    {
      type: 'Control',
      scope: '#/properties/nome',
    },
    {
      type: 'Control',
      scope: '#/properties/simbolo',
      label: 'Símbolo',
    },
    {
      type: 'Control',
      scope: '#/properties/sigla',
    },
  ],
};

export default moedaUiSchema;
