import React, { createContext, useContext } from 'react';
import { makeNotificacaoApi } from '../../../api/notificacao-api';

export interface Notificacao {
  id: number;
  assunto: string;
  corpo: string;
  prioridade: number;
  lida: Date;
  emailEnviado: Date;
  dataHoraEnvio: Date;
  modulo: string;
  checked: boolean;
  destinatario: { nome: string };
  remetente: { nome: string };
  deletedAt: Date;
}

interface NotificacaoContextData {
  borderColor: string;
  notificationCount: number;
  loadNotificationCount: () => void;
}

const NotificacaoContext = createContext<NotificacaoContextData | undefined>(undefined);

export const NotificacaoProvider: React.FC = ({ children }) => {
  const [borderColor, setBorderColor] = React.useState<string>('');
  const [notificationCount, setNotificationCount] = React.useState<number>(0);

  const loadNotificationCount = () => {
    const notificacaoApi = makeNotificacaoApi();

    notificacaoApi.countUnread().then((data) => {
      let highestPriority = 2;
      const total = Object.values(data).reduce((acc, value) => acc + value, 0);
      if ('urgente' in data) {
        highestPriority = 0;
      } else if ('atencao' in data) {
        highestPriority = 1;
      }
      setNotificationCount(total);
      setBorderColor(buildBorderColor(highestPriority));
    });
  };

  const buildBorderColor = (highestPriority: number) => {
    switch (highestPriority) {
      case 0:
        return '#bb2124';
      case 1:
        return '#f0ad4e';
      case 2:
      default:
        return '#22bb33';
    }
  };

  return (
    <NotificacaoContext.Provider
      value={{
        borderColor,
        notificationCount,
        loadNotificationCount,
      }}
    >
      {children}
    </NotificacaoContext.Provider>
  );
};

export const useNotificacaoContext = () => {
  const context = useContext(NotificacaoContext);
  if (!context) {
    throw new Error('useAuthContext deve ser usado dentro de um AuthProvider');
  }
  return context;
};
