import { Tooltip } from '@mui/material';
import { Star } from '@styled-icons/evaicons-solid/Star';
import { useNavigate } from 'react-router-dom';
import { Crud } from '../../../components/crud/crud';
import { useCrudContext } from '../../../components/crud/crud-context';
import { Data, headCells } from './linha-de-acao.headcell';
import linhaDeAcaoUiSchema from './linha-de-acao.uischema';

function LinhaDeAcao() {
  const navigate = useNavigate();
  const { parameterFilters, setParameterFilters } = useCrudContext();

  const edicaoLinhaDeAcao = {
    permission: 'ORCAMENTO.LINHA_DE_ACAO.EDICAO_LINHA_DE_ACAO.EDICAO_LINHA_DE_ACAO.EXIBIR_NO_MENU',
    action: (
      <Tooltip title='Edição' arrow>
        <Star size='24' color='#009900' />
      </Tooltip>
    ),
    handler: (row: any) => {
      setParameterFilters({
        ...parameterFilters,
        linhaDeAcao: row,
      });
      navigate(`/linha-de-acao/${row?.id}/edicao-linha-de-acao`);
    },
  };

  return (
    <Crud<Data>
      headCells={headCells}
      titleConfig={{ value: 'Linha de Ação', show: true }}
      uischema={linhaDeAcaoUiSchema}
      apiUrl={'/linha-de-acao'}
      queryFilters={[
        { chave: 'withDeleted', valor: true },
        { chave: 'orderBy', valor: 'dataInicio' },
        { chave: 'orderDirection', valor: 'ASC' },
      ]}
      customActions={[() => edicaoLinhaDeAcao]}
      formSize={{ width: { value: 650 } }}
    ></Crud>
  );
}

export default LinhaDeAcao;
