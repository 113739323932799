import { HeadCell } from '../../../components/crud/protocols/head-cell';

export interface Data extends Record<string, any> {
  id: number;
  codigo: string;
  nivel: string;
  valorAtualAjusteBolsa: number;
  cargaHoraria: number;
}

export const moduleName = 'BOLSA.MODALIDADE_DE_BOLSA.NIVEL_BOLSA.NIVEL_BOLSA';

export const moduleLabel = 'Nível de Bolsa';

export const headCells: readonly HeadCell<Data>[] = [
  {
    id: 'codigo',
    numeric: false,
    disablePadding: false,
    label: 'Código',
  },
  {
    id: 'nivel',
    numeric: false,
    disablePadding: false,
    label: 'Nível',
  },
  {
    id: 'valorAtualBolsa',
    numeric: true,
    disablePadding: false,
    label: 'Valor',
    mask: 'money',
  },
  {
    id: 'cargaHoraria',
    numeric: true,
    disablePadding: false,
    label: 'Carga Horária',
  },
];
