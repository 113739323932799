import React, { createContext, useContext, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { makeLoginApi } from '../../../api/login-api';
import { Instituicao } from '../../../types/instituicao.type';
import { Anexo } from '../../../types/anexo.type';

export interface IConfiguracoesFundacao {
  fundacaoInstituicaoId: string;
  instituicao: Instituicao;
  logoCabecalho?: Anexo;
  [key: string]: any;
}

interface UserPayload {
  id: number;
  nome: string;
  email: string;
}

interface AuthData {
  token: string;
  user: UserPayload;
  permissions: string[];
  expiresIn: number;
}

interface AuthContextData {
  login: (authData: AuthData) => void;
  logout: () => void;
  dadosFundacao: (configuracoesFundacao: IConfiguracoesFundacao) => void;
  isLoggedIn: boolean;
  token: string;
  permissions: string[];
  configuracoesFundacao: IConfiguracoesFundacao;
}

const AuthContext = createContext<AuthContextData | undefined>(undefined);

export const AuthProvider: React.FC = ({ children }) => {
  useEffect(() => {
    const loginApi = makeLoginApi();
    loginApi
      .obterConfiguracoesFundacao()
      .then((config) => {
        if (config?.status === 200 && config.data.fundacaoInstituicaoId) {
          dadosFundacao(config.data);
        }
      })
      .catch((err) => {
        console.error(err);
      });
  }, []);

  const navigate = useNavigate();
  const token = localStorage.getItem('token') || '';
  const permissions = JSON.parse(localStorage.getItem('permissions') || '[]') as string[];
  const configuracoesFundacao = JSON.parse(
    localStorage.getItem('configuracoesFundacao') || '{}',
  ) as IConfiguracoesFundacao;
  const isLoggedIn = !!token;

  const login = (authData: AuthData) => {
    localStorage.setItem('token', authData.token);
    localStorage.setItem('permissions', JSON.stringify(authData.permissions));
    navigate('/home');
  };

  const logout = () => {
    localStorage.removeItem('token');
    localStorage.removeItem('permissions');
    navigate('/login');
  };

  const dadosFundacao = (configuracoesFundacao: IConfiguracoesFundacao) => {
    localStorage.setItem('configuracoesFundacao', JSON.stringify(configuracoesFundacao));
  };

  return (
    <AuthContext.Provider
      value={{
        login,
        logout,
        dadosFundacao,
        token,
        permissions,
        isLoggedIn,
        configuracoesFundacao,
      }}
    >
      {children}
    </AuthContext.Provider>
  );
};

export const useAuthContext = () => {
  const context = useContext(AuthContext);
  if (!context) {
    throw new Error('useAuthContext deve ser usado dentro de um AuthProvider');
  }
  return context;
};
