import { rankWith, uiTypeIs } from '@jsonforms/core';
import { withJsonFormsControlProps } from '@jsonforms/react';
import Editor from '../../components/layout/ckeditor';
import './renderer.css';
import { useCrudContext } from '../../components/crud/crud-context';
import { translateErrors } from '../../components/crud/i18n';

const CkEditorRenderTester = rankWith(5, uiTypeIs('CkEditor'));

export const CkEditorRender = {
  tester: CkEditorRenderTester,
  renderer: withJsonFormsControlProps(({ visible = true, path, schema, uischema, ...props }) => {
    const isDisabled = props.enabled;

    const { errorsJsonForms, additionalErrors, validationMode, showError } = useCrudContext();

    const handleBlur = (editorData: string) => {
      props.handleChange(path, editorData);
    };

    const hasError = () => {
      return ((props.required || uischema.options?.required) && !props.data) || !!getError();
    };

    const getError = () => {
      const instancePath = `/${path?.replace?.('.', '/')}`;
      const pathSplitted = path.split('.');
      const lastPath = pathSplitted[pathSplitted.length - 1];
      const errorJsonForms = errorsJsonForms?.find?.(
        (error) =>
          [path, lastPath].includes(error?.params?.missingProperty) ||
          error?.instancePath === instancePath,
      );
      const additionalError = additionalErrors?.find?.(
        (error) =>
          [path, lastPath].includes(error?.params?.missingProperty) ||
          error?.instancePath === instancePath,
      );
      return errorJsonForms ?? additionalError;
    };

    const getErrorMessage = () => {
      const error = getError();
      return translateErrors?.(error) ?? error?.message ?? '';
    };

    return (
      <div>
        <Editor
          isDisabled={!isDisabled}
          placeholder='Digite algum texto aqui para começar...'
          initialValue={props.data}
          onBlur={handleBlur}
        />

        {validationMode === 'ValidateAndShow' && hasError() && (
          <span className='error-message'>{getErrorMessage()}</span>
        )}
      </div>
    );
  }),
};
