import { useParams } from 'react-router-dom';
import { Crud } from '../../../components/crud/crud';
import { useCrudContext } from '../../../components/crud/crud-context';
import { Data, headCells } from './historico-ajuste-valor-bolsa.headcell';
import historicoAjusteValorBolsaUiSchema from './historico-ajuste-valor-bolsa.uischema';

function HistoricoAjusteValorBolsa() {
  const { parameterFilters } = useCrudContext();
  const { nivelBolsaId } = useParams();
  const nivelBolsaIdNumber = Number(nivelBolsaId);
  const nivelBolsa = parameterFilters?.['nivelBolsa'];

  const defaultForm = {
    nivelBolsaId: nivelBolsaIdNumber,
    nivelBolsa: nivelBolsa,
  };

  return (
    <Crud<Data>
      titleConfig={{ value: 'Ajuste do Valor', show: true }}
      apiUrl={'/historico-ajuste-valor-bolsa'}
      headCells={headCells}
      uischema={historicoAjusteValorBolsaUiSchema}
      queryFilters={[
        { chave: 'nivelBolsaId', valor: nivelBolsaId },
        { chave: 'orderBy', valor: 'createdAt' },
        { chave: 'orderDirection', valor: 'DESC' },
      ]}
      defaultForm={defaultForm}
      hideView
      hideUpdate
      hideDelete
    ></Crud>
  );
}

export default HistoricoAjusteValorBolsa;

export const rootViews = ['modalidade-bolsa', 'nivel-bolsa'];
