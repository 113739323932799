import { Crud } from '../../components/crud/crud';
import { makeCustomRequiredIfBrasil, makePositiveNumberIfBrasil } from './ instituicao-errors';
import { Data, headCells } from './instituicao.headcell';
import instituicaoUiSchema from './instituicao.uischema';

function Instituicao() {
  const defaultForm = { 'paisId-codigo': 'brasil', endereco: {} };
  const customErrors = [
    makeCustomRequiredIfBrasil('cnpj'),
    makeCustomRequiredIfBrasil('endereco.bairro'),
    makeCustomRequiredIfBrasil('endereco.numero'),
    makePositiveNumberIfBrasil('endereco.numero'),
  ];

  return (
    <>
      <Crud<Data>
        titleConfig={{ value: 'Instituições', show: true }}
        headCells={headCells}
        apiUrl={'/instituicao'}
        defaultForm={defaultForm}
        customErrors={customErrors}
        uischema={instituicaoUiSchema}
        queryFilters={[{ chave: 'withDeleted', valor: true }]}
      ></Crud>
    </>
  );
}

export default Instituicao;
