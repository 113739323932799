import { FormApi } from '../../../api/generic-api';

const cronogramaDesembolsoUiSchemaFactory = ({ tesouro }: { tesouro: boolean }) => {
  const elements = [
    {
      type: 'MonetaryInput',
      scope: '#/properties/valor',
      label: 'Valor',
      options: {
        onlyCreate: true,
      },
    },
    {
      type: 'Date',
      scope: '#/properties/dataPrevista',
      label: 'Data Prevista',
      options: {
        onlyCreate: true,
      },
    },
    {
      type: 'Date',
      scope: '#/properties/dataEfetiva',
      label: 'Data Efetiva',
    },
  ];

  if (tesouro) {
    elements.unshift({
      type: 'Select',
      scope: '#/properties/tesouroId',
      label: 'Tesouro',
      options: {
        onlyCreate: true,
        filter: {
          handler: (formData: any, listData: any[], api: FormApi) => {
            return api.getAll([
              { chave: 'withDeleted', valor: 'false' },
              { chave: 'orderBy', valor: 'ano' },
              { chave: 'orderDirection', valor: 'desc' },
            ]);
          },
        },
      },
    });
  }

  return {
    type: 'VerticalLayout',
    elements,
  };
};

export default cronogramaDesembolsoUiSchemaFactory;
