import { HeadCell } from '../../../components/crud/protocols/head-cell';

export interface Data extends Record<string, any> {
  id: number;
  valor: number;
  dataPrevista: Date;
  dataEfetiva: Date;
  status: string;
  parcela: string;
  instituicaoConvenioId: number;
}

export const moduleName =
  'ORCAMENTO.CONVENIO.INSTITUICAO_CONVENIO.CRONOGRAMA_DESEMBOLSO.CRONOGRAMA_DESEMBOLSO';

export const headCells: readonly HeadCell<Data>[] = [
  {
    id: 'parcela',
    numeric: true,
    disablePadding: false,
    label: 'Parcela',
  },
  {
    id: 'dataPrevista',
    numeric: false,
    disablePadding: false,
    label: 'Data Prevista',
    dateFormat: 'DD/MM/YYYY',
  },
  {
    id: 'dataEfetiva',
    numeric: false,
    disablePadding: false,
    label: 'Data Efetiva',
    dateFormat: 'DD/MM/YYYY',
  },
  {
    id: 'valor',
    numeric: false,
    disablePadding: false,
    label: 'Valor da Parcela',
    mask: 'money',
  },
];
