import { HeadCell } from '../../../components/crud/protocols/head-cell';

export interface Data extends Record<string, any> {
  id: number;
  nome: string;
  sigla: number;
}

export const moduleName = 'BOLSA.MODALIDADE_DE_BOLSA.MODALIDADE_BOLSA';

export const moduleLabel = ' Modalidade de Bolsa';

export const displayProperties = ['nome'];

export const headCells: readonly HeadCell<Data>[] = [
  {
    id: 'nome',
    numeric: false,
    disablePadding: false,
    label: 'Nome',
  },
  {
    id: 'sigla',
    numeric: false,
    disablePadding: false,
    label: 'Sigla',
  },
];
