import { Data, headCells } from './termo-aceite.headcell';
import termoAceiteUiSchema from './termo-aceite.uischema';
import { Crud } from '../../../components/crud/crud';
import { makeTermoAceiteApi } from './termo-aceite-api';
import { customRequired } from '../../../helpers/custom-errors';

function TermoAceite() {
  const apiClient = makeTermoAceiteApi();
  const customErrors = [customRequired('termoDeAceite')];

  return (
    <Crud<Data>
      headCells={headCells}
      titleConfig={{ value: 'Termo de Aceite', show: true }}
      apiClient={apiClient}
      uischema={termoAceiteUiSchema}
      customErrors={customErrors}
      initView
    ></Crud>
  );
}

export default TermoAceite;
