import ClassicEditor from '@ckeditor/ckeditor5-build-classic';
import '@ckeditor/ckeditor5-build-classic/build/translations/pt-br';
import { CKEditor } from '@ckeditor/ckeditor5-react';
import './style/ckeditor.css';

interface EditorProps {
  isDisabled?: boolean;
  placeholder?: string;
  initialValue?: string;
  onBlur: (editorData: string) => void;
}

const Editor: React.FC<EditorProps> = ({ isDisabled, placeholder, initialValue, onBlur }) => {
  return (
    <CKEditor
      disabled={isDisabled}
      editor={ClassicEditor}
      config={{
        language: 'pt-br',
        placeholder: placeholder,
      }}
      data={initialValue ?? ''}
      onBlur={(event, editor) => {
        onBlur(editor?.getData?.());
      }}
    />
  );
};

export default Editor;
