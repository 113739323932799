import { HeadCell } from '../../../components/crud/protocols/head-cell';

export interface Data extends Record<string, any> {
  valorTotal: number;
  ano: string;
}

export const moduleName = 'ORCAMENTO.PROGRAMA.EDICAO_PROGRAMA.EDICAO_PROGRAMA.EXIBIR_NO_MENU';

export const displayProperties = ['ano'];

export const headCells: readonly HeadCell<Data>[] = [
  {
    id: 'ano',
    numeric: false,
    disablePadding: false,
    label: 'Ano',
  },
  {
    id: 'valorTotal',
    numeric: false,
    disablePadding: false,
    label: 'Soma de Recursos',
    mask: 'money',
  },
];
