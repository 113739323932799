import { HeadCell } from '../../../components/crud/protocols/head-cell';

export interface Data extends Record<string, any> {
  nome: string;
}

export const displayProperties = ['grandeAreaNome'];

export const moduleLabel = 'Área de Conhecimento';

export const moduleName = 'CONFIGURACOES_INICIAIS.AREA_DE_CONHECIMENTO';

export const rowsLength = 12;

export const headCells: readonly HeadCell<Data>[] = [
  {
    id: 'nome',
    numeric: false,
    disablePadding: false,
    label: 'Nome',
  },
];
