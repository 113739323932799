import { HeadCell } from '../../../components/crud/protocols/head-cell';

export interface Data extends Record<string, any> {
  valor: number;
  linhaDeAcaoId: number;
  recursoId: number;
  convenioId: number;
  tesouroId: number;
}

export const moduleName = 'ORCAMENTO.LINHA_DE_ACAO.EDICAO_LINHA_DE_ACAO.EDICAO_LINHA_DE_ACAO';

export const displayProperties = ['ano'];

export const headCells: readonly HeadCell<Data>[] = [
  {
    id: 'ano',
    numeric: false,
    disablePadding: false,
    label: 'Ano',
  },
  {
    id: 'valorTotal',
    numeric: false,
    disablePadding: false,
    label: 'Soma de Recursos',
    mask: 'money',
  },
  {
    id: 'valorAlocado',
    numeric: false,
    disablePadding: false,
    label: 'Valor Alocado',
    mask: 'money',
  },
];
