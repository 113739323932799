import { HeadCell } from '../../../components/crud/protocols/head-cell';

export interface Data extends Record<string, any> {
  id: number;
  nome: string;
  sigla: string;
  descricao: string;
}

export const moduleName = 'CONFIGURACOES_INICIAIS.DEPARTAMENTO_FUNDACAO';

export const moduleLabel = 'Departamentos da fundação';

export const displayProperties = ['nome'];

export const headCells: readonly HeadCell<Data>[] = [
  {
    id: 'nome',
    numeric: false,
    disablePadding: false,
    label: 'Nome do Departamento',
  },
  {
    id: 'sigla',
    numeric: false,
    disablePadding: false,
    label: 'Sigla',
  },
  {
    id: 'descricao',
    numeric: false,
    disablePadding: false,
    label: 'Descrição',
  },
];
