import { rankWith, uiTypeIs } from '@jsonforms/core';
import { withJsonFormsControlProps } from '@jsonforms/react';
import CalendarTodayOutlinedIcon from '@mui/icons-material/CalendarTodayOutlined';
import { Hidden, OutlinedInputProps } from '@mui/material';
import { DatePicker, DateView, LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import 'dayjs/locale/pt-br';
import dayjs, { Dayjs } from 'dayjs';
import React from 'react';
import { useCrudContext } from '../../components/crud/crud-context';
import { translateErrors } from '../../components/crud/i18n';
import './renderer.css';

const dateRenderTester = rankWith(5, uiTypeIs('Date'));

export const dateRender = {
  tester: dateRenderTester,
  renderer: withJsonFormsControlProps(({ visible = true, path, schema, uischema, ...props }) => {
    const fieldRef = React.useRef<HTMLDivElement>(null);
    const { errorsJsonForms, additionalErrors, validationMode, crudStates, disabledFields } =
      useCrudContext();

    const [value, setValue] = React.useState<string | null>(props.data ?? undefined);
    const [valueFormatted, setValueFormatted] = React.useState<Dayjs | null>(null);
    const dateFormatProperty = uischema?.options?.dateFormat;

    const getFormatDateString = (format: string) => {
      let formatDate = [];
      if (format.includes('YYYY')) {
        formatDate.push('YYYY');
      }
      if (format.includes('MM')) {
        formatDate.push('MM');
      }
      if (format.includes('DD')) {
        formatDate.push('DD');
      }
      if (formatDate.length === 0) {
        return 'YYYY-MM-DD';
      } else {
        return formatDate.join('-');
      }
    };

    React.useEffect(() => {
      value &&
        setValueFormatted(
          dayjs(
            value,
            dateFormatProperty
              ? getFormatDateString(dateFormatProperty.toUpperCase())
              : 'YYYY-MM-DD',
          ),
        );
    }, [value]);

    React.useEffect(() => {
      const dateDefaultProperty = uischema?.options?.dateDefault;
      const defaultValue = dateDefaultProperty === 'now' ? dayjs() : dateDefaultProperty;
      if (!value) {
        setValueFormatted(defaultValue);
        const strVal = defaultValue?.format(
          dateFormatProperty ? dateFormatProperty.toUpperCase() : 'YYYY-MM-DD',
        );
        setValue(strVal ?? null);
        props.handleChange(path, strVal);
      }
    }, []);

    React.useEffect(() => {
      fieldRef?.current?.childNodes?.forEach?.((childNode) => {
        const node = childNode as HTMLElement;
        const htmlInput = node.children[1]?.children[0];
        if (
          childNode.nodeType === 1 &&
          (node.tagName === 'INPUT' || htmlInput?.tagName === 'INPUT')
        ) {
          const inputElement = childNode as HTMLInputElement;
          if (inputElement && props.data !== undefined) {
            inputElement.value = props.data;
          } else if (inputElement) {
            inputElement.value = '';
          }
        }
      });
    }, [props.data]);

    const isDisabled: boolean =
      crudStates.view ||
      uischema?.options?.disabled ||
      (crudStates.edit && uischema?.options?.onlyCreate) ||
      disabledFields.includes(path);

    const hasError = () => {
      return ((props.required || uischema.options?.required) && !props.data) || !!getError();
    };

    const getError = () => {
      const instancePath = `/${path?.replace?.('.', '/')}`;
      const errorJsonForms = errorsJsonForms?.find?.(
        (error) => error?.params?.missingProperty === path || error?.instancePath === instancePath,
      );
      const additionalError = additionalErrors?.find?.(
        (error) => error?.params?.missingProperty === path || error?.instancePath === instancePath,
      );
      return errorJsonForms ?? additionalError;
    };

    const getErrorMessage = () => {
      const error = getError();
      return translateErrors?.(error) ?? error?.message ?? '';
    };

    const handleChange = (value: Dayjs | null) => {
      const strVal = value?.format(
        dateFormatProperty
          ? dateFormatProperty.toUpperCase().length < 10
            ? dateFormatProperty.toUpperCase()
            : 'YYYY-MM-DD'
          : 'YYYY-MM-DD',
      );
      setValue(strVal ?? null);
      props.handleChange(path, strVal);
    };

    const getViews = (dateFormat: string): DateView[] | undefined => {
      let views: DateView[] | undefined = [];
      if (dateFormat.includes('DD')) {
        views?.push('day');
      }
      if (dateFormat.includes('MM')) {
        views?.push('month');
      }
      if (dateFormat.includes('YYYY')) {
        views?.push('year');
      }
      if (views.length % 3 === 0) {
        views = undefined;
      }
      return views;
    };

    return (
      <Hidden xsUp={!visible}>
        <div className='custom-input-container' ref={fieldRef}>
          <LocalizationProvider dateAdapter={AdapterDayjs} adapterLocale='pt-br'>
            <DatePicker
              onChange={handleChange}
              defaultValue={valueFormatted}
              value={valueFormatted}
              disabled={isDisabled}
              className={`date-input
                          ${isDisabled && 'disabled-field'}
                          ${validationMode === 'ValidateAndShow' && hasError() && 'has-error'}
              `}
              slots={{ openPickerIcon: CalendarTodayOutlinedIcon }}
              slotProps={{
                textField: {
                  variant: 'filled',
                  className: 'input',
                  required: props.required,
                  InputProps: {
                    disableUnderline: true,
                  } as Partial<OutlinedInputProps>,
                },
              }}
              label={props.label}
              format={dateFormatProperty?.toUpperCase() ?? 'DD/MM/YYYY'}
              views={dateFormatProperty ? getViews(dateFormatProperty.toUpperCase()) : undefined}
            />
          </LocalizationProvider>
          {validationMode === 'ValidateAndShow' && hasError() && (
            <span className='error-message'>{getErrorMessage()}</span>
          )}
        </div>
      </Hidden>
    );
  }),
};
