import { rankWith, uiTypeIs } from '@jsonforms/core';
import { withJsonFormsControlProps } from '@jsonforms/react';
import { Box } from '@mui/material';
import React, { ChangeEvent } from 'react';
import { useCrudContext } from '../../components/crud/crud-context';

//Color picker provisório

const fileTester = rankWith(5, uiTypeIs('Color'));

export const FileRender = {
  tester: fileTester,
  renderer: withJsonFormsControlProps(({ schema, uischema, data, path, ...props }) => {
    const [value, setValue] = React.useState<string>(data ?? '');
    const { crudStates } = useCrudContext();

    React.useEffect(() => {
      setValue(data);
    }, []);

    const isDisabled =
      crudStates.view ||
      (crudStates.edit && uischema?.options?.onlyCreate) ||
      uischema?.options?.disabled;

    const handleChange = (event: ChangeEvent<HTMLInputElement>) => {
      const value = event?.target?.value;
      setValue(value);
      props.handleChange(path, value);
    };

    return (
      <Box sx={{ display: 'flex', gap: '5px', marginBottom: '10px' }}>
        <span>{props.label}: </span>
        <input type='color' onChange={handleChange} value={value} disabled={isDisabled} />
      </Box>
    );
  }),
};
