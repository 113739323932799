const tesouroUiSchema = {
  type: 'HorizontalLayout',
  elements: [
    {
      type: 'VerticalLayout',
      elements: [
        {
          type: 'Control',
          scope: '#/properties/ano',
        },
        {
          type: 'MonetaryInput',
          scope: '#/properties/valorPrometido',
          rule: { effect: 'HIDE', condition: {} },
        },
      ],
    },
    {
      type: 'VerticalLayout',
      elements: [
        {
          type: 'MonetaryInput',
          scope: '#/properties/valorPrevisto',
        },
        {
          type: 'MonetaryInput',
          scope: '#/properties/valorAlocado',
          rule: { effect: 'HIDE', condition: {} },
        },
      ],
    },
  ],
};

export default tesouroUiSchema;
