import { pascalCase } from 'change-case';
import { makeApi } from '../api/generic-api';
import { CrudContextData } from '../components/crud/crud-context';

export const createResource = async (ctx: CrudContextData, apiUrl: string) => {
  const { formData, setFormData, crudStates, parameterFilters, setParameterFilters } = ctx;
  const api = makeApi(apiUrl);
  const urlVar = `step${pascalCase(apiUrl?.replace?.('/', ''))}Id`;
  const isCreated = !!(
    formData.id &&
    parameterFilters?.[urlVar] &&
    Number(parameterFilters?.[urlVar]) === formData?.id
  );
  if (crudStates.add && !isCreated) {
    const postResponse = await api?.post?.(formData);
    if (postResponse.status === 201) {
      setParameterFilters({ ...parameterFilters, [urlVar]: postResponse?.data?.id });
      setFormData({ ...formData, ...postResponse.data });
      return true;
    } else {
      console.error(postResponse);
      return false;
    }
  }
  if (crudStates.edit) {
    const putResponse = await api?.put?.(formData?.id, formData);
    if (putResponse.status === 200) {
      return true;
    } else {
      console.error(putResponse);
      return false;
    }
  }
  return true;
};
