import { Paperclip } from '@styled-icons/foundation/Paperclip';
import { BuildingBankToolbox } from '@styled-icons/fluentui-system-regular/BuildingBankToolbox';
import { stepHandlerErrors } from '../../../utils/step-handler-errors';
import { CrudContextData } from '../../../components/crud/crud-context';
import { createResource } from '../../../utils/create-resource';
import { stepHandlerBack } from '../../../utils/step-handler-back';

const iconConfig = [
  {
    category: 'Convênio',
    icon: BuildingBankToolbox,
  },
  {
    category: 'Anexo',
    icon: Paperclip,
  },
];

const convenioUiSchema = {
  type: 'Categorization',
  elements: [
    {
      type: 'Category',
      label: 'Convênio',
      options: {
        stepHandler: {
          handler: stepHandlerErrors,
          callback: (ctx: CrudContextData) => createResource(ctx, '/convenio'),
        },
      },
      elements: [
        {
          type: 'VerticalLayout',
          elements: [
            {
              type: 'HorizontalLayout',
              elements: [
                {
                  type: 'Control',
                  scope: '#/properties/nome',
                  options: {
                    required: true,
                  },
                },
              ],
            },
            {
              type: 'HorizontalLayout',
              elements: [
                {
                  type: 'VerticalLayout',
                  elements: [
                    {
                      type: 'Select',
                      scope: '#/properties/statusConvenioId',
                      options: {
                        required: true,
                      },
                    },
                  ],
                },
                {
                  type: 'VerticalLayout',
                  elements: [
                    {
                      type: 'Select',
                      scope: '#/properties/tipoConvenioId',
                      options: {
                        required: true,
                      },
                    },
                  ],
                },
              ],
            },
            {
              type: 'HorizontalLayout',
              elements: [
                {
                  type: 'LongText',
                  scope: '#/properties/justificativa',
                },
              ],
            },
            {
              type: 'HorizontalLayout',
              elements: [
                {
                  type: 'VerticalLayout',
                  elements: [
                    {
                      type: 'Date',
                      scope: '#/properties/inicioVigencia',
                      label: 'Início da Vigência',
                      options: {
                        required: true,
                      },
                    },
                    {
                      type: 'Date',
                      scope: '#/properties/dataAssinatura',
                      label: 'Data da Assinatura',
                    },
                  ],
                },
                {
                  type: 'VerticalLayout',
                  elements: [
                    {
                      type: 'Date',
                      scope: '#/properties/terminoVigencia',
                      label: 'Término da Vigência',
                      options: {
                        required: true,
                      },
                    },
                    {
                      type: 'Date',
                      scope: '#/properties/dataPublicacao',
                      label: 'Data da Publicação',
                    },
                  ],
                },
              ],
            },
          ],
        },
      ],
    },
    {
      type: 'Category',
      label: 'Anexo',
      options: {
        stepHandler: {
          handler: stepHandlerBack,
          url: '/convenio',
        },
      },
      elements: [
        {
          type: 'VerticalLayout',
          elements: [
            {
              type: 'File',
              scope: '#/properties/convenioAnexo',
              options: {
                fileConfig: [
                  {
                    name: 'convenio',
                  },
                  {
                    name: 'convenioAnexo',
                  },
                ],
              },
            },
          ],
        },
      ],
    },
  ],
  options: {
    variant: 'stepper',
    showNavButtons: true,
    icons: iconConfig,
  },
};

export default convenioUiSchema;
