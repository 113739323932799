import { Crud } from '../../../components/crud/crud';
import { positiveNumber, whiteSpaces } from '../../../helpers/custom-errors';
import { Data, headCells } from './valor-diaria.headcell';
import diariasUiSchema from './valor-diaria.uischema';

function Diarias() {
  const customErrors = [
    whiteSpaces('descricao'),
    whiteSpaces('localidade'),
    positiveNumber('valorDiaria'),
  ];
  const defaultForm = { descricao: '', valorDiaria: 0 };

  return (
    <Crud<Data>
      headCells={headCells}
      titleConfig={{ value: 'Diárias', show: true }}
      apiUrl={'/valor-diaria'}
      uischema={diariasUiSchema}
      customErrors={customErrors}
      defaultForm={defaultForm}
      hideView
      queryFilters={[
        { chave: 'withDeleted', valor: true },
        { chave: 'orderBy', valor: 'nivelAcademico' },
        { chave: 'orderDirection', valor: 'asc' },
      ]}
    ></Crud>
  );
}

export default Diarias;
