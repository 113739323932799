import { circleFlagBuilder } from '../../components/circle-flag';

const showIfBrasil = {
  effect: 'HIDE',
  condition: {
    scope: '#/properties/paisId-codigo',
    schema: {
      not: {
        const: 'brasil',
      },
    },
  },
};

const hideIfBrasil = {
  effect: 'SHOW',
  condition: {
    scope: '#/properties/paisId-codigo',
    schema: {
      not: {
        const: 'brasil',
      },
    },
  },
};

const instituicaoUiSchema = {
  type: 'VerticalLayout',
  elements: [
    {
      type: 'HorizontalLayout',
      elements: [
        {
          type: 'Control',
          scope: '#/properties/nome',
          label: 'Nome',
        },
      ],
    },
    {
      type: 'HorizontalLayout',
      elements: [
        {
          type: 'VerticalLayout',
          elements: [
            {
              type: 'Control',
              scope: '#/properties/sigla',
              label: 'Sigla',
            },
            {
              type: 'Text',
              scope: '#/properties/endereco/properties/cep',
              label: 'Zipcode',
              rule: hideIfBrasil,
              options: {
                maxLength: 9,
              },
            },
            {
              type: 'Control',
              scope: '#/properties/cnpj',
              label: 'CNPJ',
              rule: showIfBrasil,
              options: {
                mask: '99.999.999/9999-99',
                required: true,
              },
            },
            {
              type: 'Control',
              scope: '#/properties/endereco/properties/estado',
              rule: showIfBrasil,
            },

            {
              type: 'Control',
              scope: '#/properties/endereco/properties/logradouro',
              label: 'Logradouro',
              rule: showIfBrasil,
            },
            {
              type: 'Control',
              scope: '#/properties/endereco/properties/numero',
              label: 'Número',
              rule: showIfBrasil,
              options: {
                required: true,
              },
            },
          ],
        },
        {
          type: 'VerticalLayout',
          elements: [
            {
              type: 'Select',
              scope: '#/properties/paisId',
              label: 'País',
              options: {
                // onlyCreate: true,
                iconBuilder: circleFlagBuilder,
              },
            },
            {
              type: 'Cep',
              scope: '#/properties/endereco/properties/cep',
              label: 'CEP',
              rule: showIfBrasil,
              options: {
                mask: '99999-999',
              },
            },
            {
              type: 'Control',
              scope: '#/properties/endereco/properties/municipio',
              label: 'Município',
              rule: showIfBrasil,
            },
            {
              type: 'Control',
              scope: '#/properties/endereco/properties/bairro',
              label: 'Bairro',
              rule: showIfBrasil,
              options: {
                required: true,
              },
            },
            {
              type: 'Control',
              scope: '#/properties/endereco/properties/complemento',
              label: 'Complemento',
              rule: showIfBrasil,
            },
          ],
        },
      ],
    },
    {
      type: 'HorizontalLayout',
      elements: [
        {
          type: 'VerticalLayout',
          elements: [
            {
              type: 'Control',
              scope: '#/properties/endereco/properties/estado',
              label: 'Estado/Região',
              rule: hideIfBrasil,
            },
          ],
        },
        {
          type: 'VerticalLayout',
          elements: [
            {
              type: 'Control',
              scope: '#/properties/endereco/properties/municipio',
              label: 'Município',
              rule: hideIfBrasil,
            },
          ],
        },
      ],
    },
    {
      type: 'HorizontalLayout',
      elements: [
        {
          type: 'VerticalLayout',
          elements: [
            {
              type: 'Control',
              scope: '#/properties/endereco/properties/logradouro',
              label: 'Logradouro',
              rule: hideIfBrasil,
            },
          ],
        },
      ],
    },
    {
      type: 'HorizontalLayout',
      elements: [
        {
          type: 'VerticalLayout',
          elements: [
            {
              type: 'Control',
              scope: '#/properties/endereco/properties/bairro',
              label: 'Bairro',
              rule: hideIfBrasil,
            },
          ],
        },
        {
          type: 'VerticalLayout',
          elements: [
            {
              type: 'Control',
              scope: '#/properties/endereco/properties/complemento',
              label: 'Complemento',
              rule: hideIfBrasil,
            },
          ],
        },
      ],
    },
  ],
};

export default instituicaoUiSchema;
