import { Calendar } from '@styled-icons/boxicons-regular/Calendar';
import { MoneyCalculator } from '@styled-icons/fluentui-system-filled/MoneyCalculator';
import { Business } from '@styled-icons/material-outlined/Business';
import { Attachment } from '@styled-icons/typicons/Attachment';
import { User } from 'styled-icons/boxicons-solid';
import { FormApi } from '../../../api/generic-api';
import { CrudContextData } from '../../../components/crud/crud-context';
import { createResource } from '../../../utils/create-resource';
import { stepHandlerBack } from '../../../utils/step-handler-back';
import { stepHandlerErrors } from '../../../utils/step-handler-errors';

const iconConfig = [
  {
    category: 'Termo Aditivo',
    icon: User,
  },
  {
    category: 'Aditivo de Prazo',
    icon: Calendar,
  },
  {
    category: 'Aditivo de Participantes',
    icon: Business,
  },
  {
    category: 'Aditivo de Recurso',
    icon: MoneyCalculator,
  },
  {
    category: 'Anexos',
    icon: Attachment,
  },
];

const termoAditivoConvenioUiSchema = {
  type: 'Categorization',
  elements: [
    {
      type: 'Category',
      label: 'Termo Aditivo',
      elements: [
        {
          type: 'VerticalLayout',
          elements: [
            {
              type: 'HorizontalLayout',
              elements: [
                {
                  type: 'Control',
                  scope: '#/properties/convenioId',
                  label: 'Convênio',
                  options: {
                    disabled: true,
                  },
                },
              ],
            },
            {
              type: 'HorizontalLayout',
              elements: [
                {
                  type: 'VerticalLayout',
                  elements: [
                    {
                      type: 'Control',
                      scope: '#/properties/codigo',
                      label: 'Código',
                      options: {
                        required: true,
                      },
                    },
                    {
                      type: 'Date',
                      scope: '#/properties/dataPublicacao',
                      label: 'Data de Publicação',
                      options: {
                        dateFormat: 'DD/MM/YYYY',
                        required: true,
                      },
                    },
                  ],
                },
                {
                  type: 'VerticalLayout',
                  elements: [
                    {
                      type: 'Select',
                      scope: '#/properties/tipoTermoAditivoId',
                      label: 'Tipo de Termo Aditivo',
                      options: {
                        required: true,
                      },
                    },
                    {
                      type: 'Date',
                      scope: '#/properties/dataAssinatura',
                      label: 'Data de Assinatura',
                      options: {
                        dateFormat: 'DD/MM/YYYY',
                        required: true,
                      },
                    },
                  ],
                },
              ],
            },
            {
              type: 'HorizontalLayout',
              elements: [
                {
                  type: 'LongText',
                  scope: '#/properties/objetivoDoTermo',
                  label: 'Objetivo do Termo',
                },
              ],
            },
          ],
        },
      ],
      options: {
        stepHandler: {
          handler: stepHandlerErrors,
        },
      },
    },
    {
      type: 'Category',
      label: 'Aditivo de Prazo',
      options: {
        stepHandler: {
          handler: stepHandlerErrors,
          callback: (ctx: CrudContextData) => createResource(ctx, '/termo-aditivo-convenio'),
        },
      },
      elements: [
        {
          type: 'VerticalLayout',
          elements: [
            {
              type: 'HorizontalLayout',
              elements: [
                {
                  type: 'VerticalLayout',
                  elements: [
                    {
                      type: 'Date',
                      scope: '#/properties/novoInicioVigente',
                      label: 'Novo Início da Vigência',
                      options: {
                        dateFormat: 'DD/MM/YYYY',
                        required: true,
                      },
                    },
                  ],
                },
                {
                  type: 'VerticalLayout',
                  elements: [
                    {
                      type: 'Date',
                      scope: '#/properties/novoTerminoVigente',
                      label: 'Novo Término da Vigência',
                      options: {
                        dateFormat: 'DD/MM/YYYY',
                        required: true,
                      },
                    },
                  ],
                },
              ],
            },
          ],
        },
      ],
      rule: {
        effect: 'SHOW',
        condition: {
          scope: '#/properties/tipoTermoAditivoId-codigo',
          schema: {
            const: 'aditivoPrazo',
          },
        },
      },
    },
    {
      type: 'Category',
      label: 'Aditivo de Participantes',
      options: {
        stepHandler: {
          handler: stepHandlerErrors,
          callback: (ctx: CrudContextData) => createResource(ctx, '/termo-aditivo-convenio'),
        },
      },
      elements: [
        {
          type: 'VerticalLayout',
          elements: [
            {
              type: 'HorizontalLayout',
              elements: [
                {
                  type: 'VerticalLayout',
                  elements: [
                    {
                      type: 'Select',
                      scope: '#/properties/instituicaoId',
                      label: 'Instituição',
                      options: {
                        filter: {
                          formFields: ['convenioId'],
                          handler: (formData: any, listData: any[], api: FormApi) => {
                            if (!formData.convenioId) return listData;
                            return api.getAll([
                              { chave: 'convenioId', valor: formData?.convenioId },
                            ]);
                          },
                        },
                      },
                    },
                    {
                      type: 'Control',
                      scope: '#/properties/porcentagemOutrosFins',
                      label: '% para outros fins',
                    },
                  ],
                },
                {
                  type: 'VerticalLayout',
                  elements: [
                    {
                      type: 'MonetaryInput',
                      scope: '#/properties/valorFirmado',
                      label: 'Valor firmado',
                    },

                    {
                      type: 'Select',
                      scope: '#/properties/tipoParceriaId',
                    },
                  ],
                },
              ],
            },
          ],
        },
      ],
      rule: {
        effect: 'SHOW',
        condition: {
          scope: '#/properties/tipoTermoAditivoId-codigo',
          schema: {
            const: 'aditivoParticipantes',
          },
        },
      },
    },
    {
      type: 'Category',
      label: 'Aditivo de Recurso',
      options: {
        stepHandler: {
          handler: stepHandlerErrors,
          callback: (ctx: CrudContextData) => createResource(ctx, '/termo-aditivo-convenio'),
        },
      },
      elements: [
        {
          type: 'VerticalLayout',
          elements: [
            {
              type: 'HorizontalLayout',
              elements: [
                {
                  type: 'VerticalLayout',
                  elements: [
                    {
                      type: 'Select',
                      scope: '#/properties/instituicaoConvenioId',
                      options: {
                        filter: {
                          formFields: ['convenioId'],
                          handler: (formData: any, listData: any[], api: FormApi) => {
                            if (!formData.convenioId) return listData;
                            return api.getAll([
                              { chave: 'convenioId', valor: formData?.convenioId },
                            ]);
                          },
                        },
                      },
                    },
                    {
                      type: 'MonetaryInput',
                      scope: '#/properties/instituicaoConvenio/properties/valorFinal',
                      label: 'Valor atual',
                      options: {
                        disabled: true,
                      },
                    },
                  ],
                },
                {
                  type: 'VerticalLayout',
                  elements: [
                    {
                      type: 'Select',
                      scope: '#/properties/tipoTermoDeRecurso',
                      label: 'Tipo',
                    },
                    {
                      type: 'MonetaryInput',
                      scope: '#/properties/valorAjuste',
                      label: 'Valor ajuste',
                    },
                  ],
                },
              ],
            },
          ],
        },
      ],
      rule: {
        effect: 'SHOW',
        condition: {
          scope: '#/properties/tipoTermoAditivoId-codigo',
          schema: {
            const: 'aditivoRecurso',
          },
        },
      },
    },
    {
      type: 'Category',
      label: 'Anexos',
      options: {
        stepHandler: {
          handler: stepHandlerBack,
        },
      },
      elements: [
        {
          type: 'VerticalLayout',
          elements: [
            {
              type: 'File',
              scope: '#/properties/termoAditivoConvenioAnexo',
              options: {
                fileConfig: [
                  {
                    name: 'termo-aditivo-convenio',
                  },
                  {
                    name: 'termo-aditivo-convenio-anexo',
                  },
                ],
              },
            },
          ],
        },
      ],
    },
  ],
  options: {
    variant: 'stepper',
    showNavButtons: true,
    icons: iconConfig,
  },
};

export default termoAditivoConvenioUiSchema;
