import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import DoneIcon from '@mui/icons-material/Done';
import EditIcon from '@mui/icons-material/Edit';
import { Button } from '@mui/material';
import React, { ReactNode } from 'react';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import { FormApi, makeApi } from '../../api/generic-api';
import { fixDate } from '../../helpers/fix-date';
import getRouteParam from '../../helpers/get-route-param';
import AlertCustom from './alert-custom';
import { CrudStatesOptions, useCrudContext } from './crud-context';
import DataTable, { Order } from './data-table/data-table';
import DeleteDialog from './delete-dialog';
import { makeErrorsManager } from './errors-manager';
import JsonForm from './json-form';
import Loading from './loading';
import { HeadCell } from './protocols/head-cell';
import './style/crud.css';
import { moduleNames } from '../../views';

export function Crud<T extends Record<string, any>>({
  headCells,
  checkboxes,
  titleConfig,
  uischema,
  apiUrl,
  apiClient,
  customErrors,
  hideView,
  hideDelete,
  hideUpdate,
  hideCreate,
  customActions,
  hideActions,
  initView,
  rowsLength,
  defaultForm,
  deleteMessageHandler,
  customTableHeader,
  tableData,
  queryFilters,
  customSave,
  formSize = { width: { value: 950 } },
}: CrudProps<T>) {
  const navigate = useNavigate();
  const location = useLocation();
  const routeParams = useParams();
  const routeId = Number(routeParams[getRouteParam(apiUrl)] ?? null) ?? null;
  const moduleName: string = moduleNames.find((x) => x.id === apiUrl?.substring(1))?.value ?? '';

  const [openDialog, setOpenDialog] = React.useState(false);
  const [api, setApi] = React.useState<FormApi | null>(null);

  const errorsManager = makeErrorsManager(useCrudContext());
  const { addCustomErrors, hasInsertedError, clearErrors, removeCustomErrors } = errorsManager;

  const {
    crudStates,
    updateCrudStates,
    errorsJsonForms,
    setErrorsJsonForm,
    setErrorsCustom,
    additionalErrors,
    validationMode,
    setValidationMode,
    apiListData,
    setApiListData,
    formData,
    setFormData,
    currentUiSchema,
    setCurrentUiSchema,
    openAlert,
    messageAlert,
    severityAlert,
    showError,
    showSuccess,
    schema,
    setSchema,
    load,
    setLoad,
    setDisabledFields,
    setCurrentTitle,
    setCurrentApiUrl,
    id,
    setId,
  } = useCrudContext();

  React.useEffect(() => {
    // Pega o id da rota e seta em visualização
    if (api && routeId > 0 && routeId !== id) {
      select(routeId);
    }
  }, [api, routeId]);

  React.useEffect(() => {
    // Seta o título para o breadcrumb pegar do contexto
    titleConfig?.value && setCurrentTitle(titleConfig.value);
    // Inicializa os erros customizados
    addCustomErrors();
  }, [schema]);

  React.useEffect(() => {
    if (apiClient) {
      setApi(apiClient);
    } else if (apiUrl && !api) {
      const apiInstance = makeApi(apiUrl);
      setApi(apiInstance as any);
    }
    setCurrentApiUrl((apiClient?.url ?? apiUrl)!);
  }, [apiUrl, api]);

  React.useEffect(() => {
    // Carrega o schema
    setLoad(true);
    api
      ?.getSchema?.()
      .then((schema) => {
        schema && setSchema(schema);
        setTimeout(() => setLoad(false), 200);
      })
      .catch((err) => {
        console.error(err);
        setLoad(false);
      });
    // Carrega os dados pra listagem
    if (crudStates.list) {
      setLoad(true);
      if (tableData) {
        setApiListData(tableData);
      } else {
        api
          ?.getAll?.(queryFilters)
          .then((data: any) => {
            setApiListData(data);
            setTimeout(() => setLoad(false), 200);
          })
          .catch((err) => {
            console.error(err);
            setLoad(false);
          });
      }
    }
  }, [api, crudStates.list]);

  // Pega os custom errors do uischema, dentro de options.customErrors
  React.useEffect(() => {
    const errors: any[] = customErrors || [];
    uischema?.elements?.forEach?.((el: any) => {
      if (el?.options?.customErrors) {
        const tokens: string[] = el?.scope?.split?.('/properties/');
        const path = tokens.filter((token) => token !== '#').join('.');
        for (const error of el?.options?.customErrors) {
          const errorToInsert = error(path);
          if (!hasInsertedError(errors, errorToInsert)) {
            errors.push(errorToInsert);
          }
        }
      }
    });
    setCurrentUiSchema(uischema);
    setErrorsCustom(errors);
  }, [uischema]);

  // Quando inicia no modo de visualização seta o primeiro elemento
  React.useEffect(() => {
    if (crudStates.view && initView) {
      let initialData = { ...(defaultForm || {}) };

      api?.getAll?.(queryFilters).then((data: any) => {
        if (Array.isArray(data) && data.length) {
          initialData = data[0] || {};
          setApiListData(data);
        } else if (data) {
          initialData = data;
          setApiListData([data]);
        }
        fixDate(initialData, schema);
        setFormData(initialData);
        setId(initialData?.id || 0);
      });
    }
  }, [api, crudStates.view, initView]);

  React.useEffect(() => {
    if (initView) {
      select(1);
    }
  }, [initView, id]);

  const onChangeJsonForms = (errorsFromJsonForm: any, dataFromJsonForm: any) => {
    setFormData(dataFromJsonForm);
    setErrorsJsonForm(errorsFromJsonForm);
    fixDate(formData, schema);
    removeCustomErrors();
  };

  const save = async () => {
    setValidationMode('ValidateAndShow');

    const additionalErrorsLocal = addCustomErrors();
    if (errorsJsonForms?.length || additionalErrorsLocal?.length) {
      return;
    }

    try {
      if (crudStates.add) {
        const response = await api?.post?.(formData);
        if (response.status === 201) {
          back();
          showSuccess('Adicionado com sucesso.');
        } else {
          console.error(response);
        }
      } else if (crudStates.edit) {
        const response = await api?.put?.(id, formData);
        if (response.status === 200) {
          if (initView) {
            back(CrudStatesOptions.VIEW);
          } else {
            back();
          }
          showSuccess('Editado com sucesso.');
        } else {
          console.error(response);
        }
      }
    } catch (error: any) {
      console.error(error);
      let errorMessage = error?.cause?.response?.data?.message || error?.response?.data?.message;
      if (Array.isArray(errorMessage)) errorMessage = errorMessage?.[0];
      showError(errorMessage || 'Ocorreu um erro.');
    }
  };

  const destroy = async () => {
    try {
      const response = await api?.delete?.(id);
      if (response.status === 200) {
        api?.getAll?.(queryFilters).then((data: any) => {
          setApiListData(data);
        });
        handleCloseDelete();
        showSuccess('Desativado com sucesso.');
      }
    } catch (error: any) {
      console.error(error);
      showError(error?.response?.data?.message?.[0] || 'Ocorreu um erro.');
    }
  };

  const back = (state = CrudStatesOptions.LIST) => {
    clearErrors();
    if (state === CrudStatesOptions.VIEW) {
      updateCrudStates(state);
    } else if (state === CrudStatesOptions.LIST) {
      updateCrudStates(state);
      setFormData({});
      setDisabledFields([]);
      const idToRemoveRegex = new RegExp(`/${id}$`);
      setId(-1);
      if (idToRemoveRegex.test(location.pathname)) {
        const newPath = location.pathname.replace(idToRemoveRegex, '');
        navigate(newPath);
      }
    }
  };

  const select = (newId: number) => {
    if (newId === id) return;
    setLoad(true);
    api
      ?.get?.(newId)
      .then((data: any) => {
        fixDate(data, schema);
        setFormData(data);
        setTimeout(() => setLoad(false), 200);
      })
      .catch((err) => {
        console.error(err);
        setLoad(false);
      });
    updateCrudStates(CrudStatesOptions.VIEW);
    setId(newId);
    const idRegex = new RegExp(`/${newId}$`);
    if (!idRegex.test(location.pathname)) navigate(`${location.pathname}/${newId}`);
  };

  const addView = () => {
    updateCrudStates(CrudStatesOptions.ADD);
    setFormData(defaultForm);
  };

  const editView = (id: number) => {
    setLoad(true);
    api
      ?.get?.(id)
      .then((data: any) => {
        fixDate(data, schema);
        setFormData(data);
        setTimeout(() => setLoad(false), 200);
      })
      .catch((err) => {
        console.error(err);
        setLoad(false);
      });
    setId(id);
    navigate(`${apiUrl}/${id}`);
    updateCrudStates(CrudStatesOptions.EDIT);
  };

  const restore = async (id: number) => {
    try {
      const response = await api?.restore?.(id);
      if (response?.status === 200) {
        api?.getAll?.(queryFilters).then((data: any) => {
          setApiListData(data);
        });
        showSuccess('Ativado com sucesso.');
      }
    } catch (error: any) {
      console.error(error);
      showError(error?.response?.data?.message?.[0] || 'Ocorreu um erro.');
    }
  };

  const handleOpenDelete = (id: number) => {
    setId(id);
    setOpenDialog(true);
  };

  const handleCloseDelete = () => {
    setOpenDialog(false);
    setTimeout(() => setId(-1), 100);
  };

  const findQueryFilter = (filter: string) => queryFilters?.find((value) => value.chave === filter);

  const order: Order<T> = {
    direction: findQueryFilter('orderDirection')?.valor.toLowerCase() ?? 'asc',
    attribute: findQueryFilter('orderBy')?.valor ?? 'id',
  };

  return (
    <>
      {load && <Loading isLoading={load} />}
      {!load && !crudStates.list && (
        <>
          {(!initView || crudStates.edit) && (
            <Button
              className='btn-back'
              onClick={() => {
                return initView ? back(CrudStatesOptions.VIEW) : back();
              }}
            >
              <ArrowBackIcon></ArrowBackIcon>
              Voltar
            </Button>
          )}
        </>
      )}
      {!load && (crudStates.add || crudStates.edit || crudStates.view) && titleConfig?.show && (
        <h2 className='text-center'>{titleConfig?.value}</h2>
      )}
      {!load && crudStates.list && !initView && (
        <DataTable
          headCells={headCells}
          rows={apiListData}
          checkboxes={checkboxes}
          title={titleConfig?.value}
          viewHandler={select}
          addHandler={addView}
          editHandler={editView}
          deleteHandler={handleOpenDelete}
          restoreHandler={restore}
          hideActions={hideActions}
          hideView={hideView}
          hideDelete={hideDelete}
          hideUpdate={hideUpdate}
          hideCreate={hideCreate}
          customActions={customActions}
          rowsLength={rowsLength}
          customTableHeader={customTableHeader}
          order={order}
          moduleName={moduleName}
        ></DataTable>
      )}
      {!load && !crudStates.list && (
        <>
          <JsonForm
            uischema={currentUiSchema}
            data={formData}
            onChange={({ errors, data }) => onChangeJsonForms(errors, data)}
            additionalErrors={additionalErrors}
            readonly={crudStates.view || false}
            validationMode={validationMode}
            formSize={formSize}
          ></JsonForm>
          <div className='container'>
            {crudStates.view && !hideUpdate && (
              <Button
                className='btn-edit'
                onClick={() => {
                  updateCrudStates(CrudStatesOptions.EDIT);
                }}
              >
                <EditIcon></EditIcon>
                Editar
              </Button>
            )}
            {(customSave || (crudStates.edit && !hideUpdate) || (crudStates.add && !hideCreate)) &&
              customSave?.show !== false && (
                <Button
                  className='btn-save'
                  onClick={() => {
                    if (customSave?.handler) customSave.handler(id, formData, save);
                    else save();
                  }}
                >
                  {customSave?.saveButton ?? (
                    <>
                      <DoneIcon />
                      Salvar
                    </>
                  )}
                </Button>
              )}
          </div>
        </>
      )}
      <DeleteDialog
        openDialog={openDialog}
        body={
          <>
            <b>
              {deleteMessageHandler
                ? `${deleteMessageHandler((apiListData as any)?.find((i: any) => i?.id === id))}`
                : ''}
            </b>
          </>
        }
        handleCloseDelete={handleCloseDelete}
        destroy={destroy}
      ></DeleteDialog>
      <AlertCustom open={openAlert} severity={severityAlert} message={messageAlert}></AlertCustom>
    </>
  );
}

export type CrudProps<T> = {
  headCells: readonly HeadCell<T>[];
  checkboxes?: boolean;
  titleConfig?: { value: string; show: boolean };
  uischema?: any;
  apiUrl?: string;
  customErrors?: any;
  hideView?: boolean;
  hideDelete?: boolean;
  hideUpdate?: boolean;
  hideCreate?: boolean;
  hideActions?: boolean;
  initView?: boolean;
  defaultForm?: any;
  deleteMessageHandler?: Function;

  // Qtd de linhas do DataTable
  rowsLength?: number;

  // Altera o client default pelo fornecido
  apiClient?: FormApi;

  // Ações customizadas do DataTable
  customActions?: ((
    row: any,
  ) => { action: JSX.Element; handler: (row: any) => Promise<void> | void } | undefined)[];

  // Botão e ação de salvar custom (objeto com botão e handler ou apenas função de handler)
  customSave?: {
    show?: boolean;
    saveButton?: JSX.Element;
    handler?: (id: number, formData: any, defaultSave: () => Promise<void>) => Promise<void>;
  };

  // Substitui o Header do DataTable
  customTableHeader?: ReactNode;

  // Substitui os dados default do DataTable
  tableData?: any[];

  // Filtros enviados pela url (query params)
  queryFilters?: { chave: string; valor: any }[];

  // Ajusta o tamanho do formulário em px e pode adicionar scroll tanto no eixo x quanto no y se necessário
  formSize?: {
    width?: { value: number; scrollable?: boolean };
    height?: { value: number; scrollable?: boolean };
  };
};
