const modalidadeBolsaUiSchema = {
  type: 'VerticalLayout',
  elements: [
    {
      type: 'Control',
      scope: '#/properties/nome',
    },
    {
      type: 'Control',
      scope: '#/properties/sigla',
    },
  ],
};

export default modalidadeBolsaUiSchema;
