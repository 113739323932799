const edicaoProgramaUiSchema = {
  type: 'VerticalLayout',
  elements: [
    {
      type: 'Date',
      scope: '#/properties/ano',
      label: 'Ano',
      options: {
        dateDefault: 'now',
        dateFormat: 'yyyy',
      },
    },
    {
      type: 'Control',
      scope: '#/properties/valorTotal',
      rule: {
        effect: 'HIDE',
        condition: {},
      },
    },
  ],
};

export default edicaoProgramaUiSchema;
