import LogoLedes from './images/logo-ledes.png';
import { layoutUrlFor } from '../../helpers/layout-url-for';
import { useAuthContext } from '../../views/login/auth/auth-context';

export default function LogoFundacao({
  src,
  ...props
}: React.DetailedHTMLProps<React.ImgHTMLAttributes<HTMLImageElement>, HTMLImageElement>) {
  const { configuracoesFundacao } = useAuthContext();
  const pathLogoCabecalho = configuracoesFundacao?.logoCabecalho
    ? layoutUrlFor(configuracoesFundacao.logoCabecalho.nome)
    : LogoLedes;

  return <img src={pathLogoCabecalho} {...props} />;
}
