import { rankWith, uiTypeIs } from '@jsonforms/core';
import { withJsonFormsControlProps } from '@jsonforms/react';
import { Box, Hidden, LinearProgress, LinearProgressProps, Typography } from '@mui/material';
import { CloudUpload } from '@styled-icons/bootstrap/CloudUpload';
import { FiletypeDoc } from '@styled-icons/bootstrap/FiletypeDoc';
import { FiletypeJpg } from '@styled-icons/bootstrap/FiletypeJpg';
import { FiletypePdf } from '@styled-icons/bootstrap/FiletypePdf';
import { FiletypeXls } from '@styled-icons/bootstrap/FiletypeXls';
import { Trash3 } from '@styled-icons/bootstrap/Trash3';
import { Close } from '@styled-icons/ionicons-outline/Close';
import React, { useEffect, useState } from 'react';
import { FileApi } from '../../api/file-api';
import { useCrudContext } from '../../components/crud/crud-context';
import { cropString } from '../../helpers/crop-string';
import { kebabCase } from 'change-case';
import './file-render.css';

const fileTester = rankWith(5, uiTypeIs('File'));

export const FileRender = {
  tester: fileTester,
  renderer: withJsonFormsControlProps(
    ({ visible = true, schema, uischema, path, data, ...props }) => {
      const { formData, crudStates, disabledFields, parameterFilters, showError, currentApiUrl } =
        useCrudContext();
      const [files, setFiles] = useState<File[]>(data?.anexos || []);
      const [fileProgress, setFileProgress] = useState<{ [key: string]: number }>({});
      const anexoInfo = (schema as any)?.anexoInfo;
      const filePath = (schema as any)?.type === 'object' ? 'anexo' : kebabCase(path);
      const category = (schema as any)?.type === 'object' ? kebabCase(path) : '';

      const fileApi = new FileApi();

      const fetchFilesFromApi = async () => {
        try {
          const response = await fileApi.getFile(currentApiUrl, filePath, formData?.id);

          if (response.status === 200) {
            const apiFiles = response.data.map((filesData: any) => ({
              name: filesData.nome,
              size: filesData.tamanho,
              type: filesData.mimeType,
              category: filesData.categoria,
              id: filesData.id,
            }));
            const fileProgress = apiFiles.reduce((progress: any, file: any) => {
              progress[file.name] = 100;
              return progress;
            }, {});

            setFiles(apiFiles);
            setFileProgress(fileProgress);
          } else {
            console.error('Erro ao obter arquivos da API:', response);
          }
        } catch (error) {
          console.error('Erro durante a solicitação à API para obter arquivos:', error);
        }
      };

      useEffect(() => {
        if (crudStates.view || crudStates.edit) fetchFilesFromApi();
      }, [formData?.id, path, crudStates]);

      const uploadFile = async (file: File) => {
        try {
          await fileApi.addFile(
            file,
            setFileProgress,
            currentApiUrl,
            filePath,
            formData.id,
            category,
          );

          fetchFilesFromApi();
        } catch (error) {
          console.error('Erro durante o upload:', error);
        }
      };

      const handleFileChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        const uploadedFile = event.target.files;
        if (uploadedFile) {
          const updatedFiles = Array.from(uploadedFile);
          const anexoSize = anexoInfo?.maxFileSize;
          const allowedMimeTypes = anexoInfo?.mimeTypeGroups.flatMap((x: any) =>
            Object.values(x.mimeTypes),
          );

          updatedFiles.forEach((file) => {
            if (file.size <= anexoSize && allowedMimeTypes.includes(file.type)) {
              if (!fileProgress[file.name] || fileProgress[file.name] < 100) {
                setFiles((prevFiles) => [...prevFiles, file]);
                uploadFile(file);
              }
            } else {
              if (file.size > anexoSize) {
                showError(`Tamanho máximo de anexo é ${anexoSize / 1024 / 1024} MB`);
              } else {
                showError(
                  `O anexo deve ser ${anexoInfo?.mimeTypeGroups
                    ?.map((x: any) => x.title)
                    .join(', ')}`,
                );
              }
            }
          });
        }
      };

      const handleDrop = (event: React.DragEvent<HTMLDivElement>) => {
        event.preventDefault();
        const droppedFile = event.dataTransfer.files;
        if (droppedFile) {
          const updatedFiles = Array.from(droppedFile);
          const anexoSize = anexoInfo?.maxFileSize;

          updatedFiles.forEach((file) => {
            if (file.size <= anexoSize && anexoInfo?.mimeTypes.includes(file.type)) {
              setFiles((prevFiles) => [...prevFiles, file]);
              uploadFile(file);
            } else {
              if (file.size > anexoSize) {
                showError(`Tamanho máximo de anexo é  + ${anexoSize / 1024 / 1024} MB`);
              } else {
                showError(
                  `O anexo deve ser ${anexoInfo?.mimeTypeGroups
                    ?.map((x: any) => x.title)
                    .join(', ')}`,
                );
              }
            }
          });
        }
      };

      const handleRemoveFile = async (index: number) => {
        const fileToRemove = files[index] as any;
        if (fileToRemove?.id) {
          try {
            const fileId = anexoInfo.categories ? fileToRemove?.category : fileToRemove?.id;
            await fileApi.removeFile(currentApiUrl, filePath, fileId, formData?.id);
          } catch (error) {
            console.error('Erro ao excluir arquivo:', error);
            return;
          }
        }
        setFiles((prevFiles) => prevFiles.filter((_, i) => i !== index));

        setFileProgress((prevProgress) => {
          const updatedProgress = { ...prevProgress };
          delete updatedProgress[fileToRemove.name];
          return updatedProgress;
        });
      };

      const isDisabled =
        crudStates.view ||
        (crudStates.edit && uischema?.options?.onlyCreate) ||
        uischema?.options?.disabled ||
        disabledFields.includes(path);

      const getFileTypeClass = (file: File) => {
        switch (file.type) {
          case 'application/pdf':
            return 'pdf-file';
          case 'application/msword':
          case 'application/vnd.openxmlformats-officedocument.wordprocessingml.document':
            return 'doc-file';
          case 'application/vnd.ms-excel':
          case 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet':
            return 'xls-file';
          default:
            return 'jpeg-file';
        }
      };

      const fileStyles = {
        'pdf-file': { color: '#C41414', icon: <FiletypePdf size={20} color={'#C41414'} /> },
        'doc-file': { color: '#064379', icon: <FiletypeDoc size={20} color={'#C41414'} /> },
        'xls-file': { color: '#29AB45', icon: <FiletypeXls size={20} color={'#29AB45'} /> },
        'jpeg-file': { color: 'EA74C9', icon: <FiletypeJpg size={20} color={'#EA74C9'} /> },
      };

      function LinearProgressWithLabel(props: LinearProgressProps & { value: number }) {
        return (
          <Box sx={{ display: 'flex', alignItems: 'center' }}>
            <Box sx={{ width: '100%', mr: 1 }}>
              <LinearProgress variant='determinate' {...props} />
            </Box>
            <Box sx={{ minWidth: 35 }}>
              <Typography style={{ color: '#1E90FF' }}>{`${Math.round(props.value)}%`}</Typography>
            </Box>
          </Box>
        );
      }

      return (
        <Hidden xsUp={!visible}>
          <Box className={`file-render ${isDisabled && 'disabled-field'}`}>
            <div
              className={'document-uploader'}
              onDrop={handleDrop}
              onDragOver={(event) => event.preventDefault()}
            >
              <div className='upload-info'>
                <div className='upload-phrase'>
                  <CloudUpload className='cloud-icon' />
                  <div>
                    <p>
                      Arraste e solte seu arquivo ou{' '}
                      <label
                        className={`choose-file ${isDisabled && 'disabled-field'}`}
                        htmlFor='browse'
                      >
                        Procure
                      </label>
                    </p>
                    <p className='accepted-file'>
                      Formato(s) aceito(s):{' '}
                      {anexoInfo?.mimeTypeGroups?.map((x: any) => x.title).join(', ')}
                    </p>
                  </div>
                </div>
              </div>
              <input
                type='file'
                hidden
                id='browse'
                onChange={handleFileChange}
                accept={anexoInfo?.mimeTypeGroups
                  ?.flatMap((x: any) => Object.entries(x.mimeTypes))
                  .map(([x]: [string, any]) => `.${x}`)
                  .join(', ')}
                multiple
                disabled={isDisabled}
              />
            </div>

            {files.map((file, index) => (
              <div className={`file-item ${getFileTypeClass(file)}`} key={index}>
                <div className={`border-icon ${getFileTypeClass(file)}`}>
                  <Box>{fileStyles[getFileTypeClass(file)].icon}</Box>
                </div>
                {fileProgress[file.name] && fileProgress[file.name] === 100 ? (
                  <div className='file-group'>
                    <div className='file-info'>
                      <p>{cropString(file.name, 38)}</p>
                    </div>
                    <p className='file-upload-size'>
                      {(file.size / 1024 / 1024).toPrecision(1)} mB
                    </p>
                    <Trash3 className='trash-icon' onClick={() => handleRemoveFile(index)} />
                  </div>
                ) : (
                  <div className='file-upload-group'>
                    <div className='file-info'>
                      <p>{cropString(file.name, 38)}</p>
                      <Close className='close-icon' onClick={() => handleRemoveFile(index)} />
                    </div>
                    <LinearProgressWithLabel value={fileProgress[file.name] || 0} />
                  </div>
                )}
              </div>
            ))}
          </Box>
        </Hidden>
      );
    },
  ),
};
