const indicadorProducaoUiSchema = {
  type: 'VerticalLayout',
  elements: [
    {
      label: 'Nome',
      type: 'Control',
      scope: '#/properties/nome',
    },
    {
      label: 'Nacional e Internacional',
      type: 'Control',
      scope: '#/properties/nacionalInternacional',
    },
    {
      type: 'Control',
      scope: '#/properties/producao',
      label: 'Produção',
      required: false,
      options: {
        detail: {
          type: 'HorizontalLayout',
          elements: [
            {
              type: 'Control',
              scope: '#/properties/texto',
              label: 'Texto da Produção',
            },
          ],
        },
      },
    },
  ],
};

export default indicadorProducaoUiSchema;
