import { HeadCell } from '../../../components/crud/protocols/head-cell';

export interface Data extends Record<string, any> {
  id: number;
  descricao: string;
  localidade?: string;
  nivelAcademicoId: string;
  moedaId: string;
  valorDiaria: number;
}

export const displayProperties = ['descricao', 'localidade'];

export const moduleLabel = 'Diárias';

export const moduleName = 'CONFIGURACOES_INICIAIS.VALOR_DIARIA';

export const headCells: readonly HeadCell<Data>[] = [
  {
    id: 'nivelAcademicoId',
    numeric: false,
    disablePadding: false,
    label: 'Nível Acadêmico',
    resource: {
      name: 'nivelAcademico',
      target: 'tipo',
    },
  },
  {
    id: 'localidade',
    numeric: false,
    disablePadding: false,
    label: 'Localidade',
  },
  {
    id: 'descricao',
    numeric: false,
    disablePadding: false,
    label: 'Descrição',
  },
  {
    id: 'moedaId',
    numeric: false,
    disablePadding: false,
    label: 'Moeda',
    resource: {
      name: 'moeda',
      target: 'nome',
    },
  },
  {
    id: 'valorDiaria',
    numeric: false,
    disablePadding: false,
    label: 'Valor Diária',
    mask: 'money',
  },
];
