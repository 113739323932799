import { authStringsFor } from '../../helpers/auth-strings-for';
import { useAuthContext } from '../../views/login/auth/auth-context';

export interface ISecureProps extends React.PropsWithChildren<{}> {
  permission: string;
}

export const Secure = ({ permission, children }: ISecureProps) => {
  const { permissions } = useAuthContext();
  const hasPermission = !permission || authStringsFor(permission).some((x) => permissions.includes(x));

  return hasPermission ? <>{children}</> : null;
};
