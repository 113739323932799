import { rankWith, schemaMatches, uiTypeIs } from '@jsonforms/core';
import { withJsonFormsControlProps } from '@jsonforms/react';
import { Hidden, OutlinedInputProps, TextField } from '@mui/material';
import { isEmpty } from 'lodash';
import React, { ChangeEvent } from 'react';
import { withMask } from 'use-mask-input';
import { useCrudContext } from '../../components/crud/crud-context';
import { translateErrors } from '../../components/crud/i18n';
import './renderer.css';

const isNumberRender = schemaMatches(
  (schema) => !isEmpty(schema) && (schema as any)?.type === 'integer' && !(schema as any)?.format,
);

const numberRenderTester = rankWith(5, isNumberRender);

export const numberRender = {
  tester: numberRenderTester,
  renderer: withJsonFormsControlProps(({ visible = true, path, schema, uischema, ...props }) => {
    const fieldRef = React.useRef<HTMLDivElement>(null);
    const inputRef = React.useRef<HTMLInputElement>(null);
    const position = React.useRef({
      beforeStart: 0,
      beforeEnd: 0,
    });

    const [value, setValue] = React.useState<string>(props.data ?? undefined);

    const { errorsJsonForms, additionalErrors, validationMode, crudStates, disabledFields } =
      useCrudContext();

    // keep cursor position after setting value
    React.useLayoutEffect(() => {
      inputRef?.current?.setSelectionRange(
        position.current.beforeStart,
        position.current.beforeEnd,
      );
    }, [value]);

    React.useEffect(() => {
      fieldRef?.current?.childNodes?.forEach?.((childNode) => {
        const node = childNode as HTMLElement;
        const htmlInput = node.children[1]?.children[0];
        if (
          childNode.nodeType === 1 &&
          (node.tagName === 'INPUT' || htmlInput?.tagName === 'INPUT')
        ) {
          const inputElement = childNode as HTMLInputElement;
          if (inputElement && props.data !== undefined) {
            inputElement.value = props.data;
          } else if (inputElement) {
            inputElement.value = '';
          }
        }
      });
      setValue(props.data);
    }, [props.data]);

    const isDisabled: boolean =
      crudStates.view ||
      uischema?.options?.disabled ||
      (crudStates.edit && uischema?.options?.onlyCreate) ||
      disabledFields.includes(path);

    const hasError = () => {
      return ((props.required || uischema.options?.required) && !props.data) || !!getError();
    };

    const getError = () => {
      const instancePath = `/${path?.replace?.('.', '/')}`;
      const errorJsonForms = errorsJsonForms?.find?.(
        (error) => error?.params?.missingProperty === path || error?.instancePath === instancePath,
      );
      const additionalError = additionalErrors?.find?.(
        (error) => error?.params?.missingProperty === path || error?.instancePath === instancePath,
      );
      return errorJsonForms ?? additionalError;
    };

    const getErrorMessage = () => {
      const error = getError();
      return translateErrors?.(error) ?? error?.message ?? '';
    };

    const handleChange = (event: ChangeEvent<HTMLInputElement>) => {
      const beforeStart = event.target.selectionStart ?? 0;
      const beforeEnd = event.target.selectionEnd ?? 0;
      position.current = {
        beforeStart,
        beforeEnd,
      };

      const val = event?.target?.value?.trim?.();
      const numValue = val ? Number(val) : undefined;
      props.handleChange(path, numValue);
      setValue(val);
    };

    return (
      <Hidden xsUp={!visible}>
        <div className='custom-input-container' ref={fieldRef}>
          <TextField
            {...props}
            type='number'
            variant='filled'
            className={`input
              ${isDisabled && 'disabled-field'}
              ${hasError() && validationMode === 'ValidateAndShow' && 'has-error'}
            `}
            label={props.label}
            required={props.required || uischema?.options?.required}
            disabled={isDisabled}
            value={value}
            inputRef={uischema?.options?.mask ? withMask(uischema.options.mask) : undefined}
            onChange={handleChange}
            InputProps={{ disableUnderline: true } as Partial<OutlinedInputProps>}
          />
          {validationMode === 'ValidateAndShow' && hasError() && (
            <span className='error-message'>{getErrorMessage()}</span>
          )}
        </div>
      </Hidden>
    );
  }),
};
