import { rankWith, schemaMatches, uiTypeIs } from '@jsonforms/core';
import { withJsonFormsControlProps } from '@jsonforms/react';
import { Hidden, InputBaseComponentProps, TextField } from '@mui/material';
import { isEmpty } from 'lodash';
import React, { ChangeEvent } from 'react';
import { withMask } from 'use-mask-input';
import { useCrudContext } from '../../components/crud/crud-context';
import { translateErrors } from '../../components/crud/i18n';
import './renderer.css';

const isTextRender =
  schemaMatches(
    (schema) =>
      !isEmpty(schema) &&
      (schema as any)?.type === 'string' &&
      !(schema as any)?.enum &&
      !(schema as any)?.format,
  ) || uiTypeIs('Text');

const textRenderTester = rankWith(5, isTextRender);

export const textRender = {
  tester: textRenderTester,
  renderer: withJsonFormsControlProps(({ visible = true, path, schema, uischema, ...props }) => {
    const fieldRef = React.useRef<HTMLDivElement>(null);
    const position = React.useRef({
      beforeStart: 0,
      beforeEnd: 0,
    });

    const [value, setValue] = React.useState<string>(props.data ?? '');
    const [maxLength, setMaxLength] = React.useState<number | null>(null);
    const [minLength, setMinLength] = React.useState<number | null>(null);
    const { errorsJsonForms, additionalErrors, validationMode, crudStates, disabledFields } =
      useCrudContext();

    React.useEffect(() => {
      // Pega min e max do schema
      if (schema?.minLength) {
        setMinLength(schema?.minLength);
      }
      if (schema?.maxLength) {
        setMaxLength(schema?.maxLength);
      }
      // Sobrescreve min e max do options do uischema
      if (uischema?.options?.minLength) {
        setMinLength(Number(uischema?.options?.minLength));
      }
      if (uischema?.options?.maxLength) {
        setMaxLength(Number(uischema?.options?.maxLength));
      }
    }, []);

    React.useEffect(() => {
      // Atualiza o valor do texto de acordo com o que vem do json forms (props.data)
      fieldRef?.current?.childNodes?.forEach?.((childNode) => {
        const node = childNode as HTMLElement;
        const htmlInput = node.children[1]?.children[0];
        if (
          childNode.nodeType === 1 &&
          (node.tagName === 'INPUT' || htmlInput?.tagName === 'INPUT')
        ) {
          const inputElement = childNode as HTMLInputElement;
          if (inputElement && props.data !== undefined) {
            inputElement.value = props.data;
          } else if (inputElement) {
            inputElement.value = '';
          }
          inputElement.click();
        }
      });
      setValue(props.data);
    }, [props.data]);

    const isDisabled =
      crudStates.view ||
      (crudStates.edit && uischema?.options?.onlyCreate) ||
      uischema?.options?.disabled ||
      disabledFields.includes(path);

    const hasError = () => {
      return ((props.required || uischema.options?.required) && !props.data) || !!getError();
    };

    const getError = () => {
      const instancePath = `/${path?.replace?.('.', '/')}`;
      const pathSplitted = path.split('.');
      const lastPath = pathSplitted[pathSplitted.length - 1];
      const errorJsonForms = errorsJsonForms?.find?.(
        (error) =>
          [path, lastPath].includes(error?.params?.missingProperty) ||
          error?.instancePath === instancePath,
      );
      const additionalError = additionalErrors?.find?.(
        (error) =>
          [path, lastPath].includes(error?.params?.missingProperty) ||
          error?.instancePath === instancePath,
      );
      return errorJsonForms ?? additionalError;
    };

    const getErrorMessage = () => {
      const error = getError();
      return translateErrors?.(error) ?? error?.message ?? '';
    };

    const handleChange = (event: ChangeEvent<HTMLInputElement>) => {
      const beforeStart = event.target.selectionStart ?? 0;
      const beforeEnd = event.target.selectionEnd ?? 0;
      position.current = {
        beforeStart,
        beforeEnd,
      };

      const val = event?.target?.value;
      setValue(val);
      props.handleChange(path, val.trim());
    };

    const inputProps: InputBaseComponentProps = {
      maxLength: maxLength ?? undefined,
      minLength: minLength ?? undefined,
    };

    return (
      <Hidden xsUp={!visible} key={`${path}-text`}>
        <div id={path} className='custom-input-container' ref={fieldRef}>
          <TextField
            variant='filled'
            className={`input
              ${isDisabled ? 'disabled-field' : ''}
              ${hasError() && validationMode === 'ValidateAndShow' ? 'has-error' : ''}
            `}
            label={props.label}
            required={props.required || uischema.options?.required}
            disabled={isDisabled}
            value={value}
            inputRef={uischema?.options?.mask ? withMask(uischema.options.mask) : undefined}
            onChange={handleChange}
            inputProps={inputProps}
          />
          {validationMode === 'ValidateAndShow' && hasError() && (
            <span className='error-message'>{getErrorMessage()}</span>
          )}
        </div>
      </Hidden>
    );
  }),
};
