// styledComponents.js
import styled from '@emotion/styled';
import { Box, Button, Divider, List, ListItem, ListItemButton, Typography } from '@mui/material';

// Define and export the styled components
export const CustomButton = styled(Button)({
  width: '180px',
  height: '36px',
  borderRadius: '4px',
  padding: '10px, 16px, 10px, 16px',
  backgroundColor: '#003D92',
  color: 'white',
  fontSize: '12px',
  '&:hover': {
    backgroundColor: '#0069d9',
    borderColor: '#0062cc',
    boxShadow: 'none',
  },
  '&:active': {
    boxShadow: 'none',
    backgroundColor: '#0062cc',
    borderColor: '#005cbf',
  },
  '&:focus': {
    boxShadow: '0 0 0 0.2rem rgba(0,123,255,.5)',
  },
});

export const ComponentBox = styled(Box)({
  width: '100%',
  maxWidth: 1219,
  fontFamily: 'Raleway',
  backgroundColor: 'white !important',
});

export const Header = styled('div')({
  marginLeft: '20px',
});

export const Search = styled('div')({
  padding: '20px',
  paddingTop: 0,
  marginBottom: '20px',
});

export const WidthFull = styled(List)({
  width: '100%',
  backgroundColor: 'white !important',
  '&:hover': {},
});

export const ListItemEdital = styled(List)({
  width: '100%',
  backgroundColor: 'white !important',
  marginBottom: '12px',
  marginTop: '10px',
  '&:hover': {},
});

export const WidthFullBox = styled(Box)({
  width: '100%',
  backgroundColor: 'white !important',
  '&:hover': {},
});

export const WidthFullListItem = styled(ListItem)({
  width: '100%',
  backgroundColor: 'white !important',
  '&:hover': {},
});

export const ListItemEditalName = styled(Typography)({
  fontSize: '16px',
  fontWeight: 600,
  fontFamily: 'Raleway',
  backgroundColor: 'white !important',
  '&:hover': {},
});

export const BoxFlex = styled(Box)({
  display: 'flex',
  alignItems: 'center',
  gap: 2,
});

export const ButtonLabel = styled('span')({
  marginLeft: 4,
});
