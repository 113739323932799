import {
  Avatar,
  Box,
  Checkbox,
  Container,
  IconButton,
  InputBase,
  List,
  ListItem,
  ListItemButton,
  ListItemIcon,
  ListItemText,
  Paper,
  Tooltip,
} from '@mui/material';
import React, { useEffect, useRef, useState } from 'react';
import { TrashFill } from 'styled-icons/bootstrap';
import { ArrowBack, SearchAlt } from 'styled-icons/boxicons-regular';
import { Inbox } from 'styled-icons/boxicons-solid';
import { Circle, MailInboxCheckmark, MailRead } from 'styled-icons/fluentui-system-filled';
import { ArrowBackIos, ArrowForwardIos, Notifications } from 'styled-icons/material-rounded';
import { Reload } from 'styled-icons/open-iconic';
import { INotificacaoApi, makeNotificacaoApi } from '../../../api/notificacao-api';
import { useCrudContext } from '../../../components/crud/crud-context';
import { translateAction } from '../../../components/crud/i18n';
import dayjs from '../../../components/dayjs/sig-dayjs';
import { useAuthContext } from '../../login/auth/auth-context';
import { Notificacao, useNotificacaoContext } from './notificacao-context';
import { BorderBottom, BorderLeft } from '@mui/icons-material';

const prioridades = [
  {
    value: 0,
    name: 'URGENTE',
  },
  {
    value: 1,
    name: 'ATENÇÃO',
  },
  {
    value: 2,
    name: 'NORMAL',
  },
];

interface ICategory {
  name: string;
  checked: boolean;
  value: string;
}

const NotificacaoView: React.FC = () => {
  const { setCurrentTitle } = useCrudContext();
  const {
    configuracoesFundacao: {
      instituicao: { nome: fundacaoNome },
    },
  } = useAuthContext();
  const { loadNotificationCount } = useNotificacaoContext();
  const [selectedNotification, setSelectedNotification] = useState<Notificacao>();
  const [selectedItemMenu, setSelectedItemMenu] = useState<string | null>('todas');
  const [totalNotifications, setTotalNotifications] = useState<number>(0);
  const [notifications, setNotifications] = useState<Notificacao[]>([]);
  const [categoriesFilter, setCategoriesFilter] = useState<ICategory[]>([]);
  const [selectAllChecked, setSelectAllChecked] = useState(false);
  const [inputSearch, setInputSearch] = useState('');
  const [showInbox, setShowInbox] = useState(true);
  const [skip, setSkip] = useState(0);

  const notificationApi: INotificacaoApi = makeNotificacaoApi();
  const inputRef = useRef<HTMLInputElement>(null);
  const take: number = 10;

  useEffect(() => {
    notificationApi?.findAllModules().then(({ modules }) => {
      setCategoriesFilter(
        modules.map((module: string) => ({
          name: translateAction(module),
          checked: false,
          value: module,
        })),
      );
    });
  }, []);

  useEffect(() => {
    setCurrentTitle('Notificação');
    loadNotifications();
  }, [skip, take, inputSearch, showInbox, selectedItemMenu, categoriesFilter]);

  const loadNotifications = () => {
    const where = buildWhereClause();

    notificationApi
      ?.getAll([
        { chave: 'take', valor: take },
        { chave: 'skip', valor: skip },
        { chave: 'orderBy', valor: 'id' },
        { chave: 'orderDirection', valor: 'DESC' },
        ...where,
      ])
      .then(({ data, count }) => {
        setNotifications(data);
        setTotalNotifications(count);
        handleSelectAllChange({ target: { checked: false } });
      });
  };

  const buildWhereClause = () => {
    let where: { chave: string; valor: boolean | string | string[] }[] = [];

    switch (selectedItemMenu) {
      case 'excluidas':
        where = [
          { chave: 'withDeleted', valor: true },
          { chave: 'onlyDeleted', valor: true },
        ];
        break;
      case 'naoLidas':
        where.push({ chave: 'naoLidas', valor: true });
        break;
    }

    if (categoriesFilter.length > 0) {
      const checkedNames = categoriesFilter
        .filter((category) => category.checked)
        .map((category) => category.value);

      if (checkedNames.length > 0) {
        where.push({ chave: 'modules', valor: checkedNames });
      }
    }

    if (inputSearch) {
      where.push({ chave: 'search', valor: inputSearch });
    }

    return where;
  };

  const handleSearch = () => {
    if (inputRef.current) {
      const search = inputRef.current.value;
      setInputSearch(search);
      setSelectedItemMenu(null);
    }
  };

  const handleSelectAllChange = (event: any) => {
    const isChecked = event.target.checked;
    setNotifications((prevNotifications) =>
      prevNotifications.map((notification) => ({
        ...notification,
        checked: isChecked,
      })),
    );
    setSelectAllChecked(isChecked);
  };

  const handleMarkAsRead = () => {
    notificationApi
      ?.marcarComoLida(
        notifications.filter((notification) => notification.checked).map((n) => n.id),
      )
      .then(() => {
        loadNotifications();
        loadNotificationCount();
      });
  };

  const handleDelete = () => {
    notificationApi
      ?.multipleDelete(
        notifications.filter((notification) => notification.checked).map((n) => n.id),
      )
      .then(() => {
        loadNotifications();
        loadNotificationCount();
      });
  };

  const nextPage = () => {
    if (skip + take >= totalNotifications) return;
    setSkip(skip + take);
  };

  const previousPage = () => {
    if (skip === 0) return;
    setSkip(skip - take);
  };

  const handleCheckboxChange = (id: number) => {
    const notification = notifications.filter((notification) => notification.id === id);

    if (notification && notification[0].checked) {
      setSelectAllChecked(false);
    }

    setNotifications((prevNotifications) =>
      prevNotifications.map((notification) =>
        notification.id === id ? { ...notification, checked: !notification.checked } : notification,
      ),
    );
  };

  const truncateText = (text: string, maxLength: number) => {
    if (text && text.length > maxLength) {
      return text.substring(0, maxLength) + '...';
    }
    return text;
  };

  const handleSelectNotification = (notification: Notificacao) => {
    notificationApi?.marcarComoLida([notification.id]).then(() => {
      loadNotificationCount();
      setSelectedNotification(notification);
      setShowInbox(false);
    });
  };

  const handleNameAvatar = (name: string | undefined) => {
    if (!name) return '';

    return name
      .split(' ')
      .map((word) => word.charAt(0))
      .join('');
  };

  const handlePriorityText = (priority: number | undefined) => {
    return prioridades.filter((p) => p.value === priority)[0].name;
  };

  const handleSelectCategories = (index: number) => {
    setSkip(0);
    setCategoriesFilter((prevCategories) => {
      return prevCategories.map((category, idx) =>
        idx === index ? { ...category, checked: !category.checked } : category,
      );
    });
  };

  const formatDateTime = (date: Date) => {
    if (!date) return;
    return dayjs(date).format('DD/MM/YYYY - HH:mm');
  };

  const SearchInput = () => {
    return (
      <Paper
        component='form'
        sx={{
          p: '0px 10px',
          display: 'flex',
          alignItems: 'center',
          borderRadius: '10px',
          backgroundColor: '#FAFAFA',
        }}
        onSubmit={(e) => e.preventDefault()}
      >
        <InputBase
          sx={{ ml: 1, flex: 1 }}
          placeholder='Pesquisar mensagens'
          inputProps={{ 'aria-label': 'pesquisar mensagens' }}
          inputRef={inputRef}
          defaultValue={inputSearch}
          onKeyDown={(e) => {
            if (e.key === 'Enter') {
              e.preventDefault();
              handleSearch();
            }
          }}
        />
        <IconButton type='button' sx={{ p: '10px' }} aria-label='search' onClick={handleSearch}>
          <SearchAlt size={34} />
        </IconButton>
      </Paper>
    );
  };

  const MenuActions = () => {
    return (
      <Box display='flex' justifyContent='start' alignItems='center' margin='10px 0px 30px 0px'>
        <Box>
          <Tooltip title='Marcar todas as mensagens' arrow>
            <Checkbox checked={selectAllChecked} onChange={handleSelectAllChange} />
          </Tooltip>
        </Box>
        <Tooltip title='Marcar como lida' arrow>
          <MailRead
            size={30}
            style={{ marginLeft: '20px', cursor: 'pointer', color: '#9B9B9B' }}
            onClick={handleMarkAsRead}
          />
        </Tooltip>
        <Tooltip title='Excluir' arrow>
          <TrashFill
            size={24}
            style={{ marginLeft: '20px', cursor: 'pointer', color: '#9B9B9B' }}
            onClick={handleDelete}
          />
        </Tooltip>

        <Tooltip title='Atualizar' arrow>
          <Reload
            size={20}
            style={{ marginLeft: '20px', cursor: 'pointer', color: '#9B9B9B' }}
            onClick={loadNotifications}
          />
        </Tooltip>

        <Box flexGrow={1} />

        <Tooltip title='Próximas' arrow>
          <ArrowBackIos
            size={25}
            style={{
              marginLeft: '20px',
              color: skip === 0 ? '#E0E0E0' : '#9B9B9B',
              cursor: skip === 0 ? 'not-allowed' : 'pointer',
            }}
            color='#9B9B9B'
            onClick={previousPage}
          />
        </Tooltip>
        <Tooltip title='Anteriores' arrow>
          <ArrowForwardIos
            size={25}
            style={{
              marginLeft: '20px',
              color: skip + take >= totalNotifications ? '#E0E0E0' : '#9B9B9B',
              cursor: skip + take >= totalNotifications ? 'not-allowed' : 'pointer',
            }}
            onClick={nextPage}
          />
        </Tooltip>
      </Box>
    );
  };

  const NotificationItems = () => {
    return (
      <Box>
        {notifications.map((notification) => (
          <Box
            key={notification.id}
            display='flex'
            alignItems='center'
            marginTop='10px'
            justifyContent='space-between'
          >
            <Box display='flex' alignItems='center' width='100%'>
              <Checkbox
                checked={notification?.checked}
                onChange={() => handleCheckboxChange(notification.id)}
              />
              <Box
                display='flex'
                alignItems='center'
                padding='0px'
                sx={{
                  cursor: 'pointer',
                  ':hover': { backgroundColor: '#DEDEDE' },
                }}
                width='100%'
                borderRadius='10px'
                onClick={() => handleSelectNotification(notification)}
              >
                <Box marginLeft='5px'>
                  <Avatar
                    sx={{
                      width: '45px',
                      height: '45px',
                      fontSize: '13pt',
                      bgcolor: '#ffdddd',
                      color: '#5d0000',
                    }}
                  >
                    {handleNameAvatar(notification?.destinatario?.nome)}
                  </Avatar>
                </Box>
                <Box marginLeft='20px' width='100%'>
                  <Box display='flex' alignItems='center' width='100%'>
                    <text style={{ margin: 0, flex: 1, fontSize: '0.9em' }}>
                      <strong>{notification?.remetente?.nome ?? fundacaoNome}</strong> -{' '}
                      {notification.prioridade < 2
                        ? `[${handlePriorityText(notification.prioridade)}] `
                        : ''}
                      {truncateText(notification.assunto, 46)}
                    </text>
                    <text
                      style={{
                        margin: 0,
                        fontSize: '0.7em',
                        color: '#A0A0A0',
                        paddingRight: '10px',
                      }}
                    >
                      {formatDateTime(notification.dataHoraEnvio)}
                    </text>
                    {!notification.lida ? <Circle size={8} color='#2E8FCA' /> : null}
                  </Box>
                  <Box>
                    <text style={{ margin: 0, fontSize: '0.9em' }}>
                      {truncateText(notification.corpo, 82)}
                    </text>
                  </Box>
                </Box>
              </Box>
            </Box>
          </Box>
        ))}
      </Box>
    );
  };

  const renderListItem = (label: string, icon: React.ReactNode, menuName: string) => (
    <ListItem disablePadding>
      <ListItemButton
        sx={{
          borderRadius: '10px',
          '&:hover': { backgroundColor: '#1E90FF2E' },
          backgroundColor: selectedItemMenu === menuName ? '#1E90FF2E' : 'transparent',
        }}
        onClick={() => {
          setSkip(0);
          setInputSearch('');
          setSelectedItemMenu(menuName);
        }}
      >
        <ListItemIcon>{icon}</ListItemIcon>
        <ListItemText primary={label} />
      </ListItemButton>
    </ListItem>
  );

  const FilterItems = () => {
    return (
      <Box sx={{ width: '100%', maxWidth: 360 }}>
        <h3>Notificações</h3>
        <nav aria-label='main mailbox folders'>
          <List>
            {renderListItem('Todas', <Inbox size={30} />, 'todas')}
            {renderListItem('Não lidas', <MailInboxCheckmark size={30} />, 'naoLidas')}
            {renderListItem('Excluídas', <TrashFill size={30} />, 'excluidas')}
          </List>
        </nav>
        <Box sx={{ marginBottom: '40px' }} />
        <Box>
          <h3>Fundação</h3>
        </Box>
        <nav aria-label='secondary mailbox folders'>
          <List sx={{ margin: '0px', padding: '0px' }}>
            {categoriesFilter.map((category, index) => (
              <ListItem disablePadding key={index}>
                <Checkbox
                  checked={category.checked}
                  onChange={() => handleSelectCategories(index)}
                />
                <ListItemText primary={category.name} />
              </ListItem>
            ))}
          </List>
        </nav>
      </Box>
    );
  };

  const BackToInbox = () => {
    return (
      <Box display='flex' margin='10px 0px 30px 0px'>
        <Box
          display='flex'
          justifyContent='start'
          alignItems='center'
          sx={{ cursor: 'pointer' }}
          onClick={() => setShowInbox(true)}
        >
          <IconButton disabled>
            <ArrowBack size={24} color='#9B9B9B' />
          </IconButton>
          <h5>Voltar para inbox</h5>
        </Box>
      </Box>
    );
  };

  const NotificationDetails = () => {
    return (
      <Box position='relative'>
        <Box
          position='absolute'
          top={-15}
          right={-15}
          display='flex'
          justifyContent='end'
          width='100%'
        >
          <Box padding='10px' borderRadius='50%' sx={{ backgroundColor: '#1351b4' }}>
            <Notifications size={30} color='white' />
          </Box>
        </Box>
        <Box display='flex' border={1} padding='20px 30px' borderRadius={2}>
          <Box width='10%' display='flex' justifyContent='center'>
            <Avatar
              sx={{ width: 65, height: 65, fontSize: '18pt', bgcolor: '#eaddff', color: '#20005d' }}
            >
              {handleNameAvatar(selectedNotification?.destinatario?.nome)}
            </Avatar>
          </Box>
          <Box marginLeft='20px' width='100%'>
            <Box display='flex' alignItems='center' width='100%'>
              <text style={{ margin: 0, flex: 1 }}>
                <strong>{selectedNotification?.remetente?.nome ?? fundacaoNome}</strong> -{' '}
                {selectedNotification.prioridade < 2
                  ? `[${handlePriorityText(selectedNotification.prioridade)}] `
                  : ''}
                {selectedNotification.assunto}
              </text>
              <text style={{ margin: 0, fontSize: '0.8em', color: '#A0A0A0' }}>
                {formatDateTime(selectedNotification.dataHoraEnvio)}
              </text>
            </Box>
            <Box>
              <text style={{ margin: 0 }}>{selectedNotification.corpo}</text>
            </Box>
          </Box>
        </Box>
      </Box>
    );
  };

  return (
    <Container style={{ display: 'flex', marginTop: '20px' }}>
      <Container style={{ width: '20%', display: 'flex', justifyContent: 'start' }}>
        <FilterItems />
      </Container>
      <Container style={{ width: '80%' }}>
        <SearchInput />
        <Container style={{ padding: '10px' }}>
          {showInbox ? (
            <Box>
              <MenuActions />
              <NotificationItems />
            </Box>
          ) : (
            <Box>
              <BackToInbox />
              <NotificationDetails />
            </Box>
          )}
        </Container>
      </Container>
    </Container>
  );
};

export default NotificacaoView;
