import { ExpandLess, ExpandMore } from '@mui/icons-material';
import { Box, Collapse, ListItemButton, ListItemIcon, ListItemText } from '@mui/material';
import React, { ReactNode, useState } from 'react';
import '../../style/layout.css';
import { Item } from '../nav-drawer';
import { NavItem } from '../nav-item';
import { authStringsFor } from '../../../../helpers/auth-strings-for';
import { useAuthContext } from '../../../../views/login/auth/auth-context';
import { moduleNames } from '../../../../views';
import { useLocation } from 'react-router-dom';

export interface NavCategoryProps {
  title: string;
  icon: ReactNode;
  items: Item[];
  subCategory?: boolean;
  open: boolean;
  setOpen: (open: boolean) => void;
  toggleDrawer: () => void;
}

export function NavCategory(props: NavCategoryProps) {
  const { title, icon, items, open, setOpen, toggleDrawer } = props;
  const [visible, setVisible] = useState(false);
  const [isExpanded, setIsExpanded] = useState(false);
  const { permissions } = useAuthContext();
  const location = useLocation();

  const isActive = items.some((item) => {
    return location.pathname.startsWith(item.route);
  });

  const handleClick = () => {
    !open && toggleDrawer();
    setIsExpanded((prevState) => !prevState);
  };

  const mapListToElement = (list: Item[]) => {
    const listMap =
      open &&
      list
        .map((item, index) => {
          const moduleName = moduleNames.find((i) => i.id === item.route.substring(1))?.value;
          const hasPermission =
            authStringsFor(`${moduleName ?? ''}.EXIBIR_NO_MENU` ?? '').some((x) =>
              permissions.includes(x),
            ) &&
            authStringsFor(`${moduleName ?? ''}.BUSCAR_TODOS` ?? '').some((x) =>
              permissions.includes(x),
            );
          if (!visible && hasPermission) setVisible(true);
          if (!hasPermission) return null;
          return (
            <NavItem
              key={index}
              route={item.route}
              state={item.state}
              icon={item.icon}
              title={item.title}
            />
          );
        })
        .filter((x) => x);
    return listMap;
  };

  const childrens = mapListToElement(items);

  if (!visible) return null;

  const activeColor = isActive ? '#017096' : '';

  return (
    <>
      <Box sx={{ display: 'flex' }}>
        <Box
          sx={{
            width: '3px',
            height: '100',
            backgroundColor: activeColor,
            margin: '3px 0px',
          }}
        />
        <ListItemButton
          onClick={handleClick}
          sx={
            !open
              ? {
                  ':hover': { backgroundColor: '#d4e0e4' },
                  borderRadius: '5px',
                  width: '100%',
                  padding: '3px 0px',
                  height: '38px',
                }
              : {
                  borderRadius: '5px',
                  padding: '3px 10px',
                  height: '38px',
                }
          }
        >
          <ListItemIcon
            className='nav-icon'
            sx={
              !open
                ? {
                    justifyContent: 'left',
                    width: '100%',
                    paddingLeft: '10px',
                  }
                : {}
            }
          >
            {React.cloneElement(icon as any, {
              style: {
                color: activeColor,
              },
            })}
          </ListItemIcon>
          {open && (
            <ListItemText
              primary={title}
              primaryTypographyProps={{
                variant: 'h6',
                style: {
                  fontFamily: 'Raleway',
                  fontSize: '14px',
                  fontWeight: 'medium',
                  color: activeColor,
                },
              }}
              style={{
                whiteSpace: 'normal',
              }}
            />
          )}
          {open &&
            (isExpanded ? (
              <ExpandLess sx={{ color: activeColor }} />
            ) : (
              <ExpandMore sx={{ color: activeColor }} />
            ))}
        </ListItemButton>
      </Box>
      <Collapse className='sub-item' in={isExpanded} timeout='auto' unmountOnExit>
        <Box sx={{ display: 'block', flexDirection: 'column' }}>{childrens}</Box>
      </Collapse>
    </>
  );
}
