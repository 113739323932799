import { HeadCell } from '../../../components/crud/protocols/head-cell';

export interface Data extends Record<string, any> {
  id: number;
  logradouro: string;
  numero: number;
  bairro: string;
  municipio: string;
  estado: string;
}

export const displayProperties = ['logradouro', 'numero', 'bairro', 'municipio', 'estado'];

export const moduleLabel = 'Endereço';

export const headCells: readonly HeadCell<Data>[] = [
  {
    id: 'logradouro',
    numeric: false,
    disablePadding: false,
    label: 'Logradouro',
  },
  {
    id: 'numero',
    numeric: false,
    disablePadding: false,
    label: 'Número',
  },
  {
    id: 'bairro',
    numeric: false,
    disablePadding: false,
    label: 'Bairro',
  },
  {
    id: 'municipio',
    numeric: false,
    disablePadding: false,
    label: 'Municipio',
  },
  {
    id: 'estado',
    numeric: false,
    disablePadding: false,
    label: 'Estado',
  },
];
