import { Crud } from '../../../components/crud/crud';
import { whiteSpaces } from '../../../helpers/custom-errors';
import DepartamentoFundacaoUiSchema from './departamento-fundacao.uischema';
import { Data, headCells } from './departamento-fundacao.headcell';

function DepartamentoFundacao() {
  const customErrors = [whiteSpaces('sigla'), whiteSpaces('nome'), whiteSpaces('descricao')];
  const defaultForm = { nome: '', sigla: '' };

  return (
    <Crud<Data>
      headCells={headCells}
      titleConfig={{ value: 'Departamento da Fundação', show: true }}
      uischema={DepartamentoFundacaoUiSchema}
      apiUrl={'/departamento-fundacao'}
      customErrors={customErrors}
      defaultForm={defaultForm}
      hideView
      queryFilters={[
        { chave: 'withDeleted', valor: true },
        { chave: 'orderBy', valor: 'sigla' },
        { chave: 'orderDirection', valor: 'asc' },
      ]}
    ></Crud>
  );
}

export default DepartamentoFundacao;
