import { useNavigate } from 'react-router-dom';
import { Crud } from '../../../components/crud/crud';
import { customRequired } from '../../../helpers/custom-errors';
import { Data, headCells } from './modalidade-bolsa.headcell';
import modalidadeBolsaUiSchema from './modalidade-bolsa.uischema';
import { Add } from '@styled-icons/fluentui-system-filled/Add';
import { useCrudContext } from '../../../components/crud/crud-context';
import { Tooltip } from '@mui/material';

function ModalidadeBolsa() {
  const navigate = useNavigate();
  const { parameterFilters, setParameterFilters } = useCrudContext();

  const customErrors = [
    customRequired('nome'),
    customRequired('sigla', 'O campo Sigla é obrigatório.'),
  ];

  const nivelBolsa = {
    permission: 'BOLSA.MODALIDADE_DE_BOLSA.NIVEL_BOLSA.NIVEL_BOLSA.EXIBIR_NO_MENU',
    action: (
      <Tooltip title='Níveis de Bolsa' arrow>
        <Add size='24' color='green' />
      </Tooltip>
    ),
    handler: (row: any) => {
      setParameterFilters({
        ...parameterFilters,
        modadalidadeBolsa: row,
      });
      navigate(`/modalidade-bolsa/${row?.id}/nivel-bolsa`);
    },
  };

  const showNivelBolsa = (row: any) => {
    if (row.deletedAt === null) return nivelBolsa;
  };

  return (
    <Crud<Data>
      titleConfig={{ value: ' Modalidade de Bolsa', show: true }}
      apiUrl={'/modalidade-bolsa'}
      customErrors={customErrors}
      headCells={headCells}
      uischema={modalidadeBolsaUiSchema}
      queryFilters={[{ chave: 'withDeleted', valor: true }]}
      customActions={[showNivelBolsa]}
      hideView
    ></Crud>
  );
}

export default ModalidadeBolsa;
