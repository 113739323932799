import {
  ControlProps,
  RankedTester,
  defaultDateTimeFormat,
  isDateTimeControl,
  isDescriptionHidden,
  rankWith,
} from '@jsonforms/core';
import {
  createOnBlurHandler,
  createOnChangeHandler,
  getData,
  useFocus,
} from '@jsonforms/material-renderers';
import { withJsonFormsControlProps } from '@jsonforms/react';
import { FormHelperText, Hidden } from '@mui/material';
import { DateTimePicker, LocalizationProvider, renderTimeViewClock } from '@mui/x-date-pickers';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import merge from 'lodash/merge';
import { useCallback, useMemo, useState } from 'react';
import dayjs from '../../components/dayjs/sig-dayjs';

const MaterialDateTimeControl = (props: ControlProps) => {
  const [focused, onFocus, onBlur] = useFocus();
  const {
    id,
    description,
    errors,
    label,
    uischema,
    visible,
    enabled,
    required,
    path,
    handleChange,
    data,
    config,
  } = props;
  const appliedUiSchemaOptions = merge({}, config, uischema.options);
  const isValid = errors.length === 0;

  const showDescription = !isDescriptionHidden(
    visible,
    description,
    focused,
    appliedUiSchemaOptions.showUnfocusedDescription,
  );

  const format = appliedUiSchemaOptions.dateTimeFormat ?? 'DD-MM-YY HH:mm:ss';
  const saveFormat = appliedUiSchemaOptions.dateTimeSaveFormat ?? defaultDateTimeFormat;

  const [key, setKey] = useState<number>(0);

  const views = appliedUiSchemaOptions.views ?? ['year', 'day', 'hours', 'minutes', 'seconds'];

  const firstFormHelperText = showDescription ? description : !isValid ? errors : null;
  const secondFormHelperText = showDescription && !isValid ? errors : null;

  const updateChild = useCallback(() => setKey((key) => key + 1), []);

  const onChange = useMemo(
    () => createOnChangeHandler(path, handleChange, saveFormat),
    [path, handleChange, saveFormat],
  );

  const onBlurHandler = useMemo(
    () => createOnBlurHandler(path, handleChange, format, saveFormat, updateChild, onBlur),
    [path, handleChange, format, saveFormat, updateChild],
  );
  const value = dayjs(getData(data, saveFormat));

  return (
    <Hidden xsUp={!visible}>
      <LocalizationProvider dateAdapter={AdapterDayjs}>
        <DateTimePicker
          key={key}
          label={label}
          value={value}
          onAccept={onChange as any}
          format={format}
          ampm={!!appliedUiSchemaOptions.ampm}
          views={views}
          disabled={!enabled}
          viewRenderers={{
            hours: renderTimeViewClock,
            minutes: renderTimeViewClock,
            seconds: renderTimeViewClock,
          }}
          slotProps={{
            actionBar: ({ wrapperVariant }) => ({
              actions: wrapperVariant === 'desktop' ? [] : ['clear', 'cancel', 'accept'],
            }),
            textField: {
              id: id + '-input',
              required: required && !appliedUiSchemaOptions.hideRequiredAsterisk,
              autoFocus: appliedUiSchemaOptions.focus,
              error: !isValid,
              fullWidth: !appliedUiSchemaOptions.trim,
              inputProps: {
                type: 'text',
              },
              InputLabelProps: data ? { shrink: true } : undefined,
              onFocus: onFocus,
              onBlur: onBlurHandler,
            },
          }}
        />
        <FormHelperText error={!isValid && !showDescription}>{firstFormHelperText}</FormHelperText>
        <FormHelperText error={!isValid}>{secondFormHelperText}</FormHelperText>
      </LocalizationProvider>
    </Hidden>
  );
};

const materialDateTimeControlTester: RankedTester = rankWith(5, isDateTimeControl);

export const materialDateTimeRender = {
  renderer: withJsonFormsControlProps(MaterialDateTimeControl),
  tester: materialDateTimeControlTester,
};
