import { rankWith, uiTypeIs } from '@jsonforms/core';
import { withJsonFormsControlProps } from '@jsonforms/react';
import { Autocomplete, Hidden, TextField } from '@mui/material';
import { DownArrow } from '@styled-icons/boxicons-solid/DownArrow';
import match from 'autosuggest-highlight/match';
import parse from 'autosuggest-highlight/parse';
import { camelCase } from 'change-case';
import { isEmpty, isEqual } from 'lodash';
import React from 'react';
import { GenericApi, makeApi } from '../../api/generic-api';
import { useCrudContext } from '../../components/crud/crud-context';
import { translateErrors } from '../../components/crud/i18n';
import { displayProperties, moduleLabels } from '../../views';
import './renderer.css';

const selectTester = rankWith(6, uiTypeIs('Select'));

export const selectRender = {
  tester: selectTester,
  renderer: withJsonFormsControlProps(({ visible = true, schema, uischema, path, ...props }) => {
    const [api, setApi] = React.useState<GenericApi | null>(null);
    const [apiListData, setApiListData] = React.useState<any>([]);
    const [selectedItem, setSelectedItem] = React.useState<any>(null);
    const [options, setOptions] = React.useState<any>([]);
    const [lastFilterValues, setLastFilterValues] = React.useState<any>([]);
    const [disable, setDisable] = React.useState<boolean>(false);
    const [isVisible, setIsVisible] = React.useState<boolean>(true);

    const iconBuilder: (option: any) => JSX.Element = uischema?.options?.iconBuilder;
    const visibleHandler = (uischema as any)?.options?.visibleHandler;
    const customApiInstance = (uischema as any)?.options?.apiInstance;
    const apiUrl = (schema as any)?.foreignRoute || '';
    const oneOf = (schema as any)?.oneOf;

    const ctx = useCrudContext();

    const {
      errorsJsonForms,
      errorsCustom,
      validationMode,
      formData,
      crudStates,
      disabledFields,
      showError,
    } = ctx;

    React.useEffect(() => {
      if (customApiInstance) {
        setApi(customApiInstance);
      } else if (!api && apiUrl) {
        setApi(makeApi(apiUrl));
      }
    }, [api, apiUrl]);

    React.useEffect(() => {
      const filter = (uischema as any)?.options?.filter;
      let hasFormField = filter?.formFields ? true : false;
      if (!filter) {
        api?.getAll?.().then((dataFromApi: any) => {
          setApiListData(dataFromApi);
          loadSavedItem(dataFromApi);
          setTimeout(() => loadEnumDefault(dataFromApi), 100);
        });
      } else if (api && !hasFormField) {
        const { handler } = filter;
        handler?.(formData, apiListData, api, undefined, path)?.then?.((dataFromApi: any) => {
          setApiListData(dataFromApi);
        });
        filterHandler();
      } else if (api) filterHandler();
    }, [api]);

    React.useEffect(() => {
      let options = [];

      if (Array.isArray(oneOf)) {
        options = oneOf.map((option) => ({
          label: `${option?.title}`,
          value: `${option?.const}`,
        }));
      } else {
        options = apiListData?.map?.((item: any) => ({
          label: displayPropertiesComputed(item),
          value: item?.id,
          codigo: item?.codigo ?? '',
          data: item,
        }));
      }

      disableHandler(); //TODO: analisar um lugar melhor para chamada desse handler
      setOptions(options);
    }, [apiListData]);

    React.useEffect(() => {
      if (props.data || oneOf || apiListData?.length) {
        if (Array.isArray(oneOf)) {
          const one = oneOf?.find?.((item: any) => item?.const === props?.data);

          if (one) {
            setSelectedItem({
              label: one?.title,
              value: one?.const,
            });
          }
        } else {
          const item = apiListData?.find?.((item: any) => item?.id === Number(props?.data));
          if (item) {
            const option = {
              label: displayPropertiesComputed(item),
              value: item?.id,
              codigo: item.codigo,
            };
            setSelectedItem(option);
          }
        }
      }
    }, [props.data, apiListData, oneOf]);

    React.useEffect(() => {
      if (selectedItem && Object.keys(selectedItem).length) {
        let foreignEnum;
        if ('codigo' in selectedItem && (foreignEnum = (schema as any)?.foreignEnum)) {
          const [codeKey] =
            Object.entries(foreignEnum).find(([, value]) => value === selectedItem.codigo) ?? [];
          setTimeout(() => props.handleChange(`${path}-codigo`, codeKey), 200);
        }
      }
    }, [selectedItem]);

    React.useEffect(() => {
      if (!(crudStates.edit || crudStates.view || crudStates.add) || !api) return;
      filterHandler();
    }, [api, crudStates, apiListData, formData]);

    const isDisabled: boolean =
      crudStates.view ||
      uischema?.options?.disabled ||
      (crudStates.edit && uischema?.options?.onlyCreate) ||
      disabledFields.includes(path);

    const disableHandler = () => {
      const disableHandler = (uischema as any)?.options?.disableHandler;
      if (!disableHandler) return;

      const handlerResult = disableHandler?.(ctx, apiListData);
      setDisable(handlerResult);
    };

    const filterHandler = () => {
      const filter = (uischema as any)?.options?.filter;
      if (!filter) return;

      const { formFields, handler } = filter;
      if (!formFields) return;

      let hasNullFilterValue = false;

      const queryFilterValues =
        formFields?.map?.((filter: any) => {
          const filterValue = path
            ?.split('.')
            .slice(0, -1)
            .concat(filter)
            .reduce((acc: any, key: any) => acc && acc[key], formData);

          if (!filterValue) hasNullFilterValue = true;

          return {
            filter,
            value: filterValue,
          };
        }) ?? [];

      if (visibleHandler) {
        const visibility = visibleHandler?.(ctx, apiListData, queryFilterValues);
        setIsVisible(visibility);
      }

      if (handler && !isEqual(queryFilterValues, lastFilterValues)) {
        if (formFields) {
          handler?.(formData, apiListData, api, queryFilterValues, path)
            ?.then?.((dataFromApi: any) => {
              setApiListData(dataFromApi);
            })
            .catch((error: any) => {
              showError(error?.response?.data?.message?.[0] || 'Ocorreu um erro.');
            });
        }

        if (hasNullFilterValue) {
          //Limpa a lista quando o filtro observado for null
          setApiListData([]);
        }

        if (!(queryFilterValues.length && !lastFilterValues?.length)) {
          // Alterou os filtros
          // Limpa o select
          setTimeout(() => handleChange(undefined, null), 100);
        }

        setLastFilterValues(queryFilterValues);
      }
    };

    const loadSavedItem = (dataFromApi: any[]) => {
      if (oneOf) {
        const item = oneOf?.find?.((option: any) => option?.const === props?.data);
        if (item) {
          setSelectedItem({
            label: item?.title,
            value: item?.const,
          });
        }
      } else if (props?.data) {
        const item = dataFromApi?.find?.((item: any) => item?.id === Number(props?.data));
        if (item) {
          const option = {
            label: displayPropertiesComputed(item),
            value: item?.id,
            codigo: item?.codigo,
          };
          setSelectedItem(option);
          if (uischema.options?.handleChange) {
            uischema.options?.handleChange?.(item, props.handleChange, formData);
          }
        }
      }
    };

    const displayPropertiesComputed = (item: any) => {
      if (isEmpty(item)) return '';
      const formatOptionLabel = uischema?.options?.formatOptionLabel;

      if (formatOptionLabel && typeof formatOptionLabel === 'function') {
        return formatOptionLabel(item);
      }

      // Formatação padrão
      const properties = displayProperties?.find((option) => option?.id === apiUrl);
      const values: string[] = [];

      if (typeof properties?.value === 'function') return properties?.value(item);

      let propValues =
        (uischema as any)?.options?.filter?.displayProperties ?? (properties?.value as string[]);
      propValues?.forEach?.(
        (prop: any) =>
          values?.push?.(prop.split('.').reduce((acc: any, key: any) => acc && acc[key], item)),
      );
      return values?.join?.(', ');
    };

    const getLabel = () => {
      const uiSchemaLabel = uischema.label;
      const moduleLabel = moduleLabels?.find((headCell) => headCell?.id === apiUrl)?.value;
      return uiSchemaLabel ?? moduleLabel;
    };

    const getError = () => {
      const requiredError = errorsJsonForms?.find?.(
        (error) => error?.params?.missingProperty === path,
      );
      const customRequiredError = errorsCustom?.find?.(
        (error) => error?.params?.missingProperty === path,
      );
      return requiredError ?? customRequiredError;
    };

    const hasError = () => {
      return (props.required && !formData?.[path]) || !!getError();
    };

    const getErrorMessage = () => {
      const error = getError();
      return translateErrors?.(error) ?? error?.message ?? '';
    };

    const processPath = (path: string): string => {
      //Processa o path para os casos de lista
      const parts = path.split('.');
      const isArrayPath = parts.some((part) => !isNaN(Number(part)));

      if (isArrayPath) {
        return parts.slice(0, parts.length - 1).join('.');
      }

      return camelCase(apiUrl);
    };

    const handleChange = (_: any, content: any) => {
      const option = {
        label: displayPropertiesComputed(content?.data) ?? '',
        value: content?.value,
        codigo: content?.codigo,
      };

      if (apiUrl) {
        const pathToSet = content?.value != null ? processPath(path) : path;
        const valueToSet = content?.value != null ? { ...content?.data } : null;
        props.handleChange(pathToSet, valueToSet);
      }
      if (uischema.options?.handleChange) {
        uischema.options?.handleChange?.(
          content.data ?? content.value,
          props.handleChange,
          formData,
        );
      }

      props.handleChange(path, content?.value ?? null);
      setSelectedItem(content?.value ? option : null);
    };

    const loadEnumDefault = (listData: any) => {
      if (!props.data && formData?.[`${path}-codigo`]) {
        const foreignEnum = (schema as any)?.foreignEnum;
        const enumCode = foreignEnum[formData[`${path}-codigo`]];
        const item = listData?.find?.((item: any) => item.codigo === enumCode);
        props.handleChange(path, item?.id);
      }
    };

    return (
      <Hidden xsUp={!visible || !isVisible}>
        <div className='custom-input-container'>
          <Autocomplete
            className={`custom-autocomplete ${
              (isDisabled && 'disabled-field') ||
              (hasError() && validationMode === 'ValidateAndShow' && 'has-error')
            }`}
            options={options}
            value={selectedItem}
            getOptionLabel={(option: any) => option?.label}
            onChange={handleChange}
            isOptionEqualToValue={(option: any, value: any) => option?.value === value?.value}
            disabled={isDisabled || disable}
            noOptionsText={'Sem opções'}
            renderInput={(params) => (
              <TextField
                {...params}
                label={getLabel()}
                required={props.required}
                InputProps={{
                  ...params.InputProps,
                  endAdornment: <DownArrow size={5} />,
                }}
                InputLabelProps={{ shrink: false }}
              />
            )}
            renderOption={(props, option, { inputValue }) => {
              const matches = match(option.label, inputValue, {
                insideWords: true,
              });
              const parts = parse(option.label, matches);

              return (
                <li {...props}>
                  <div>
                    {iconBuilder && iconBuilder(option)}
                    {parts.map((part, index) => (
                      <span
                        key={index}
                        style={{
                          color: part.highlight ? '#1E90FF' : '#333333',
                        }}
                      >
                        {part.text}
                      </span>
                    ))}
                  </div>
                </li>
              );
            }}
          />
          {validationMode === 'ValidateAndShow' && hasError() && (
            <span className='error-message'>{getErrorMessage()}</span>
          )}
        </div>
      </Hidden>
    );
  }),
};
