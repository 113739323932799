import { HeadCell } from '../../components/crud/protocols/head-cell';

export interface Data extends Record<string, any> {
  id: number;
  codigo: string;
}

export const displayProperties = ['nome'];

export const moduleLabel = 'Parceria';

export const headCells: readonly HeadCell<Data>[] = [
  {
    id: 'nome',
    numeric: false,
    disablePadding: false,
    label: 'Nome',
  },
];
