import { HeadCell } from '../../../components/crud/protocols/head-cell';

export interface Data extends Record<string, any> {
  id: number;
  pergunta: string;
}

export const moduleName = 'EDITAL.MULTIPLA_ESCOLHA.MULTIPLA_ESCOLHA';

export const displayProperties = ['pergunta'];

export const moduleLabel = 'Múltipla Escolha';

export const headCells: readonly HeadCell<Data>[] = [
  {
    id: 'pergunta',
    numeric: false,
    disablePadding: false,
    label: 'Pergunta',
  },
];
